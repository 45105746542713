<template>
    <div :class="{'distribution-period-details': true, 'loading': !loaded}">
        <strong class="period-year">{{ year }}</strong>
        <strong class="period-quarter">{{ quarter }}</strong>
        <div class="period-details">
            <div class="period-details-entry">
                <strong>Total returns</strong>
                <span>{{ percentage }}%</span>
            </div>
            <div class="period-details-entry">
                <strong>Total Distributions</strong>
                <span>{{ distributions.length }}</span>
            </div>
            <div class="period-details-entry">
                <strong>Payable Date</strong>
                <span>{{ convertToReadableDate(payableDate) }}</span>
            </div>
            <div class="period-details-entry">
                <strong>Created on</strong>
                <span>{{ convertToReadableDate(createdAt) }}</span>
            </div>
        </div>
        <div class="period-amounts">
            <div class="period-amount-item">
                <strong>${{ totalDistributed ? totalDistributed.toLocaleString() : '0' }}</strong>
                <span>Distributed</span>
            </div>
            <div class="period-amount-item">
                <strong>${{ totalReinvested ? totalReinvested.toLocaleString() : '0' }}</strong>
                <span>Reinvested</span>
            </div>
        </div>
        <div class="period-distributions-wrapper" v-if="distributions.length > 0">
            <h4>Distributions &amp; Reinvestments</h4>
            <div class="period-distributions-list">
                <div class="distribution-entry" v-for="(distribution, key) in distributions" :key="key">
                    <a :href="'/investors/' + distribution.user.id" target="_blank" class="distribution-user">
                        <span>{{ distribution.user.credentials }}</span>
                        <strong>{{ distribution.user.name }}</strong>
                    </a>
                    <strong class="distribution-amount">${{ distribution.amount ? distribution.amount.toLocaleString() : '0' }}</strong>
                    <img src="@/assets/icons/refresh.svg" alt="Reinvested" data-bs-toggle="tooltip" title="Reinvested" v-if="distribution.type === 'reinvested'" class="distribution-type">
                    <img src="@/assets/icons/wallet.svg" alt="Distributed" data-bs-toggle="tooltip" title="Distributed" v-if="distribution.type === 'distributed'" class="distribution-type">
                </div>
            </div>
            <button class="btn btn-secondary mb-3" v-if="distributions.length > 0" @click.prevent="deleteDistributions">
                <span :class="{'opacity-0': deleting}">Delete Distributions</span>
                <span class="spinner-border" v-if="deleting"></span>
            </button>
        </div>
        <div class="period-distributions-upload" v-if="loaded">
            <h4>Upload Distributions</h4>
            <p>Please upload <strong>CSV</strong> file containing the distributions &amp; reinvestments. The file should contain the following columns: <i>SumOfNet_Amount</i>, <i>DefaultPmtMethod</i>, <i>ReinvestShares</i>, <i>Account ID</i>.</p>
            <p class="fw-bolder small">If the file contains distributions that are already uploaded, it will overwrite them with the new  data.</p>
            <input type="file" ref="file" class="form-control" @change="readyToUpload = true">
            <button type="button" class="btn btn-primary mt-2" @click.prevent="uploadDistributions" :disabled="uploading || !readyToUpload">
                <span :class="{'opacity-0': uploading}">Upload Distributions</span>
                <span class="spinner-border" v-if="uploading"></span>
            </button>
            <div class="text-danger fw-bolder mt-4" v-if="uploadingError">{{ uploadingError }}</div>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import {readableDate, readableNumber} from "@/helpers";

export default {
    name: "DistributionPeriodDetails",
    components: {},
    props: ['id',],
    emits: ['reload-distribution-periods'],
    data() {
        return {
            distributionPeriodId: null,
            loaded: null,
            error: null,
            year: null,
            quarter: null,
            percentage: null,
            payableDate: null,
            createdAt: null,
            totalDistributed: null,
            totalReinvested: null,
            distributions: [],
            deleting: false,
            uploading: false,
            readyToUpload: false,
            uploadingError: false,
        }
    },
    created() {
        this.loadDetails(this.$route.params.id ?? this.id);
    },
    methods: {
        loadDetails(id) {
            if (!id) return null;

            // Reset data
            this.distributionPeriodId = id;
            this.loaded = false;
            this.error = false;

            // Load data
            repository.get("/distribution-periods/" + id)
                .then(response => {
                    this.year = response.data.distribution_period.year;
                    this.quarter = response.data.distribution_period.quarter;
                    this.percentage = response.data.distribution_period.percentage;
                    this.payableDate = response.data.distribution_period.payable_date;
                    this.createdAt = response.data.distribution_period.created_at;
                    this.totalDistributed = parseInt(response.data.distribution_period.total_distributed);
                    this.totalReinvested = parseInt(response.data.distribution_period.total_reinvested);
                    this.distributions = response.data.distributions;
                    this.loaded = true;
                    this.error = false;
                })
                .catch(error => {
                    this.loaded = true;
                    this.error = true;
                    console.log("Distribution period not found", error);
                });
        },
        uploadDistributions() {
            // Verify file
            let file = this.$refs.file.files[0];
            if(!file) return null;

            // Start loading & prepare data
            this.uploading = true;
            let formData = new FormData();
            formData.append('file', file);

            // Send to server
            repository.post("/distribution-periods/" + this.distributionPeriodId, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    console.log(response.data);
                    this.uploading = false;
                    this.uploadingError = false;
                    this.totalDistributed = response.data.distribution_period.total_distributed;
                    this.totalReinvested = response.data.distribution_period.total_reinvested;
                    this.distributions = response.data.distributions;
                    this.$emit('reload-distribution-periods');
                })
                .catch(error => {
                    this.uploading = false;
                    this.uploadingError = error.response.data.message;
                    console.log("An error happened while saving the distributions", error);
                });
        },
        deleteDistributions() {
            // Start effect
            this.deleting = true;

            // Confirm
            if(!confirm("Are you sure? This would remove all distributions permanently from this distribution period!")) {
                this.deleting = false;
                return null;
            }

            // Send request
            repository.delete("/distribution-periods/" + this.distributionPeriodId + "/distributions")
                .then(() => {
                    this.distributions = [];
                    this.totalReinvested = 0;
                    this.totalDistributed = 0;
                    this.deleting = false;
                    this.$emit('reload-distribution-periods');
                })
                .catch(error => {
                    console.log(error.response.data);
                    console.log("An error happened while deleting the submissions for this period");
                });
        },
        convertToReadableDate(date) {
            return readableDate(date, false);
        },
        convertToReadableNumber(value) {
            return readableNumber(value);
        },
    },
    watch: {
        id(newId) {
            this.loadDetails(newId);
        },
        '$route.params.id'(newId) {
            this.loadDetails(newId);
        }
    },
}
</script>

<style lang="scss" scoped>
.distribution-period-details {

    .period-year {
        font-size: 1.5rem;
        font-weight: bold;
        color: $dark;
        display: inline-block;
    }

    .period-quarter {
        font-size: 1.5rem;
        font-weight: bold;
        color: $dark;
        margin-left: 0.5rem;
        display: inline-block;
    }

    .period-details {
        display: block;

        .period-details-entry {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 0.5rem;
            margin-top: 0.5rem;
            border-top: 1px solid $grey-100;
            font-size: 0.9rem;

            img.period-admin-avatar {
                width: 1.5rem;
                height: 1.5rem;
                margin-bottom: -0.5rem;
                margin-top: -0.5rem;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }

    .period-amounts {
        display: flex;
        margin-top: 0.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid $grey-100;

        .period-amount-item {
            display: block;
            width: 50%;

            strong {
                display: block;
                color: $primary;
                font-size: 1.25rem;
                line-height: 1.2;
            }

            span {
                display: block;
                color: $dark;
                font-size: 0.9rem;
                line-height: 1.2;
            }
        }
    }

    .period-actions {
        margin-top: 1.5rem;
    }

    .period-distributions-wrapper {
        margin-top: 1.5rem;
        background: $grey-100;
        border-radius: $border-radius-large;
        padding: 1rem 1rem 0 1rem;

        h4 {
            font-size: 1.1rem;
            color: $dark;
            font-weight: bold;
        }

        .period-distributions-list {
            overflow-x: hidden;
            width: calc(100% + 2rem);
            margin: 0 -1rem;
            padding: 0 1rem 0.5rem 1rem;
            max-height: 20rem;

            .distribution-entry {
                display: flex;
                align-items: center;
                padding: 0.5rem 0.5rem;
                width: calc(100% + 1rem);
                margin: 0 -0.5rem;
                border-radius: $border-radius-large;
                @extend .animated;

                &:hover {
                    background: $white;

                    .distribution-user {
                        span {
                            background: $primary;
                            color: $white;
                        }

                        strong {
                            color: $primary;
                        }
                    }
                }

                .distribution-user {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: inherit;
                    @extend .animated;

                    span {
                        display: block;
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 2rem;
                        font-weight: bold;
                        text-transform: uppercase;
                        background: rgba($dark, 0.2);
                        font-size: 0.7rem;
                    }

                    strong {
                        margin-left: 0.5rem;
                        font-weight: 600;
                        color: $dark;
                        font-size: 0.9rem;
                    }
                }

                .distribution-amount {
                    margin-left: auto;
                    margin-right: 0.5rem;
                    font-weight: bold;
                    font-size: 0.9rem;
                    color: $primary;
                }

                .distribution-type {
                    width: 1.25rem;
                    height: 1.25rem;
                    opacity: 0.25;
                    cursor: pointer;
                    @extend .animated;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .period-distributions-upload {
        margin-top: 1.5rem;
        background: $grey-100;
        border-radius: $border-radius-large;
        padding: 1rem;

        h4 {
            font-size: 1.1rem;
            color: $dark;
            font-weight: bold;
        }

        p {
            font-size: 0.9rem;

            i {
                background: rgba($dark, 0.1);
                border-radius: $border-radius-medium;
                padding: 0.1rem 0.25rem;
                display: inline-block;
            }
        }

        .period-distributions-list {
            overflow-x: hidden;
            width: calc(100% + 2rem);
            margin: 0 -1rem;
            padding: 0 1rem 0.5rem 1rem;
            max-height: 20rem;

            .distribution-entry {
                display: flex;
                align-items: center;
                padding: 0.5rem 0.5rem;
                width: calc(100% + 1rem);
                margin: 0 -0.5rem;
                border-radius: $border-radius-large;
                @extend .animated;

                &:hover {
                    background: $white;

                    .distribution-user {
                        span {
                            background: $primary;
                            color: $white;
                        }

                        strong {
                            color: $primary;
                        }
                    }
                }

                .distribution-user {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: inherit;
                    @extend .animated;

                    span {
                        display: block;
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 2rem;
                        font-weight: bold;
                        text-transform: uppercase;
                        background: rgba($dark, 0.2);
                        font-size: 0.7rem;
                    }

                    strong {
                        margin-left: 0.5rem;
                        font-weight: 600;
                        color: $dark;
                        font-size: 0.9rem;
                    }
                }

                .distribution-amount {
                    margin-left: auto;
                    margin-right: 0.5rem;
                    font-weight: bold;
                    font-size: 0.9rem;
                    color: $primary;
                }

                .distribution-type {
                    width: 1.25rem;
                    height: 1.25rem;
                    opacity: 0.25;
                    cursor: pointer;
                    @extend .animated;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.loading {
        .period-year,
        .period-quarter {
            color: transparent;
            border-radius: $border-radius-medium;
            background: rgba($dark, 0.1);
            width: 5rem;
            height: 2.25rem;
            display: inline-block;
            vertical-align: middle;
            @extend .disable-clicks;
            @extend .disable-selection;
        }

        .period-details {
            .period-details-entry {
                color: transparent;
                border-radius: $border-radius-medium;
                background: rgba($dark, 0.1);
                width: 100%;
                @extend .disable-clicks;
                @extend .disable-selection;

                * {
                    opacity: 0;
                }
            }
        }

        .period-amounts {
            border-top-color: transparent;

            .period-amount-item {
                color: transparent;
                border-radius: $border-radius-medium;
                background: rgba($dark, 0.1);
                width: calc(50% - 0.75rem);
                @extend .disable-clicks;
                @extend .disable-selection;

                & + .period-amount-item {
                    margin-left: 1.5rem;
                }

                strong, span {
                    opacity: 0;
                }
            }
        }

        .period-distributions-wrapper {
            opacity: 0;
        }
    }
}
</style>