<template>
    <!-- Investments -->
    <div class="investor-contact-details investments-box">
        <h4>Investments</h4>
        <p>Investment portfolio summary for this investor</p>
        <div class="investments-wrapper">
            <div class="investment-summary-item investment-completed">
                <strong>${{ completedInvestments.toLocaleString() }}</strong>
                <span>Completed Investments</span>
            </div>
            <div class="investment-summary-item investment-pending">
                <strong>${{ pendingInvestments.toLocaleString() }}</strong>
                <span>Pending Investments</span>
            </div>
            <div class="investment-summary-item investment-cancelled">
                <strong>${{ cancelledInvestments.toLocaleString() }}</strong>
                <span>Cancelled Investments</span>
            </div>
        </div>

        <h4 class="mt-4 pt-4 border-top">Transactions</h4>
        <p>The investor has made the following investment transactions</p>
        <div class="investor-transaction" v-for="(transaction, key) in investments" :key="key">
            <span class="transaction-id">#{{ transaction.id }}</span>
            <span class="transaction-amount">${{ transaction.amount.toLocaleString() }}</span>
            <span class="transaction-date">{{ transaction.date.day }} {{ transaction.date.month }} {{ transaction.date.year }}</span>
            <span class="transaction-status">
                <span class="badge bg-secondary" v-if="transaction.trade_id === null || transaction.offer_id === null">Incomplete</span>
                <span class="badge bg-info" v-else-if="transaction.status === 'CREATED'">Pending</span>
                <span class="badge bg-success" v-else-if="transaction.status === 'FUNDED'">Completed</span>
                <span class="badge bg-danger" v-else>Cancelled</span>
            </span>
            <div class="transaction-documents">
                <a :href="generateDocumentLink(transaction)" target="_blank" :class="{'btn btn-light': true, 'disabled': transaction.status !== 'FUNDED' || transaction.docu_sign === 0}">
                    <img src="../../../assets/icons/document-alt.svg" alt="Document">
                    <span>Agreement</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {API_URL} from "@/repository/repository";

export default {
    name: "InvestorInvestmentsTransactionsSummary",
    components: {},
    props: ['investments', 'inPopup', ],
    methods: {
        generateDocumentLink(investment) {
            if(investment.status !== 'FUNDED') return '#';
            return API_URL + "/investments/" + investment.id + "/download?t=" + this.token;
        },
    },
    computed: {
        token() {
            return this.$store.getters.token;
        },
        completedInvestments() {
            if(this.investments === null || this.investments.length === 0) return 0;
            let total = 0;
            this.investments.forEach(investment => {
                if(investment.trade_id === null || investment.offer_id === null) return;
                if(investment.status === 'FUNDED') total += investment.amount;
            });
            return total;
        },
        pendingInvestments() {
            if(this.investments === null || this.investments.length === 0) return 0;
            let total = 0;
            this.investments.forEach(investment => {
                if(investment.trade_id === null || investment.offer_id === null) return;
                if(investment.status === 'CREATED') total += investment.amount;
            });
            return total;
        },
        cancelledInvestments() {
            if(this.investments === null || this.investments.length === 0) return 0;
            let total = 0;
            this.investments.forEach(investment => {
                if(investment.trade_id === null || investment.offer_id === null) return;
                if(investment.status !== 'CREATED' && investment.status !== 'FUNDED') total += investment.amount;
            });
            return total;
        },
    },
}
</script>

<style lang="scss" scoped>
.investments-box {
    padding: 1.5rem;
    border-radius: $border-radius-large;
    background: $white;
    box-shadow: rgba($dark, 0.1) 0 0.25rem 0.5rem;

    h4 {
        margin: 0 0 0.25rem 0;
        font-size: 1rem;
        width: 100%;
        font-weight: bold;
        color: $dark;
    }

    p {
        font-size: 0.9rem;
        color: rgba($dark, 0.5);
        margin: 0 0 0.5rem 0;
    }

    .investor-transaction {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;

        & + .investor-transaction {
            border-top: 1px solid rgba($dark, 0.1);
        }

        .transaction-id {
            width: calc((100% - 10rem) / 4);
            flex-shrink: 0;
            flex-grow: 0;
        }

        .transaction-amount {
            width: calc((100% - 8rem) / 4);
            flex-shrink: 0;
            font-weight: bold;
            color: $dark;
            flex-grow: 0;
        }

        .transaction-date {
            width: calc((100% - 6rem) / 4);
            flex-shrink: 0;
            flex-grow: 0;
            color: rgba($dark, 0.5);
            font-size: 0.8rem;
        }

        .transaction-status {
            text-align: right;
            width: calc((100% - 8rem) / 4);
            flex-shrink: 0;
            flex-grow: 0;

            .badge {
                border-radius: $border-radius-large;

                &.bg-success {
                    background: $success !important;
                }

                &.bg-danger {
                    background: $danger !important;
                }

                &.bg-info {
                    background: rgba($dark, 0.075) !important;
                    color: rgba($dark, 0.35);
                }
            }
        }

        .transaction-documents {
            width: 8rem;
            text-align: right;

            .btn.btn-light {
                border: none;
                width: auto;
                height: 2rem;
                padding: 0;
                line-height: 2rem;
                display: inline-flex;
                align-items: center;
                background: none;
                font-size: 0.7rem;
                box-shadow: none;
                outline: none;
                opacity: 0.75;
                @extend .animated;

                &:hover,
                &:focus,
                &:active {
                    box-shadow: none !important;
                    outline: none !important;
                    background: none !important;
                    border: none !important;
                    opacity: 1;
                }

                &.disabled {
                    opacity: 0.25;
                    cursor: default;
                }

                img {
                    width: 1rem;
                    height: 1rem;
                    margin-right: 0.15rem;
                }
            }
        }
    }

    .investments-wrapper {
        display: flex;

        .investment-summary-item {
            display: block;
            width: calc(100% / 3);
            flex-shrink: 0;
            flex-grow: 0;

            strong {
                display: block;
                font-weight: bold;
                font-size: 1.5rem;
            }

            span {
                display: block;
                color: rgba($dark, 0.5);
                font-size: 0.8rem;
                line-height: 1.2;
            }

            &.investment-completed {
                strong {
                    color: $success;
                }
            }

            &.investment-pending {
                strong {
                    color: rgba($dark, 1);
                }
            }

            &.investment-cancelled {
                strong {
                    color: rgba($dark, 1);
                }
            }
        }
    }
}
</style>