<template>
    <div :class="{'onboarding-steps': true, 'in-popup': inPopup, }">
        <h4>Onboarding Progress</h4>
        <p>This lead has completed the following onboarding steps:</p>
        <div :class="{'onboarding-step': true, 'completed': currentStep > 1, 'active': currentStep === 1}">
            <span>Personal & Tax Information</span>
        </div>
        <div :class="{'onboarding-step': true, 'completed': currentStep > 2, 'active': currentStep === 2}">
            <span>Investment Amount</span>
        </div>
        <div :class="{'onboarding-step': true, 'completed': currentStep > 3, 'active': currentStep === 3}">
            <span>Bank Account</span>
        </div>
        <div :class="{'onboarding-step': true, 'completed': currentStep > 4, 'active': currentStep === 4}">
            <span>Agreements</span>
        </div>
        <div :class="{'onboarding-step': true, 'completed': currentStep > 5, 'active': currentStep === 5}">
            <span>Verification</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeadOnboardingProgress",
    props: ['currentStep', 'inPopup', ],
}
</script>

<style lang="scss" scoped>
.onboarding-steps {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //background: rgba($dark, 0.025);
    padding-left: 1.5rem;
    border-left: 1px solid $grey-200;

    &:not(.in-popup) {
        padding: 1.5rem;
        border-radius: $border-radius-large;
        box-shadow: rgba($dark, 0.05) 0 0.5rem 2rem;
        background: $white;
    }

    h4 {
        margin: 0 0 0.25rem 0;
        font-size: 1rem;
        width: 100%;
        font-weight: bold;
        color: $dark;
    }

    p {
        font-size: 0.9rem;
        color: rgba($dark, 0.5);
        margin: 0;
    }

    hr {
        margin: 0.75rem 0;
        width: 100%;
        height: 1px;
        border: none;
        background: $grey-200;
        opacity: 1;
    }

    .onboarding-step {
        display: inline-flex;
        align-items: center;
        position: relative;
        padding: 0 0 0 1.75rem;
        font-size: 1rem;
        border-radius: $border-radius-large;
        margin-top: 0.5rem;

        &.completed {
            &:before {
                background: $success;
            }

            &:after {
                position: absolute;
                top: 0;
                left: 0.325rem;
                bottom: 0.15rem;
                margin: auto;
                width: 0.55rem;
                height: 0.3rem;
                border-left: 2px solid $white;
                border-bottom: 2px solid $white;
                content: ' ';
                transform-origin: center;
                transform: rotate(-45deg);
            }

            span {
                color: $success;
                font-weight: 700;
            }
        }

        &.active {
            &:before {
                background: $dark;
            }

            span {
                color: $dark;
                font-weight: 700;
            }
        }

        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 1.25rem;
            height: 1.25rem;
            background: $grey-100;
            border-radius: 50%;
            content: ' ';
            margin: auto;
        }

        span {
            font-weight: 600;
            color: rgba($dark, 0.5);
        }
    }

    &.in-popup {
        background: rgba($dark, 0.025);
        padding: 1.5rem;
        border-radius: $border-radius-large;
        margin: 1.5rem 0 0 0;
        border: none;

        .onboarding-step:not(.completed, .active):before {
            background: rgba($dark, 0.1);
        }
    }
}
</style>