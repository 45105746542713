import { render, staticRenderFns } from "./DistributionPeriodDetails.vue?vue&type=template&id=0d5318fb&scoped=true&"
import script from "./DistributionPeriodDetails.vue?vue&type=script&lang=js&"
export * from "./DistributionPeriodDetails.vue?vue&type=script&lang=js&"
import style0 from "./DistributionPeriodDetails.vue?vue&type=style&index=0&id=0d5318fb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d5318fb",
  null
  
)

export default component.exports