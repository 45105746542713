<template>
    <div :class="{'sidebar-profile-box': true, 'loading': !user.loaded }">
        <img :src="user.avatar" :alt="user.name" class="profile-box-avatar">
        <div class="profile-box-info">
            <strong class="profile-box-name">{{ user.name }}</strong>
            <span class="profile-box-email">{{ user.email }}</span>
        </div>
        <router-link to="/logout" class="profile-box-logout">
            <img src="../../assets/icons/logout.svg" alt="Logout">
        </router-link>
    </div>
</template>

<script>
export default {
    name: "SidebarProfileBox",
    computed: {
        user() {
            return this.$store.getters.user;
        },
    },
}
</script>

<style lang="scss" scoped>
.sidebar-profile-box {
    position: relative;
    padding: 1.5rem 2.5rem;
    border-top: 1px solid $grey-100;
    margin: 0 -1rem -1rem -1rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;

    &:hover {
        .profile-box-logout {
            opacity: 1;
        }
    }

    .profile-box-avatar {
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        object-fit: cover;
        flex-shrink: 0;
        background: rgba($dark, 0.1);
        @extend .animated;
    }

    .profile-box-info {
        padding-left: 1rem;
        flex-grow: 1;
        overflow: hidden;
        flex-basis: calc(100% - 3rem);

        .profile-box-name {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
            color: $dark;
            @extend .animated;
        }

        .profile-box-email {
            font-size: 0.8rem;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba($dark, 0.5);
            margin-top: 0.25rem;
            @extend .animated;
        }
    }

    .profile-box-logout {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(to right, rgba($white, 0), rgba($white, 1) 60%);
        padding: 0 1.5rem 0 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        @extend .animated;

        img {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &.loading {
        .profile-box-avatar {
            opacity: 0.1;
        }

        .profile-box-info {

            .profile-box-name {
                color: transparent;
                border-radius: $border-radius-medium;
                background: rgba($white, 0.1);
                width: 100%;
                @extend .disable-clicks;
                @extend .disable-selection;
            }

            .profile-box-email {
                color: transparent;
                border-radius: $border-radius-medium;
                background: rgba($white, 0.025);
                width: 100%;
                @extend .disable-clicks;
                @extend .disable-selection;
            }
        }
    }
}
</style>