<template>
    <div id="app">

        <!-- Screens -->
        <router-view v-if="loaded"/>

        <!-- Loading -->
        <div class="loading-effect py-5 text-center" v-if="!loaded">
            <div class="spinner-border text-black-50 opacity-25"></div>
        </div>

    </div>
</template>

<script>
import repository from "@/repository/repository";
import Tooltip from "bootstrap/js/src/tooltip";

export default {
    name: 'App',
    components: {
    },
    data() {
        return {
            loaded: false,
        }
    },
    created() {
        if(this.$store.getters.token) {
            // Verify if user is already signed in
            repository.get("/me")
                .then(response => {
                    this.$store.commit("updateUser", response.data.user);
                    this.loaded = true;
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit("logoutUser");
                    this.$router.replace("/login");
                    this.loaded = true;
                })
        } else {
            this.loaded = true;
        }
    },
    mounted() {
        this.enableTooltips();
    },
    methods: {
        enableTooltips() {
            new Tooltip(document.body, {
                selector: "[data-bs-toggle='tooltip']",
            });
        },
    },
}
</script>

<style lang="scss">
@import "assets/scss/base.scss";
@import "assets/scss/avatars.scss";
</style>
