<template>
    <div class="login-screen">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-xl-5 mx-auto">
                    <form action="#" method="post" @submit.prevent="submit">

                        <!-- Branding -->
                        <div class="d-flex align-items-center justify-content-between mb-5">
                            <img src="@/assets/logo.svg" alt="Logo" class="brand-logo">
                            <h1>Admin Panel</h1>
                        </div>

                        <!-- Form Steps -->
                        <div class="form-field">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" name="email" id="email" v-model="email" placeholder="Your email address" required>
                        </div>
                        <div class="form-field mt-3 pt-3">
                            <label for="password" class="form-label">Password</label>
                            <div class="password-field">
                                <input type="password" class="form-control" name="password" id="password" v-model="password" placeholder="Your password" required>
                            </div>
                        </div>

                        <!-- Form Navigation -->
                        <div class="d-flex flex-column flex-md-row mt-3 pt-3">
                            <button class="btn btn-primary" type="submit" :disabled="loading">
                                <span :class="{'loading-span': loading}">Sign In</span>
                                <span class="spinner-border" v-if="loading"></span>
                            </button>
                        </div>

                        <!-- Errors -->
                        <div class="errors mt-3" v-if="error">
                            <p class="text-danger fw-bolder small m-0" v-if="error === 'missing_data'">Please enter email and password.</p>
                            <p class="text-danger fw-bolder small m-0" v-else-if="error === 'invalid_credentials'">Invalid email or password. Please try again.</p>
                            <p class="text-danger fw-bolder small m-0" v-else>An unexpected error happened. If this continues happening, please contact the developer.</p>
                        </div>

                    </form>
                    <p class="mb-0 mt-3 text-center small text-black-50">All Rights Reserved &copy; Reiturn by Birgo</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "LoginScreen",
    data() {
        return {
            token: this.$store.getters.token,
            email: null,
            password: null,
            error: null,
            loading: false,
        }
    },
    methods: {
        submit() {
            // Start Loading Effect
            this.loading = true;
            this.error = false;

            // Submit Request & Proceed
            repository.post('/login', {
                email: this.email,
                password: this.password,
            })
                .then(response => {
                    console.log(response);

                    // Update Auth Details
                    this.$store.commit("updateAuth", {
                        token: response.data.token,
                    });

                    // Update User
                    this.$store.commit("updateUser", response.data.user);

                    // Proceed to Home page
                    this.loading = false;
                    if(this.redirect) {
                        this.$router.replace(this.redirect);
                    } else {
                        this.$router.replace("/");
                    }
                })
                .catch(error => {
                    this.error = error.response.data.code ?? "error";
                    this.loading = false;
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.login-screen {
    padding: 5rem 0;

    form {
        background: $white;
        padding: 2.5rem;
        border-radius: $border-radius-large;
        box-shadow: rgba($dark, 0.05) 0 1rem 3.5rem;
    }

    .brand-logo {
        max-height: 3rem;
    }

    h1 {
        font-size: 1rem;
        text-transform: uppercase;
        color: $dark;
        margin: 0;
        font-weight: bold;
    }
}
</style>