<template>
    <div class="lead-details-wrapper">

        <!-- Lead Basic Details -->
        <div :class="{'lead-details': true, 'in-popup': inPopup, 'not-popup': !inPopup}" v-if="loaded && !error">

            <!-- Name -->
            <div class="lead-name">
                <div :class="getAvatar(email)">{{ credentials }}</div>
                <strong>{{ name }}</strong>
            </div>

            <div class="row">
                <div :class="{'col-md-12': inPopup, 'col-md-8': !inPopup}">

                    <!-- Contact Details -->
                    <div class="lead-contact-details">
                        <h4>Lead Details</h4>
                        <p>We have collected the following details for this lead:</p>
                        <div class="lead-contact">
                            <strong>Full Name</strong>
                            <span>{{ name }}</span>
                        </div>
                        <div class="lead-contact">
                            <strong>Email</strong>
                            <span>{{ email }}</span>
                        </div>
                        <div class="lead-contact">
                            <strong>Phone</strong>
                            <span>{{ phone }}</span>
                        </div>
                        <div class="lead-contact">
                            <strong>State</strong>
                            <span>{{ fullStateName }}</span>
                        </div>
                        <div class="lead-contact" v-if="investments.length > 0 && investmentAmount">
                            <strong>Investment Amount</strong>
                            <span class="fw-bolder">${{ investmentAmount }}</span>
                        </div>
                        <div class="lead-contact" v-if="autoInvestment !== null">
                            <strong>Auto Investment</strong>
                            <span>{{ autoInvestment }}</span>
                        </div>
                        <div class="lead-contact" v-if="joinedDate">
                            <strong>Joined</strong>
                            <span>{{ joinedDate }}</span>
                        </div>
                        <div class="lead-contact" v-if="verificationCode">
                            <strong>Verification Code</strong>
                            <span class="fw-bolder text-brand">{{ verificationCode }}</span>
                        </div>
                        <div class="lead-contact" v-if="id">
                            <strong>Investor ID</strong>
                            <span>#{{ id }}</span>
                        </div>
                        <div class="lead-contact" v-if="accountId">
                            <strong>Account ID</strong>
                            <a href="https://api.norcapsecurities.com/admin_v3/client/accounts" target="_blank" @click="copyValue(accountId)">
                                {{ accountId }}
                                <img src="../../../assets/icons/external-link.svg" alt="External Link">
                            </a>
                        </div>
                        <div class="lead-contact" v-if="partyId">
                            <strong>Party ID</strong>
                            <a href="https://api.norcapsecurities.com/admin_v3/client/parties" target="_blank" @click="copyValue(partyId)">
                                {{ partyId }}
                                <img src="../../../assets/icons/external-link.svg" alt="External Link">
                            </a>
                        </div>
                        <div class="lead-contact" v-if="entityId">
                            <strong>Entity ID</strong>
                            <a href="https://api.norcapsecurities.com/admin_v3/client/parties/entity" target="_blank" @click="copyValue(entityId)">
                                {{ entityId }}
                                <img src="../../../assets/icons/external-link.svg" alt="External Link">
                            </a>
                        </div>
                    </div>

                </div>
                <div :class="{'col-md-12': inPopup, 'col-md-4': !inPopup}">

                    <!-- Current Step -->
                    <LeadOnboardingProgress :current-step="currentStep" :in-popup="inPopup"></LeadOnboardingProgress>

                </div>
            </div>

            <!-- Change Email -->
            <UpdateUserEmailForm :user-id="id" :in-popup="inPopup"></UpdateUserEmailForm>

            <!-- Change Password -->
            <UpdateUserPasswordForm :user-id="id" :in-popup="inPopup"></UpdateUserPasswordForm>

            <!-- Verify Account -->
            <VerifyLeadForm :user-id="id" :in-popup="inPopup" v-if="currentStep === 5"></VerifyLeadForm>

            <!-- Delete Account -->
            <DeleteLeadForm :user-id="id" :in-popup="inPopup"></DeleteLeadForm>

        </div>

        <!-- Error -->
        <div class="error-message" v-if="loaded && error">
            <img src="../../../assets/icons/error.svg" alt="Error">
            <h4>Lead not found</h4>
            <p>Either the ID is invalid, or the investor has completed their onboarding process.</p>
        </div>

        <!-- Loading Effect -->
        <div class="spinner-border" v-if="!loaded"></div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import LeadOnboardingProgress from "@/components/accounts/leads/LeadOnboardingProgress.vue";
import UpdateUserEmailForm from "@/components/accounts/UpdateUserEmailForm.vue";
import UpdateUserPasswordForm from "@/components/accounts/UpdateUserPasswordForm.vue";
import VerifyLeadForm from "@/components/accounts/leads/VerifyLeadForm.vue";
import DeleteLeadForm from "@/components/accounts/leads/DeleteLeadForm.vue";
import moment from "moment-timezone";
import {generateAvatarClass, getState} from "@/helpers";

export default {
    name: "LeadDetails",
    components: {DeleteLeadForm, VerifyLeadForm, UpdateUserPasswordForm, UpdateUserEmailForm, LeadOnboardingProgress},
    props: ['leadId'],
    data() {
        return {
            id: null,
            name: null,
            email: null,
            phone: null,
            state: null,
            accountId: null,
            partyId: null,
            entityId: null,
            linkId: null,
            autoInvestmentPeriod: null,
            autoInvestmentStartDay: null,
            autoInvestmentAmount: null,
            bankAccountConnected: null,
            dividendReinvestment: null,
            verificationCode: null,
            verified: null,
            createdAt: null,
            kycDocumentUploaded: null,
            investments: [],
            credentials: null,
            joined: null,
            loaded: false,
            error: false,
        }
    },
    created() {
        this.loadDetails(this.$route.params.id ?? this.leadId);
    },
    methods: {
        loadDetails(id) {
            if(!id) return null;
            this.id = id;
            this.loaded = false;
            this.error = false;
            repository.get("/leads/" + id)
                .then(response => {
                    console.log(response.data);
                    this.name = response.data.lead.name;
                    this.email = response.data.lead.email;
                    this.phone = response.data.lead.phone;
                    this.state = response.data.lead.state;
                    this.accountId = response.data.lead.account_id;
                    this.partyId = response.data.lead.party_id;
                    this.entityId = response.data.lead.entity_id;
                    this.linkId = response.data.lead.link_id;
                    this.autoInvestmentPeriod = response.data.lead.auto_investment_period;
                    this.autoInvestmentStartDay = response.data.lead.auto_investment_start_day;
                    this.autoInvestmentAmount = response.data.lead.auto_investment_amount;
                    this.bankAccountConnected = response.data.lead.bank_account_connected;
                    this.dividendReinvestment = response.data.lead.dividend_reinvestment;
                    this.verificationCode = response.data.lead.verification_code;
                    this.verified = response.data.lead.verified;
                    this.createdAt = response.data.lead.created_at;
                    this.kycDocumentUploaded = response.data.lead.kyc_document_uploaded;
                    this.credentials = response.data.lead.credentials;
                    this.joined = response.data.lead.joined;
                    this.investments = response.data.lead.investments;
                    this.loaded = true;
                    this.error = false;
                })
                .catch(error => {
                    this.loaded = true;
                    this.error = true;
                    console.log("Lead not found", error);
                });
        },
        getAvatar(email) {
            return "lead-credentials " + generateAvatarClass({
                email: email,
            });
        },
        copyValue(value) {
            this.$copyText(value);
        },
        deleteAccount() {
            if(!confirm("Are you sure? This will permanently delete the selected lead.")) return;
            this.forms.delete.loading = true;
            repository.delete("/leads/" + this.id)
                .then(() => {
                    this.$root.$emit("leadDeleted", this.id);
                    history.back();
                })
                .catch(error => {
                    this.forms.delete.loading = false;
                    this.forms.delete.status = "error";
                    this.forms.delete.response = error.response.data.message;
                })
        },
    },
    computed: {
        fullStateName() {
            if(this.state === null) return null;
            return getState(this.state);
        },
        investmentAmount() {
            if(!this.loaded) return 0;
            let total = 0;
            this.investments.forEach(investment => {
                total += investment.amount;
            });
            return total.toLocaleString();
        },
        autoInvestment() {
            if(!this.loaded) return null;
            if(this.autoInvestmentPeriod === null) return null;
            if(this.autoInvestmentPeriod === "Disabled") return this.autoInvestmentPeriod;
            return this.autoInvestmentPeriod + " - $" + this.autoInvestmentAmount.toLocaleString();
        },
        joinedDate() {
            if(!this.loaded) return null;
            return moment(this.createdAt).format('MMMM Do YYYY, h:mm A');
        },
        currentStep() {
            if(!this.loaded) return 0;
            if(this.accountId === null || this.partyId === null) return 1;
            if(this.investments.length === 0) return 2;
            if(this.bankAccountConnected === null) return 3;
            if(this.dividendReinvestment === null) return 4;
            if(this.verified === 0) return 5;
            return 1;
        },
        inPopup() {
            return this.leadId;
        },
    },
    watch: {
        leadId(newId) {
            this.loadDetails(newId);
        },
        '$route.params.id'(newId) {
            this.loadDetails(newId);
        }
    },
}
</script>

<style lang="scss" scoped>
.lead-details-wrapper {
    position: relative;

    // Loading Effect
    .spinner-border {
        opacity: 0.25;
        margin: 2.5rem auto;
        display: block;
    }

    // Error Effect
    .error-message {
        padding: 2.5rem 5rem;
        text-align: center;

        img {
            width: 8rem;
            height: 8rem;
            display: block;
            margin: auto;
        }

        h4 {
            font-size: 1.25rem;
            font-weight: bold;
            color: $danger;
            margin: 1.5rem 0 0.5rem 0;
        }
    }

    // Details Wrapper Box
    .lead-details {
        display: block;
        position: relative;

        .lead-name {
            display: flex;
            align-items: center;
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid $grey-200;

            > div {
                width: 3rem;
                height: 3rem;
                text-align: center;
                line-height: 3rem;
                font-weight: bold;
                text-transform: uppercase;
                border-radius: 50%;
            }

            strong {
                margin-left: 0.5rem;
                display: block;
                font-size: 1.25rem;
                font-weight: bold;
                color: $dark;
            }
        }

        .lead-contact-details {
            h4 {
                margin: 0 0 0.25rem 0;
                font-size: 1rem;
                width: 100%;
                font-weight: bold;
                color: $dark;
            }

            p {
                font-size: 0.9rem;
                color: rgba($dark, 0.5);
                margin: 0 0 0.5rem 0;
            }

            .lead-contact {
                display: flex;
                align-items: flex-start;
                padding: 0.5rem 0;

                & + .lead-contact {
                    border-top: 1px solid $grey-200;
                }

                strong {
                    width: 40%;
                    flex-shrink: 0;
                    flex-grow: 0;
                    font-weight: 400;
                    padding-right: 0.5rem;

                    &:after {
                        content: ':';
                    }
                }

                span {
                    font-weight: 600;
                    color: $dark;
                    margin-left: 0.25rem;
                }

                a {
                    font-weight: 600;
                    color: $dark;
                    margin-left: 0.25rem;
                    position: relative;
                    text-decoration: none;
                    padding-right: 1.25rem;

                    &:hover {
                        color: $primary;
                    }

                    img {
                        width: 0.75rem;
                        height: 0.75rem;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        opacity: 0.25;
                    }
                }
            }
        }

        &.in-popup {
            .lead-contact-details {
                background: rgba($dark, 0.025);
                padding: 1.5rem;
                border-radius: $border-radius-large;
            }
        }

        &.not-popup {
            .lead-name {
                margin-bottom: 0;
            }

            .lead-contact-details {
                padding: 1.5rem;
                border-radius: $border-radius-large;
                box-shadow: rgba($dark, 0.05) 0 0.5rem 2rem;
                background: $white;
            }
        }
    }
}
</style>