<template>
    <div class="leads-screen">

        <!-- Welcome Message -->
        <h1 class="dashboard-title">Leads Management</h1>

        <!-- Leads Daily Chart Last 6 months -->
        <div class="row mt-3">
            <div class="col-md-8 mb-4">
                <LeadsDailyChart></LeadsDailyChart>
            </div>
            <div class="col-md-4 mb-4">
                <LeadsSummary></LeadsSummary>
            </div>
        </div>

        <!-- List -->
        <LeadsList></LeadsList>

    </div>
</template>

<script>
import LeadsList from "@/components/accounts/leads/LeadsList.vue";
import LeadsDailyChart from "@/components/statistics/LeadsMonthlyChart.vue";
import LeadsSummary from "@/components/statistics/LeadsSummary.vue";

export default {
    name: "LeadsScreen",
    components: {LeadsSummary, LeadsDailyChart, LeadsList},
}
</script>

<style lang="scss" scoped>
</style>