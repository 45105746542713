<template>
    <!-- Investments -->
    <div class="investor-contact-details investments-box">
        <h4>Distributions &amp; Reinvestments</h4>
        <p>Distributions summary for this investor</p>
        <div class="investments-wrapper">
            <div class="investment-summary-item investment-completed">
                <strong>${{ totalReinvested.toLocaleString() }}</strong>
                <span>Total Reinvested</span>
            </div>
            <div class="investment-summary-item investment-pending">
                <strong>${{ totalDistributed.toLocaleString() }}</strong>
                <span>Total Distributed</span>
            </div>
        </div>

        <p class="mt-4 pt-3 border-top">The investor has received the following distributions & reinvestments</p>
        <div class="investor-transaction" v-for="(distribution, key) in distributions" :key="key">
            <span class="transaction-date">{{ distribution.distribution_period.year }} {{ distribution.distribution_period.quarter }}</span>
            <span class="transaction-amount">${{ distribution.amount.toLocaleString() }}</span>
            <span class="transaction-status">
                <span class="badge bg-secondary" v-if="distribution.type === 'distributed'">Distributed</span>
                <span class="badge bg-success" v-if="distribution.type === 'reinvested'">Reinvested</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "InvestorDistributionsSummary",
    components: {},
    props: ['distributions', 'inPopup', ],
    computed: {
        totalReinvested() {
            return this.distributions.filter(item => item.type === 'reinvested').reduce((total, distribution) => total + distribution.amount, 0);
        },
        totalDistributed() {
            return this.distributions.filter(item => item.type === 'distributed').reduce((total, distribution) => total + distribution.amount, 0);
        },
    },
}
</script>

<style lang="scss" scoped>
.investments-box {
    padding: 1.5rem;
    border-radius: $border-radius-large;
    background: $white;
    box-shadow: rgba($dark, 0.1) 0 0.25rem 0.5rem;

    h4 {
        margin: 0 0 0.25rem 0;
        font-size: 1rem;
        width: 100%;
        font-weight: bold;
        color: $dark;
    }

    p {
        font-size: 0.9rem;
        color: rgba($dark, 0.5);
        margin: 0 0 0.5rem 0;
    }

    .investor-transaction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;

        & + .investor-transaction {
            border-top: 1px solid rgba($dark, 0.1);
        }

        .transaction-amount {
            font-weight: bold;
            color: $primary;
            flex-grow: 0;
        }

        .transaction-date {
            color: $dark;
            font-weight: bold;
            font-size: 1rem;
        }

        .transaction-status {
            text-align: right;
            flex-shrink: 0;
            flex-grow: 0;

            .badge {
                border-radius: $border-radius-large;

                &.bg-success {
                    background: $success !important;
                }

                &.bg-danger {
                    background: $danger !important;
                }

                &.bg-info {
                    background: rgba($dark, 0.075) !important;
                    color: rgba($dark, 0.35);
                }
            }
        }
    }

    .investments-wrapper {
        display: flex;

        .investment-summary-item {
            display: block;
            width: calc(100% / 3);
            flex-shrink: 0;
            flex-grow: 0;

            strong {
                display: block;
                font-weight: bold;
                font-size: 1.5rem;
            }

            span {
                display: block;
                color: rgba($dark, 0.5);
                font-size: 0.8rem;
                line-height: 1.2;
            }

            &.investment-completed {
                strong {
                    color: $success;
                }
            }

            &.investment-pending {
                strong {
                    color: rgba($dark, 1);
                }
            }

            &.investment-cancelled {
                strong {
                    color: rgba($dark, 1);
                }
            }
        }
    }
}
</style>