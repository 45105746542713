<template>
    <div class="distributions-screen">

        <!-- Welcome Message -->
        <div class="row align-items-center">
            <div class="col-md-8">
                <h1 class="dashboard-title mb-3">Distributions Management</h1>
                <p>Using this data, the system will automatically prepare the Performance tab on each investor profile.</p>
            </div>
            <div class="col-md-4 text-md-end">
                <a href="#" class="btn btn-primary" @click.prevent="openNewDistributionPeriodPopup">New Distribution Period</a>
            </div>
        </div>

        <!-- Distribution Periods -->
        <div class="row">
            <div class="col-md-12" v-if="!loaded">
                <div class="spinner-border my-4 opacity-25"></div>
            </div>
            <div class="col-md-12" v-if="loaded && error">
                <div class="text-danger fw-bolder mt-4">{{ error }}</div>
            </div>
            <div class="col-md-12" v-if="loaded && !error && items.length === 0">
                <div class="fw-bolder mt-4">You haven't created any distribution periods.</div>
            </div>
            <div class="col-md-6 col-lg-4 mt-4" v-for="(distributionPeriod, key) in items" :key="key">
                <DistributionPeriodBox
                    :period="distributionPeriod"
                    @deleted="distributionPeriodDeleted"
                    @details="viewDistributionPeriodDetails"
                >
                </DistributionPeriodBox>
            </div>
        </div>

        <!-- Distribution Period Popup -->
        <ModalPopup size="medium" :class="{'active': distributionPeriod}">
            <DistributionPeriodDetails :id="distributionPeriod" v-if="distributionPeriod" @reload-distribution-periods="loadDistributionPeriods"/>
        </ModalPopup>

        <!-- New Distribution Period Popup -->
        <ModalPopup size="medium" :class="{'active': newDistributionPeriodPopup}">
            <NewDistributionPeriodForm v-if="newDistributionPeriodPopup" @reload-distribution-periods="loadDistributionPeriods"/>
        </ModalPopup>

    </div>
</template>

<script>
import DistributionPeriodBox from "@/components/distributions/DistributionPeriodBox.vue";
import DistributionPeriodDetails from "@/components/distributions/DistributionPeriodDetails.vue";
import ModalPopup from "@/components/widgets/ModalPopup.vue";
import repository from "@/repository/repository";
import NewDistributionPeriodForm from "@/components/distributions/NewDistributionPeriodForm.vue";

export default {
    name: "DistributionsScreen",
    components: {NewDistributionPeriodForm, DistributionPeriodDetails, ModalPopup, DistributionPeriodBox},
    data() {
        return {
            loaded: false,
            error: false,
            items: [],
            newDistributionPeriodPopup: false,
            distributionPeriod: null,
        }
    },
    created() {
        this.loadDistributionPeriods(true);

        // Listen for modal closings
        let app = this;
        window.addEventListener('popstate', function () {
            app.distributionPeriod = null;
            app.newDistributionPeriodPopup = null;
            document.documentElement.style.overflow = "auto";
        });
    },
    methods: {
        loadDistributionPeriods(showEffect = false) {
            if(showEffect) this.loaded = false;
            repository.get("/distribution-periods")
                .then(response => {
                    this.items = response.data.distribution_periods;
                    this.loaded = true;
                })
                .catch(error => {
                    this.error = "An error happened while fetching the distribution periods";
                    this.loaded = true;
                    console.log("An error happened while fetching the distribution periods", error);
                })
        },
        openNewDistributionPeriodPopup() {
            this.newDistributionPeriodPopup = true;
            history.pushState({page: 1}, "", "/distributions/new");
        },
        distributionPeriodDeleted(id) {
            let indexToDelete = this.items.findIndex(item => item.id === id);
            if(indexToDelete !== -1) {
                this.items.splice(indexToDelete, 1);
            }
        },
        viewDistributionPeriodDetails(id) {
            this.distributionPeriod = id;
            history.pushState({page: 1}, "", "/distributions/" + id);
            document.documentElement.style.overflow = "hidden";
        },
    },
}
</script>

<style lang="scss" scoped>
</style>