<template>
    <div class="dashboard-box investments-box">
        <h2 class="dashboard-subtitle">States Performance</h2>
        <div class="investment-states-wrapper" v-if="loaded">
            <div class="investment-states-list">
                <div class="investment-state" v-for="(item, key) in preparedStates" :key="key">
                    <strong>{{ item.state }}</strong>
                    <span :class="{'text-brand fw-bolder': item.total > 0}">${{ item.total.toLocaleString() }}</span>
                </div>
            </div>
        </div>
        <div class="spinner-border" v-if="!loaded"></div>
    </div>
</template>

<script>

import repository from "@/repository/repository";
import {states} from "@/helpers";

export default {
    name: "InvestmentsPerState",
    data() {
        return {
            investments: [],
            loaded: false,
            error: false,
        }
    },
    created() {
        this.loadInvestments();
    },
    methods: {
        loadInvestments() {
            this.loaded = false;
            this.error = false;
            repository.get("/advanced-stats/investments")
                .then(response => {
                    this.investments = response.data.states;
                    this.loaded = true;
                })
        },
    },
    computed: {
        preparedStates() {
            if(!this.loaded) return [];
            let results = [];
            console.log(states, Object.keys(states), this.investments);
            Object.keys(states).forEach(shortcode => {
                results.push({
                    state: states[shortcode],
                    total: this.investments[shortcode] ?? 0,
                })
            })
            results.sort((a, b) => {
                if(a.total < b.total) return 1;
                if(a.total > b.total) return -1;
                return 0;
            })
            return results;
        }
    },
}
</script>

<style lang="scss" scoped>
.dashboard-box.investments-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    .spinner-border {
        margin: 2.5rem auto;
        opacity: 0.25;
    }

    .investment-states-wrapper {
        flex-grow: 1;
        position: relative;
        width: calc(100% + 3rem);
        height: 100%;
        margin: 0 -1.5rem -1.5rem -1.5rem;

        .investment-states-list {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            padding: 0 1rem;
        }

        .investment-state {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.25rem 0.5rem;
            border-radius: $border-radius-medium;
            @extend .animated;

            &:hover {
                background: $grey-100;
            }

            strong {
                font-weight: 400;
            }

            span {
                font-weight: 400;
                color: rgba($dark, 0.5);
            }
        }
    }
}
</style>