<template>
    <div class="row">
        <div class="col-md-8 mb-4">
            <div class="dashboard-box">
                <div class="dashboard-subtitle">Onboarding Summary <ExplanationIcon class="ms-1" title="How much of the leads are stuck on each of the onboarding steps"></ExplanationIcon></div>
                <div class="dashboard-chart-wrapper">
                    <div class="spinner-border" v-if="!loaded"></div>
                    <VueApexCharts type="area" height="300" :options="lineChartOptions" :series="lineChartSeries" v-if="loaded"></VueApexCharts>
                </div>
            </div>
        </div>
        <div class="col-md-4 mb-4">
            <div class="dashboard-box">
                <div class="dashboard-subtitle">Users Progress Summary <ExplanationIcon class="ms-1" title="Visual representation of how many leads are stuck on each step & how many have completed their onboarding"></ExplanationIcon></div>
                <div class="dashboard-chart-wrapper">
                    <div class="spinner-border" v-if="!loaded"></div>
                    <VueApexCharts type="bar" height="300" :options="barChartOptions" :series="barChartSeries" v-if="loaded"></VueApexCharts>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import ExplanationIcon from "@/components/widgets/ExplanationIcon.vue";
import VueApexCharts from "vue-apexcharts";

export default {
    name: "OnboardingProgressStats",
    components: {VueApexCharts, ExplanationIcon},
    data() {
        return {
            total_leads: null,
            total_investors: null,
            personal_information_step: null,
            investment_step: null,
            bank_account_step: null,
            agreements_step: null,
            verification_step: null,
            loaded: false,
            error: false,
        }
    },
    created() {
        this.loadStats();
    },
    methods: {
        loadStats() {
            this.loaded = false;
            this.error = false;
            repository.get("/advanced-stats/onboarding-progress")
                .then(response => {
                    this.total_leads = response.data.total_leads;
                    this.total_investors = response.data.total_investors;
                    this.personal_information_step = response.data.personal_information_step;
                    this.investment_step = response.data.investment_step;
                    this.bank_account_step = response.data.bank_account_step;
                    this.agreements_step = response.data.agreements_step;
                    this.verification_step = response.data.verification_step;
                    this.loaded = true;
                })
        },
        calculatePercentage(value) {
            if(!this.loaded) return 0;
            return ((100 / (this.total_leads + this.total_investors)) * value).toFixed(1);
        },
    },
    computed: {
        lineChartCategories() {
            return [
                "Personal Details",
                "Investment",
                "Bank Account",
                "Agreements",
                "Verification",
                "Completed",
            ];
        },
        lineChartSeries() {
            if(!this.loaded) return [];
            let series = [];
            series.push({
                name: "Total",
                type: 'line',
                data: [
                    this.personal_information_step,
                    this.investment_step,
                    this.bank_account_step,
                    this.agreements_step,
                    this.verification_step,
                    this.total_investors,
                ],
            });
            return series;
        },
        lineChartOptions() {
            return {
                chart: {
                    height: 300,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                fill: {
                    type: 'solid',
                },
                colors: [
                    "#16193A",
                ],
                stroke: {
                    curve: 'smooth',
                    width: [4],
                },
                grid: {
                    borderColor: "#eee",
                    padding: {
                        left: 30,
                    }
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [0],
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                    },
                },
                legend: {
                    labels: {
                        colors: "#aaa",
                    }
                },
                xaxis: {
                    type: 'category',
                    categories: this.lineChartCategories,
                    labels: {
                        show: true,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: [{
                        labels: {
                            formatter: function (value) {
                                return (value ? value : 0) + " Leads";
                            },
                        },
                    }
                ],
            };
        },
        barChartCategories() {
            return [
                "Personal Details",
                "Investment",
                "Bank Account",
                "Agreements",
                "Verification",
                "Completed",
            ];
        },
        barChartSeries() {
            if(!this.loaded) return [];
            let series = [];
            series.push({
                name: "Percentage",
                data: [
                    this.calculatePercentage(this.personal_information_step),
                    this.calculatePercentage(this.investment_step),
                    this.calculatePercentage(this.bank_account_step),
                    this.calculatePercentage(this.agreements_step),
                    this.calculatePercentage(this.verification_step),
                    this.calculatePercentage(this.total_investors),
                ],
            });
            return series;
        },
        barChartOptions() {
            return {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                },
                colors: [
                    "#16193A",
                ],
                markers: {
                    size: 4,
                    colors: ['#fff'],
                    strokeColor: '#16193A',
                    strokeWidth: 2,
                },
                dataLabels: {
                    enabled: true,
                    offsetX: 40,
                    style: {
                        colors: ['#16193A'],
                        fontSize: '12px',
                    },
                    formatter: function (value) {
                        return (value ? value : 0) + "%";
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        }
                    }
                },
                xaxis: {
                    categories: this.barChartCategories,
                    labels: {
                        formatter: function (value) {
                            return (value ? value : 0) + "%";
                        },
                    },
                    max: 60,
                    tickAmount: 4,
                },
                yaxis: {
                    labels: {
                    },
                },
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.dashboard-chart-wrapper {
    position: relative;
    min-height: 300px;

    .spinner-border {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        opacity: 0.25;
    }
}
</style>