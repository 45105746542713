<template>
    <div class="dashboard-box chart-box">
        <div class="d-flex justify-content-between">
            <h2 class="dashboard-subtitle">Investments in the last {{ months }} months</h2>
            <select v-model="months" aria-label="Days" class="form-control">
                <option value="3">Last 3 Months</option>
                <option value="6">Last 6 Months</option>
                <option value="12">Last 12 Months</option>
            </select>
        </div>
        <div class="dashboard-chart-wrapper">
            <div class="spinner-border" v-if="!loaded"></div>
            <div class="row gx-0" v-if="loaded">
                <div class="col-md-6">
                    <DailyLineChart :entries="this.chart.funded" name="Funded Investments" color="#1FAE5D" type="line"></DailyLineChart>
                </div>
                <div class="col-md-6">
                    <DailyLineChart :entries="this.chart.cancelled" name="Cancelled Investments" color="#D64933" type="line"></DailyLineChart>
                </div>
                <div class="col-md-6">
                    <DailyLineChart :entries="this.chart.pending" name="Pending Investments" color="#16193A" type="line"></DailyLineChart>
                </div>
                <div class="col-md-6">
                    <DailyLineChart :entries="this.chart.incomplete" name="Incomplete Investments" color="#AAAAAA" type="line"></DailyLineChart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DailyLineChart from "@/components/statistics/DailyLineChart.vue";
import repository from "@/repository/repository";

export default {
    name: "InvestmentsMonthlyLineCharts",
    components: {DailyLineChart },
    data: function () {
        return {
            loaded: false,
            months: 6,
            chart: {
                incomplete: [],
                pending: [],
                funded: [],
                cancelled: [],
            },
        }
    },
    created() {
        this.getInvestments();
    },
    methods: {
        getInvestments() {
            this.loaded = false;
            repository.get("/investments/charts/monthly?months=" + this.months)
                .then(response => {
                    this.chart.incomplete = response.data.incomplete;
                    this.chart.pending = response.data.pending;
                    this.chart.funded = response.data.funded;
                    this.chart.cancelled = response.data.cancelled;
                    this.loaded = true;
                })
                .catch(error => {
                    console.log("Error while fetching Investments Data", error);
                })
        },
    },
    watch: {
        months() {
            this.getInvestments();
        }
    }
}
</script>

<style lang="scss" scoped>
.dashboard-chart-wrapper {
    position: relative;
    min-height: 360px;

    .spinner-border {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        opacity: 0.25;
    }
}

.dashboard-box.chart-box {
    .form-control {
        width: 10rem;
        border: none;
        outline: none;
        box-shadow: none;
        background: rgba($dark, 0.05);
        color: $dark;
        border-radius: $border-radius-medium;
        cursor: pointer;
        font-weight: 600;
        text-align: center;
        @extend .animated;

        &:hover {
            background: rgba($dark, 0.1);
        }

        option {
            background: $white;
        }
    }
}
</style>