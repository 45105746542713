<template>
    <div class="property-box">
        <img :src="propertyImage" :alt="property.name" class="property-image">
        <div class="property-details">
            <strong class="property-name">{{ property.name }}</strong>
            <p class="property-address">{{ property.address }}</p>
        </div>
        <div class="property-actions">
            <div @click.prevent="$emit('details', property.id)" class="btn btn-circle" data-bs-toggle="tooltip" title="View & Edit">
                <img src="../../assets/icons/settings.svg" alt="View & Edit" class="btn-icon">
            </div>
            <div @click.prevent="deleteProperty" class="btn btn-circle" data-bs-toggle="tooltip" title="Delete Property">
                <img src="../../assets/icons/trash.svg" alt="Trash" class="btn-icon">
            </div>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "PropertyItem",
    props: ['property',],
    data() {
        return {
            loading: false,
        }
    },
    emits: ['details', 'deleted'],
    methods: {
        deleteProperty() {
            if(!confirm("Are you sure? This will permanently remove the property from the platform.")) return;
            this.loading = true;
            repository.delete("/properties/" + this.property.id)
                .then(() => {
                    this.$emit("deleted", this.property.id);
                })
                .catch(error => {
                    console.log(error.response.data.message);
                })
        },
    },
    computed: {
        propertyImage() {
            if(this.loading) return null;
            if(this.property.images === null || this.property.images.length === 0) return null;
            return this.property.images[0].image;
        },
    },
}
</script>

<style lang="scss" scoped>
.property-box {
    display: flex;
    align-items: center;

    &:not(:last-child) {
        border-bottom: 1px solid $grey-100;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .property-image {
        width: 5rem;
        height: 3rem;
        object-fit: cover;
        border-radius: $border-radius-medium;
        flex-shrink: 0;
    }

    .property-details {
        display: block;
        position: relative;
        padding: 0 1rem;

        strong {
            display: block;
        }

        p {
            display: block;
            margin: 0;
            font-size: 0.9rem;
            color: rgba($dark, 0.5);
        }
    }

    .property-actions {
        margin-left: auto;
        opacity: 0;
        @extend .animated;

        .btn-circle {
            width: 3rem;
            height: 3rem;
            background: rgba($dark, 0.05);
            border: none;
            padding: 0;
            text-align: center;
            line-height: 3rem;
            border-radius: 50%;
            margin-left: 0.5rem;
            @extend .animated;

            &:hover,
            &:focus {
                background: rgba($dark, 0.1);
                border-color: transparent;
                outline: none;
                box-shadow: none;
            }

            &:active {
                background: rgba($dark, 0.15);
                border-color: transparent;
                box-shadow: none;
                outline: none;
                transform-origin: center;
                transform: scale(0.925);
            }

            img.btn-icon {
                margin: 0;
            }
        }
    }

    &:hover {
        .property-actions {
            opacity: 1;
        }
    }
}
</style>