<template>
    <div class="verifications-screen">

        <!-- Welcome Message -->
        <h1 class="dashboard-title">Verification Center</h1>

        <!-- Stats -->
        <VerificationStats></VerificationStats>

        <!-- Pending Reviews List -->
        <VerificationList :items="pendingUsers" :loaded="users.loaded" type="pending"></VerificationList>

        <!-- Disapproved Users List -->
        <VerificationList :items="disapprovedUsers" :loaded="users.loaded" type="disapproved"></VerificationList>

    </div>
</template>

<script>
import repository from "@/repository/repository";
import VerificationStats from "@/components/verifications/VerificationStats.vue";
import VerificationList from "@/components/verifications/VerificationList.vue";

export default {
    name: "VerificationsScreen",
    components: {VerificationList, VerificationStats },
    data() {
        return {
            users: {
                loaded: false,
                items: [],
            },
        }
    },
    created() {
        this.loadUsers();

        // Disable previous listeners
        this.$root.$off('verificationUserApproved');
        this.$root.$off('verificationUserDisapproved');

        // Listen for any changes
        this.$root.$on('verificationUserApproved', (user) => {
            let userIndex = this.users.items.indexOf(user);
            if(userIndex < 0) return null;
            this.users.items.splice(userIndex, 1);
        });
        this.$root.$on('verificationUserDisapproved', () => {
            this.loadUsers();
        });
    },
    methods: {
        loadUsers() {
            this.users.loaded = false;
            repository.get("/verifications")
                .then(response => {
                    this.users.items = response.data.users;
                    this.users.loaded = true;
                });
        },
    },
    computed: {
        pendingUsers() {
            if(!this.users.loaded) return [];
            return this.users.items.filter(user => user.kyc_document_uploaded);
        },
        disapprovedUsers() {
            if(!this.users.loaded) return [];
            return this.users.items.filter(user => !user.kyc_document_uploaded);
        },
    },
}
</script>

<style lang="scss" scoped>

</style>