<template>
    <div class="dashboard-screen">

        <!-- Welcome Message -->
        <h1 class="dashboard-title">Welcome, {{ user.name }}!</h1>

        <!-- Summary -->
        <DashboardBasicStats></DashboardBasicStats>

        <div class="row">
            <div class="col-md-8 mb-4">
                <!-- Investments last 30 days -->
                <DashboardInvestmentsChart></DashboardInvestmentsChart>
            </div>
            <div class="col-md-4 mb-4">
                <!-- Fund Summary -->
                <DashboardFundSummary></DashboardFundSummary>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 mb-4">
                <!-- Users Summary -->
                <DashboardUsersSummary></DashboardUsersSummary>
            </div>
            <div class="col-md-8 mb-4">
                <!-- Leads vs. Investors last 30 days -->
                <DashboardLeadsVsInvestorsChart></DashboardLeadsVsInvestorsChart>
            </div>
        </div>


    </div>
</template>

<script>
import DashboardInvestmentsChart from "@/components/statistics/DashboardInvestmentsChart.vue";
import DashboardBasicStats from "@/components/statistics/DashboardBasicStats.vue";
import DashboardFundSummary from "@/components/statistics/DashboardFundSummary.vue";
import DashboardLeadsVsInvestorsChart from "@/components/statistics/DashboardLeadsVsInvestorsChart.vue";
import DashboardUsersSummary from "@/components/statistics/DashboardUsersSummary.vue";

export default {
    name: "DashboardScreen",
    components: {
        DashboardUsersSummary,
        DashboardLeadsVsInvestorsChart,
        DashboardFundSummary,
        DashboardBasicStats,
        DashboardInvestmentsChart,
    },
    computed: {
        user() {
            return this.$store.getters.user;
        }
    },
}
</script>

<style lang="scss" scoped>
</style>