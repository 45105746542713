<template>
    <div :class="{'dashboard-box fund-summary-box h-100': true, 'loading': !loaded}">
        <h2 class="dashboard-subtitle">Accounts Summary</h2>

        <!-- Active Investors -->
        <div class="summary-main-entry">
            <VueCountTo class="summary-number" :autoplay="false" :startVal="0" :endVal="active_investors" :duration="1000" ref="activeInvestors"></VueCountTo>
            <div class="summary-title">Active Investors</div>
        </div>

        <!-- New Investors -->
        <div class="summary-entry">
            <div class="summary-title">New Investors</div>
            <VueCountTo class="summary-number text-success fw-bolder" :autoplay="false" :startVal="0" :endVal="new_investors" :duration="1000" ref="newInvestors"></VueCountTo>
            <ExplanationIcon title="New investors in the last 30 days."></ExplanationIcon>
        </div>

        <!-- Inactive Investors -->
        <div class="summary-entry">
            <div class="summary-title">Inactive Investors</div>
            <VueCountTo class="summary-number text-danger fw-bolder" :autoplay="false" :startVal="0" :endVal="inactive_investors" :duration="1000" ref="inactiveInvestors"></VueCountTo>
            <ExplanationIcon title="These users have completed their onboarding process, but don't have any active investments."></ExplanationIcon>
        </div>

        <!-- Total Leads -->
        <div class="summary-entry">
            <div class="summary-title">Total Leads</div>
            <VueCountTo class="summary-number" :autoplay="false" :startVal="0" :endVal="total_leads" :duration="1000" ref="totalLeads"></VueCountTo>
            <ExplanationIcon title="These are users that have started the onboarding process, but didn't complete it."></ExplanationIcon>
        </div>

        <!-- New Leads -->
        <div class="summary-entry">
            <div class="summary-title">New Leads</div>
            <VueCountTo class="summary-number" :autoplay="false" :startVal="0" :endVal="new_leads" :duration="1000" ref="newLeads"></VueCountTo>
            <ExplanationIcon title="New leads in the last 30 days."></ExplanationIcon>
        </div>

    </div>
</template>

<script>
import VueCountTo from 'vue-count-to';
import repository from "@/repository/repository";
import ExplanationIcon from "@/components/widgets/ExplanationIcon.vue";

export default {
    name: "DashboardUsersSummary",
    components: {ExplanationIcon, VueCountTo },
    data: function () {
        return {
            loaded: false,
            active_investors: 0,
            inactive_investors: 0,
            total_leads: 0,
            new_leads: 0,
            new_investors: 0,
        }
    },
    created() {
        this.getSummary();
    },
    methods: {
        getSummary() {
            this.loaded = false;
            repository.get("/dashboard/stats/users-summary")
                .then(response => {
                    this.active_investors = response.data.active_investors;
                    this.inactive_investors = response.data.inactive_investors;
                    this.total_leads = response.data.total_leads;
                    this.new_leads = response.data.new_leads;
                    this.new_investors = response.data.new_investors;
                    this.loaded = true;
                    this.$refs.activeInvestors.start();
                    this.$refs.inactiveInvestors.start();
                    this.$refs.totalLeads.start();
                    this.$refs.newLeads.start();
                    this.$refs.newInvestors.start();
                })
                .catch(error => {
                    console.log("Error while fetching Fund Summary Data", error);
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.fund-summary-box {

    // Main Entry
    .summary-main-entry {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $grey-200;
        @extend .animated;

        .summary-number {
            font-size: 2rem;
            font-weight: bold;
            color: $primary;
        }

        .summary-title {
            display: block;
            color: rgba($dark, 0.5);
            font-weight: 600;
            font-size: 0.8rem;

            &.fw-bolder {
                color: $dark;
            }
        }
    }

    // Basic Entry
    .summary-entry {
        display: flex;
        align-items: center;
        @extend .animated;

        & + .summary-entry {
            margin-top: 0.5rem;
        }

        .summary-title {
            display: block;
            color: rgba($dark, 0.5);
            font-weight: 600;
            font-size: 0.8rem;
        }

        .summary-number {
            color: $dark;
            font-weight: 600;
            margin-left: auto;
            margin-right: 0.5rem;
        }
    }

    // Loading
    &.loading {
        @extend .disable-clicks;
        @extend .disable-selection;

        .summary-main-entry {
            border-radius: $border-radius-large;
            border-bottom-color: transparent;
            background: rgba($dark, 0.1);

            .summary-title, .summary-number {
                color: transparent;
            }
        }

        .summary-entry {
            border-radius: $border-radius-medium;
            border-bottom-color: transparent;
            background: rgba($dark, 0.05);

            * {
                opacity: 0;
            }

            .summary-title, .summary-number {
                color: transparent;
            }
        }
    }
}
</style>