<template>
    <div class="row mt-3">
        <div class="col-md-6 col-lg-3 mb-4">
            <div :class="{'dashboard-box box-clickable stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="Users that have submitted their documents, and are pending approval">
                <VueCountTo class="stats-box-number" :autoplay="false" :startVal="0" :endVal="pending" :duration="1000" ref="pending"></VueCountTo>
                <span class="fw-bolder">Pending Review</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <div :class="{'dashboard-box box-clickable stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="Users that have failed verification, and did not submit documents, or their documents were not approved">
                <VueCountTo class="stats-box-number text-danger" :autoplay="false" :startVal="0" :endVal="disapproved" :duration="1000" ref="disapproved"></VueCountTo>
                <span class="fw-bolder">Disapproved Accounts</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <div :class="{'dashboard-box box-clickable stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="Users that have been approved manually">
                <VueCountTo class="stats-box-number" :autoplay="false" :startVal="0" :endVal="manuallyApproved" :duration="1000" ref="manuallyApproved"></VueCountTo>
                <span class="fw-bolder">Manually Approved</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <div :class="{'dashboard-box box-clickable stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="Users that have been verified & approved automatically">
                <VueCountTo class="stats-box-number text-success" :autoplay="false" :startVal="0" :endVal="autoApproved" :duration="1000" ref="approved"></VueCountTo>
                <span class="fw-bolder">Auto-Approved</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </div>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import VueCountTo from 'vue-count-to';

export default {
    name: "VerificationStats",
    components: { VueCountTo },
    data: function () {
        return {
            loaded: false,
            autoApproved: 0,
            manuallyApproved: 0,
            disapproved: 0,
            pending: 0,
        }
    },
    created() {
        this.loadStats();
    },
    methods: {
        loadStats() {
            this.loaded = false;
            repository.get("/verifications/stats")
                .then(response => {
                    this.autoApproved = response.data.auto_approved;
                    this.manuallyApproved = response.data.manually_approved;
                    this.disapproved = response.data.disapproved;
                    this.pending = response.data.pending;
                    this.loaded = true;
                    this.startCounting();
                });
        },
        startCounting() {
            this.$refs.pending.start();
            this.$refs.disapproved.start();
            this.$refs.approved.start();
            this.$refs.manuallyApproved.start();
        },
    },
}
</script>

<style lang="scss" scoped>
.stats-box {
    .stats-box-number {
        font-weight: bold;
        display: block;
        font-size: 2rem;
        color: $dark;
    }

    span {
        display: block;
        color: rgba($dark, 0.5);
        font-weight: 600;
        font-size: 0.8rem;

        &.fw-bolder {
            color: $dark;
        }
    }

    &.loading {
        position: relative;
        @extend .disable-clicks;
        @extend .disable-selection;

        .stats-box-number {
            color: transparent !important;
            @extend .disable-clicks;
            @extend .disable-selection;
        }

        span {
            color: transparent !important;
            @extend .disable-clicks;
            @extend .disable-selection;
        }

        .spinner-border {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.25;
            margin: auto;
        }
    }
}
</style>