<template>
    <div class="row">
        <div class="col-md-8">
            <!-- Investments List -->
            <div class="dashboard-box">

                <!-- Filter Investments -->
                <div class="filter-investors-field mb-2">
                    <img src="@/assets/icons/search.svg" alt="Search">
                    <input type="text" class="form-control" v-model="searchTerm" placeholder="Search by name, email or transaction id..">
                </div>

                <!-- Investments List -->
                <div class="investors-list-wrapper" ref="investmentsList">
                    <!-- Investments Entries -->
                    <InvestmentsListItem :investment="investment" v-for="(investment, key) in filteredInvestments" :key="key"></InvestmentsListItem>

                    <!-- No Investments -->
                    <p class="small py-2" v-if="loaded && investments.length === 0">No investments found in the system.</p>

                    <!-- No Investments -->
                    <p class="small py-2" v-if="loaded && investments.length > 0 && filteredInvestments.length === 0">No investments found for your search term. Please try something else.</p>

                    <!-- Loading effect -->
                    <div class="spinner-border" v-if="!loaded"></div>

                    <!-- Lead Details Popup -->
                    <ModalPopup size="large" :class="{'active': investmentDetails !== null}">
                        <InvestmentDetails :investment="investmentDetails" v-if="investmentDetails"/>
                    </ModalPopup>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4 mt-md-0">
            <!-- Search, Filters & Export -->
            <div class="dashboard-box">

                <!-- Type -->
                <div class="form-field">
                    <label for="paymentStatus" class="form-label">Status</label>
                    <select class="form-control" name="paymentStatus" id="paymentStatus" v-model="filters.paymentStatus">
                        <option value="all">All Transactions</option>
                        <option value="funded">Funded</option>
                        <option value="pending">Pending</option>
                        <option value="incomplete">Incomplete</option>
                        <option value="cancelled">Cancelled</option>
                    </select>
                    <p class="small opacity-50 mt-2 mb-0" v-if="filters.paymentStatus === 'incomplete'">Incomplete investments refer to investments originating from leads that have not yet initiated payment.</p>
                    <p class="small opacity-50 mt-2 mb-0" v-if="filters.paymentStatus === 'pending'">Pending investments are those where payment has been initiated, but the funds have not yet been received.</p>
                </div>

                <!-- Period -->
                <div class="form-field mt-4">
                    <label for="period" class="form-label">Period</label>
                    <select class="form-control" name="period" id="period" v-model="filters.period">
                        <option value="all">All</option>
                        <option value="7">Last 7 days</option>
                        <option value="14">Last 14 days</option>
                        <option value="30">Last 30 days</option>
                        <option value="180">Last 6 months</option>
                        <option value="365">Last 12 months</option>
                        <option value="custom">Custom</option>
                    </select>
                </div>

                <!-- Custom Period -->
                <div class="row mt-4" v-if="filters.period === 'custom'">
                    <div class="col-6">
                        <div class="form-field">
                            <label for="start" class="form-label">Start</label>
                            <input type="date" class="form-control" name="start" id="start" v-model="filters.start">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field">
                            <label for="end" class="form-label">End</label>
                            <input type="date" class="form-control" name="end" id="end" v-model="filters.end">
                        </div>
                    </div>
                </div>

                <!-- Total Invested -->
                <div class="form-field mt-4">
                    <label for="amount" class="form-label">Investment Amount</label>
                    <select class="form-control" name="amount" id="amount" v-model="filters.amount">
                        <option value="all">All</option>
                        <option value="500">More than $500</option>
                        <option value="1000">More than $1,000</option>
                        <option value="1500">More than $1,500</option>
                        <option value="2500">More than $2,500</option>
                        <option value="5000">More than $5,000</option>
                        <option value="10000">More than $10,000</option>
                    </select>
                </div>

                <!-- Export -->
                <button @click.prevent="exportInvestments" class="btn btn-primary d-block w-100 mt-4" :disabled="exporting">
                    <span class="spinner-border" v-if="exporting"></span>
                    <span :class="{'opacity-0': exporting}">Export Investments</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import ModalPopup from "@/components/widgets/ModalPopup.vue";
import InvestmentDetails from "@/components/investments/InvestmentDetails.vue";
import InvestmentsListItem from "@/components/investments/InvestmentsListItem.vue";
import {isDateInPeriod, isDateWithinLastDays} from "@/helpers";

export default {
    name: "InvestmentsList",
    components: {InvestmentsListItem, InvestmentDetails, ModalPopup},
    data() {
        return {
            loaded: false,
            investments: [],
            end: 20,
            perPage: 20,
            investmentDetails: null,
            searchTerm: null,
            filters: {
                paymentStatus: 'all',
                period: 'all',
                start: null,
                end: null,
                amount: 'all',
            },
            exporting: false,
        }
    },
    created() {
        // Disable previous listeners
        this.$root.$off('closePopups');
        this.$root.$off('openInvestmentPopup');

        // Listen for any changes
        this.$root.$on('closePopups', () => {
            this.investmentDetails = null;
            document.documentElement.style.overflow = "auto";
        });
        this.$root.$on('openInvestmentPopup', (investment) => {
            history.pushState({page: 1}, "", "/investments/" + investment.id);
            this.investmentDetails = investment;
            document.documentElement.style.overflow = "hidden";
        });
        let app = this;
        window.addEventListener('popstate', function () {
            app.investmentDetails = null;
            document.documentElement.style.overflow = "auto";
        });
    },
    mounted() {
        // Load Investments
        this.getInvestments();

        // Add event listener
        this.$refs.investmentsList.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        // Remove event listener
        this.$refs.investmentsList.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        getInvestments() {
            this.loaded = false;
            repository.get("/investments")
                .then(response => {
                    this.investments = response.data.investments;
                    this.loaded = true;
                })
                .catch(error => {
                    console.log("Error while fetching the investments", error);
                })
        },
        handleScroll() {
            const scrollOffset = this.$refs.investmentsList.scrollTop + this.$refs.investmentsList.clientHeight
            if (scrollOffset >= this.$refs.investmentsList.scrollHeight - 20) {
                this.end += this.perPage
                if(this.end >= this.investments.length) this.end = this.investments.length;
            }
        },
        exportInvestments() {
            if (this.exporting) return;
            if (this.loaded && this.filteredInvestments.length === 0) return;
            this.exporting = true;
            repository.post("/investments/export", {
                payment_status: this.filters.paymentStatus,
                period: this.filters.period,
                start: this.filters.start,
                end: this.filters.end,
                amount: this.filters.amount,
            })
                .then(response => {
                    const blob = new Blob([response.data], {type: 'text/csv'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = "Reiturn Investments"+ (this.filters.period !== 'all' ? (this.filters.period === 'custom' ? ' ('+ this.filters.start +' - '+ this.filters.end +')' : ' (Last '+ this.filters.period +' days)') : '') +".csv";
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.exporting = false;
                })
                .catch(error => {
                    console.log(error);
                    this.exporting = false;
                })
        },
    },
    computed: {
        filteredInvestments() {
            return this.investments.filter(investment => {
                if(!this.searchTerm || this.searchTerm.trim().length === 0) return investment;
                return investment.user.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || investment.user.email.toLowerCase().includes(this.searchTerm.toLowerCase()) || investment.id == this.searchTerm;
            }).filter(investment => {
                if(this.filters.paymentStatus === 'all') return investment;
                return investment.payment_status === this.filters.paymentStatus;
            }).filter(investment => {
                if(this.filters.period === 'all') {
                    return investment;
                } else if(this.filters.period === 'custom') {
                    if(this.filters.start === null || this.filters.end === null) return investment;
                    return isDateInPeriod(investment.created_at, this.filters.start, this.filters.end);
                } else {
                    return isDateWithinLastDays(investment.created_at, parseInt(this.filters.period));
                }
            }).filter(investment => {
                if(this.filters.amount === 'all') return investment;
                return investment.amount >= this.filters.amount;
            }).slice(0, this.end);
        },
    },
    watch: {
        searchTerm() {
            this.end = 20;
        },
    },
}
</script>

<style lang="scss" scoped>
.filter-investors-field {
    position: relative;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0.5rem;
        margin: auto;
        width: 1rem;
        height: 1rem;
        opacity: 0.25;
        z-index: 1;
    }

    .form-control {
        box-shadow: none;
        padding: 0.5rem 0.5rem 0.5rem 2rem;
        border: none;
        outline: none;
        color: $dark;
        position: relative;
        z-index: 0;
        @extend .animated;

        &:focus {
            background: $grey-200;
        }
    }
}

.investors-list-wrapper {
    position: relative;
    min-height: 15rem;
    max-height: 40rem;
    overflow-x: hidden;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    width: calc(100% + 3rem);
    margin: -0.5rem -1.5rem -1.5rem -1.5rem;

    .spinner-border {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.15;
    }
}
</style>