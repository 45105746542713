/**
 * Generates avatar class for the provided user.
 * The user object should contain an `email` parameter.
 * The avatar classes are in the format of `avatar-class-{i}`.
 *
 * @param user
 * @returns {string}
 */
export function generateAvatarClass(user) {
    if(!user || !user.email) return "avatar-color-1";
    let hash = 0;
    for (let i = 0; i < user.email.length; i++) {
        hash += user.email.charCodeAt(i);
    }
    let num = (hash & 0x7fffffff) % 14 + 1;
    return "avatar-color-" + num;
}

/**
 * Converts any string to uppercase-first-letter type.
 * For example, "john Smith" would become "John Smith".
 *
 * @param str
 * @returns {string}
 */
export function ucwords(str) {
    return str.toLowerCase().replace(/(^|\s)\S/g, function(firstLetter) {
        return firstLetter.toUpperCase();
    });
}

/**
 * Returns full State name from the provided State Shortcode.
 * For example, "AL" returns "Alabama".
 *
 * @param stateShortCode
 * @returns {string}
 */
export function getState(stateShortCode) {
    return states[stateShortCode];
}

/**
 * The provided object contains all states in the system.
 */
export const states = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FL": "Florida",
    "GA": "Georgia",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PA": "Pennsylvania",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
};

/**
 * Converts a date string in the format "2023-08-13T15:19:39.000000Z"
 * to a formatted string in the style "August 13, 2023 3:19 PM" if full is true,
 * otherwise return "August 13, 2023" if full is false.
 *
 * @param date
 * @param full
 * @returns {string}
 */
export function readableDate(date, full = true) {
    // Convert the given date string to a Date object
    const dateObject = new Date(date);

    // Options for formatting the date
    let options = {};
    if(full) {
        options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
    } else {
        options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
    }

    // Convert the Date object to the desired format
    return dateObject.toLocaleString('en-US', options);
}

/**
 * Verifies if a given date is within a specified period between two dates.
 *
 * @param {string} dateString - The date in ISO 8601 format (e.g., "2023-05-20T05:14:28.000000Z").
 * @param {string} startDateString - The start date of the period in "YYYY-MM-DD" format.
 * @param {string} endDateString - The end date of the period in "YYYY-MM-DD" format.
 * @returns {boolean} True if the date is within the specified period, false otherwise.
 */
export function isDateInPeriod(dateString, startDateString, endDateString) {
    const currentDate = new Date(dateString);
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    return currentDate >= startDate && currentDate <= endDate;
}

/**
 * Checks if a given date is within the last specified number of days.
 *
 * @param {string} dateString - The date in ISO 8601 format (e.g., "2023-05-20T05:14:28.000000Z").
 * @param {number} days - The number of days to check against.
 * @returns {boolean} True if the date is within the last X days, false otherwise.
 */
export function isDateWithinLastDays(dateString, days) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const currentDate = new Date();
    const targetDate = new Date(dateString);
    const timeDifference = currentDate - targetDate;
    const daysAgo = timeDifference / millisecondsPerDay;
    return daysAgo <= days;
}

/**
 * Returns the provided value in a readable $ format.
 * For example, 154000 would become $154K.
 *
 * @param value
 * @returns {string}
 */
export function readableNumber(value) {
    if (value >= 500000) {
        return "$" + (value / 1000000).toFixed(1) + "M";
    }
    if (value >= 10000) {
        return "$" + (value / 1000).toFixed(1) + "K";
    }
    return "$" + value;
}

/**
 * Generates a random string of the specified length using characters from A-Z, a-z, and 0-9.
 *
 * @param {number} length - The length of the random string to generate.
 * @returns {string} A random string containing only A-Z, a-z, and 0-9 characters.
 */
export function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }
    return result;
}