<template>
    <div class="dashboard-sidebar">

        <!-- Branding -->
        <router-link to="/" class="navbar-brand">
            <img src="../../assets/logo.svg" alt="Reiturn">
        </router-link>

        <!-- Links -->
        <h4 class="d-none d-md-block">Main</h4>
        <ul class="nav">
            <li class="nav-item">
                <router-link to="/" class="nav-link">
                    <img src="../../assets/icons/home.svg" alt="Dashboard">
                    <span>Dashboard</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/investors" class="nav-link">
                    <img src="../../assets/icons/users.svg" alt="Investors">
                    <span>Investors</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/leads" class="nav-link">
                    <img src="../../assets/icons/star.svg" alt="Leads">
                    <span>Leads</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/verifications" class="nav-link">
                    <img src="../../assets/icons/shield-check.svg" alt="Verifications">
                    <span>Verifications</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/investments" class="nav-link">
                    <img src="../../assets/icons/document-alt.svg" alt="Investments">
                    <span>Investments</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/refunds" class="nav-link">
                    <img src="../../assets/icons/refresh.svg" alt="Refunds">
                    <span>Refunds</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/distributions" class="nav-link">
                    <img src="../../assets/icons/pie-chart.svg" alt="Distributions">
                    <span>Distributions</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/stats" class="nav-link">
                    <img src="../../assets/icons/statistics.svg" alt="Statistics">
                    <span>Advanced Statistics</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/properties" class="nav-link">
                    <img src="../../assets/icons/buildings.svg" alt="Buildings">
                    <span>Properties</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/impact" class="nav-link">
                    <img src="../../assets/icons/earth.svg" alt="Impact">
                    <span>Impact</span>
                </router-link>
            </li>
        </ul>

        <!-- Links -->
        <div v-if="adminHasFullAccess">
            <h4 class="d-none d-md-block">Admins Only</h4>
            <ul class="nav">
                <li class="nav-item">
                    <router-link to="/admins" class="nav-link">
                        <img src="../../assets/icons/users.svg" alt="Admins">
                        <span>Administrators</span>
                    </router-link>
                </li>
            </ul>
        </div>

        <!-- Current User -->
        <SidebarProfileBox/>
    </div>
</template>

<script>
import SidebarProfileBox from "@/components/layouts/SidebarProfileBox.vue";

export default {
    name: "DashboardSidebar",
    components: {SidebarProfileBox},
    computed: {
        adminHasFullAccess() {
            return this.$store.getters.user.type === 1;
        },
    },
}
</script>

<style lang="scss" scoped>
.dashboard-sidebar {
    background: $white;
    width: 20rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 1rem;

    .navbar-brand {
        padding: 1.5rem 1.5rem;
        display: block;

        img {
            width: 10rem;
        }
    }

    h4 {
        font-size: 0.9rem;
        color: rgba($dark, 0.5);
        padding: 0 1.5rem 0 1.5rem;
        margin-top: 0;
        margin-bottom: 0;
    }

    hr {
        width: calc(100% - 3rem);
        margin: 0.5rem auto;
        opacity: 0.1;
    }

    .nav {
        flex-direction: column;
        padding: 0.5rem 0.5rem 2.5rem 0.5rem;

        .nav-item {
            margin-top: 0.1rem;

            .nav-link {
                font-weight: 700;
                display: flex;
                align-items: center;
                font-size: 1rem;
                color: rgba($dark, 0.65);
                border-radius: 0.35rem;
                padding: 0.65rem 1rem;
                user-select: none;
                @extend .animated;

                &:hover,
                &.router-link-exact-active {
                    background: rgba($dark, 0.05);
                    color: $dark;

                    img {
                        opacity: 1;
                    }
                }

                &:active {
                    background: rgba($dark, 0.1);

                    img {
                        opacity: 1;
                    }
                }

                img {
                    width: 1.25rem;
                    height: 1.25rem;
                    flex-shrink: 0;
                    margin-top: -0.25rem;
                    margin-bottom: -0.25rem;
                    margin-right: 0.75rem;
                    opacity: 0.25;
                    @extend .animated;
                }
            }

        }
    }

    p.copyright {
        padding: 1.5rem;
        font-size: 0.8rem;
        color: rgba($dark, 0.5);
        margin: auto 0 0 0;
    }
}
</style>