<template>
	<div class="dashboard-wrapper">

        <!-- Sidebar -->
        <DashboardSidebar/>

        <!-- Content -->
		<div class="dashboard-content">
			<router-view/>
		</div>

	</div>
</template>

<script>
import DashboardSidebar from "@/components/layouts/DashboardSidebar.vue";

export default {
    name: "DashboardWrapper",
	components: {DashboardSidebar},
	data() {
        return {}
    },
}
</script>

<style lang="scss">
.dashboard-wrapper {
	display: flex;
    min-height: 100vh;
    background: $grey-100;

	@media (max-width: 767.99px) {
		min-height: 100vh;
	}

	.dashboard-content {
		flex-grow: 1;
        padding: 2.5rem;

        .dashboard-title {
            font-size: 1.25rem;
            font-weight: 700;
            color: $dark;
        }

        .dashboard-subtitle {
            font-size: 1rem;
            font-weight: 700;
            color: $dark;
        }

	}
}
</style>