<template>
    <!-- Access Account -->
    <div :class="{'investor-actions access-account': true, 'in-popup': inPopup, }">
        <h4>Access Account</h4>
        <p>Get access to this account to solve any issues this user is having.</p>
        <a :href="accessLink" target="_blank" class="btn btn-success mt-3">
            <img src="../../../assets/icons/lock.svg" alt="Access">
            <span>Access Account</span>
        </a>
    </div>
</template>

<script>
import {API_URL} from "@/repository/repository";

export default {
    name: "AccessAccountForm",
    components: {},
    props: ['userId', 'inPopup'],
    computed: {
        token() {
            return this.$store.getters.token;
        },
        accessLink() {
            return API_URL + "/users/" + this.userId + "/access?t=" + this.token;
        },
    },
}
</script>

<style lang="scss" scoped>
.investor-actions {
    margin-top: 1.5rem;
    overflow: hidden;
    display: block;
    position: relative;
    padding: 1.5rem;
    border-radius: $border-radius-large;
    box-shadow: rgba($dark, 0.05) 0 0.5rem 2rem;
    background: $white;
    text-decoration: none;
    color: inherit;

    h4 {
        margin: 0 0 0.25rem 0;
        font-size: 1rem;
        width: 100%;
        font-weight: bold;
        color: $dark;
    }

    p {
        font-size: 0.9rem;
        color: rgba($dark, 0.5);
        margin: 0;
    }

    .btn {
        display: inline-flex;
        align-items: center;
        text-transform: none;
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: $border-radius-extra-large;

        img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.25rem;
        }

        &.btn-success {
            background: $success;
            color: $white;

            img {
                filter: brightness(0) invert(1);
            }

            &:hover,
            &:focus {
                background: lighten($success, 5%);
                color: $white;
            }
        }
    }

    &.in-popup {
        padding: 1.5rem;
        border: 1px solid $grey-200;
        border-radius: $border-radius-large;
        margin: 1.5rem 0 0 0;
        background: rgba($success, 0.075);
        box-shadow: none;

        .form-group {
            max-width: 100%;
        }
    }
}
</style>