<template>
    <div class="row">
        <div class="col-md-8">
            <!-- Investors List -->
            <div class="dashboard-box">

                <!-- Filter Investors -->
                <div class="filter-investors-field mb-2">
                    <img src="../../../assets/icons/search.svg" alt="Search">
                    <input type="text" class="form-control" v-model="searchTerm" placeholder="Search by name, email or investor id..">
                </div>

                <!-- Investors List -->
                <div class="investors-list-wrapper" ref="investorsList">
                    <!-- Investors Entries -->
                    <InvestorsListItem :investor="investor" v-for="(investor, key) in filteredInvestors" :key="key"></InvestorsListItem>

                    <!-- No Entries -->
                    <p class="small py-2" v-if="loaded && investors.length === 0">No investors found in the system.</p>

                    <!-- No Entries -->
                    <p class="small py-2" v-if="loaded && investors.length > 0 && filteredInvestors.length === 0">No investors found for your search term. Please try something else.</p>

                    <!-- Loading effect -->
                    <div class="spinner-border" v-if="!loaded"></div>

                    <!-- Lead Details Popup -->
                    <ModalPopup size="large" :class="{'active': investorDetailsId !== null}">
                        <InvestorDetails :investor-id="investorDetailsId"/>
                    </ModalPopup>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4 mt-md-0">
            <!-- Search, Filters & Export -->
            <div class="dashboard-box">

                <!-- Type -->
                <div class="form-field">
                    <label for="status" class="form-label">Status</label>
                    <select class="form-control" name="status" id="status" v-model="filters.status">
                        <option value="all">All Investors</option>
                        <option value="active">Active Investors</option>
                        <option value="pending">Pending Investors</option>
                        <option value="inactive">Inactive Investors</option>
                    </select>
                    <p class="small opacity-50 mt-2 mb-0" v-if="filters.status === 'inactive'">Inactive investors are users who have successfully completed the onboarding process but do not have any funded or pending investments.</p>
                    <p class="small opacity-50 mt-2 mb-0" v-if="filters.status === 'pending'">Pending investors are users who have successfully completed the onboarding process, and have investments which are still pending.</p>
                </div>

                <!-- Period -->
                <div class="form-field mt-4">
                    <label for="period" class="form-label">Period</label>
                    <select class="form-control" name="period" id="period" v-model="filters.period">
                        <option value="all">All</option>
                        <option value="7">Last 7 days</option>
                        <option value="14">Last 14 days</option>
                        <option value="30">Last 30 days</option>
                        <option value="180">Last 6 months</option>
                        <option value="365">Last 12 months</option>
                        <option value="custom">Custom</option>
                    </select>
                </div>

                <!-- Custom Period -->
                <div class="row mt-4" v-if="filters.period === 'custom'">
                    <div class="col-6">
                        <div class="form-field">
                            <label for="start" class="form-label">Start</label>
                            <input type="date" class="form-control" name="start" id="start" v-model="filters.start">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field">
                            <label for="end" class="form-label">End</label>
                            <input type="date" class="form-control" name="end" id="end" v-model="filters.end">
                        </div>
                    </div>
                </div>

                <!-- Total Invested -->
                <div class="form-field mt-4">
                    <label for="totalInvested" class="form-label">Total Invested</label>
                    <select class="form-control" name="totalInvested" id="totalInvested" v-model="filters.totalInvested">
                        <option value="all">All</option>
                        <option value="500">More than $500</option>
                        <option value="1000">More than $1,000</option>
                        <option value="1500">More than $1,500</option>
                        <option value="2500">More than $2,500</option>
                        <option value="5000">More than $5,000</option>
                        <option value="10000">More than $10,000</option>
                    </select>
                </div>

                <!-- Export -->
                <button @click.prevent="exportInvestors" class="btn btn-primary d-block w-100 mt-4" :disabled="exporting">
                    <span class="spinner-border" v-if="exporting"></span>
                    <span :class="{'opacity-0': exporting}">Export Investors</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import ModalPopup from "@/components/widgets/ModalPopup.vue";
import InvestorsListItem from "@/components/accounts/investors/InvestorsListItem.vue";
import InvestorDetails from "@/components/accounts/investors/InvestorDetails.vue";
import {isDateInPeriod, isDateWithinLastDays} from "@/helpers";

export default {
    name: "InvestorsList",
    components: {InvestorDetails, InvestorsListItem, ModalPopup},
    data() {
        return {
            loaded: false,
            investors: [],
            end: 20,
            perPage: 20,
            investorDetailsId: null,
            searchTerm: null,
            filters: {
                status: 'all',
                period: 'all',
                start: null,
                end: null,
                totalInvested: 'all',
            },
            exporting: false,
        }
    },
    created() {
        // Disable previous listeners
        this.$root.$off('closePopups');
        this.$root.$off('openInvestorPopup');

        // Listen for any changes
        this.$root.$on('closePopups', () => {
            this.investorDetailsId = null;
            document.documentElement.style.overflow = "auto";
        });
        this.$root.$on('openInvestorPopup', (investorId) => {
            history.pushState({page: 1}, "", "/investors/" + investorId);
            this.investorDetailsId = investorId;
            document.documentElement.style.overflow = "hidden";
        });
        let app = this;
        window.addEventListener('popstate', function () {
            app.investorDetailsId = null;
            document.documentElement.style.overflow = "auto";
        });
    },
    mounted() {
        // Load Investors
        this.getInvestors();

        // Add event listener
        this.$refs.investorsList.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        // Remove event listener
        this.$refs.investorsList.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        getInvestors() {
            this.loaded = false;
            repository.get("/investors")
                .then(response => {
                    this.investors = response.data.investors;
                    this.loaded = true;
                })
                .catch(error => {
                    console.log("Error while fetching the investors", error);
                })
        },
        handleScroll() {
            const scrollOffset = this.$refs.investorsList.scrollTop + this.$refs.investorsList.clientHeight
            if (scrollOffset >= this.$refs.investorsList.scrollHeight - 20) {
                this.end += this.perPage
                if(this.end >= this.investors.length) this.end = this.investors.length;
            }
        },
        exportInvestors() {
            if (this.exporting) return;
            if (this.loaded && this.filteredInvestors.length === 0) return;
            this.exporting = true;
            repository.post("/investors/export", {
                status: this.filters.status,
                period: this.filters.period,
                start: this.filters.start,
                end: this.filters.end,
                total_invested: this.filters.totalInvested,
            })
                .then(response => {
                    const blob = new Blob([response.data], {type: 'text/csv'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = "Reiturn Investors"+ (this.filters.period !== 'all' ? (this.filters.period === 'custom' ? ' ('+ this.filters.start +' - '+ this.filters.end +')' : ' (Last '+ this.filters.period +' days)') : '') +".csv";
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.exporting = false;
                })
                .catch(error => {
                    console.log(error);
                    this.exporting = false;
                })
        },
    },
    computed: {
        filteredInvestors() {
            return this.investors.filter(investor => {
                if(!this.searchTerm || this.searchTerm.trim().length === 0) return investor;
                return investor.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || investor.email.toLowerCase().includes(this.searchTerm.toLowerCase()) || investor.id == this.searchTerm;
            }).filter(investor => {
                if(this.filters.status === 'all') return investor;
                return investor.investor_status === this.filters.status;
            }).filter(investor => {
                if(this.filters.period === 'all') {
                    return investor;
                } else if(this.filters.period === 'custom') {
                    if(this.filters.start === null || this.filters.end === null) return investor;
                    return isDateInPeriod(investor.created_at, this.filters.start, this.filters.end);
                } else {
                    return isDateWithinLastDays(investor.created_at, parseInt(this.filters.period));
                }
            }).filter(investor => {
                if(this.filters.totalInvested === 'all') return investor;
                return investor.total_invested >= this.filters.totalInvested;
            }).slice(0, this.end);
        },
    },
    watch: {
        searchTerm() {
            this.end = 20;
        },
    },
}
</script>

<style lang="scss" scoped>
.filter-investors-field {
    position: relative;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0.5rem;
        margin: auto;
        width: 1rem;
        height: 1rem;
        opacity: 0.25;
        z-index: 1;
    }

    .form-control {
        box-shadow: none;
        padding: 0.5rem 0.5rem 0.5rem 2rem;
        border: none;
        outline: none;
        color: $dark;
        position: relative;
        z-index: 0;
        @extend .animated;

        &:focus {
            background: $grey-200;
        }
    }
}

.investors-list-wrapper {
    position: relative;
    min-height: 15rem;
    max-height: 40rem;
    overflow-x: hidden;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    width: calc(100% + 3rem);
    margin: -0.5rem -1.5rem -1.5rem -1.5rem;

    .spinner-border {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.15;
    }
}
</style>