<template>
    <div :class="{'admin-box h-100': true, 'loading': loading}">
        <div class="admin-avatar">
            <img :src="admin.avatar" :alt="admin.name">
        </div>
        <div class="admin-details">
            <h3>{{ admin.name }}</h3>
            <span>{{ admin.email }}</span>
        </div>
        <div class="admin-actions">
            <button class="btn btn-primary" @click.prevent="$emit('details', admin)" :disabled="admin.type === 1">Update</button>
            <button class="btn btn-light ms-md-2" @click.prevent="deleteAdmin" :disabled="loading || admin.type === 1">
                <span :class="{'opacity-0': loading}">Delete</span>
                <span class="spinner-border" v-if="loading"></span>
            </button>
            <p class="small opacity-50 mt-3 mb-0" v-if="admin.type === 1">You cannot delete or update administrators with full access</p>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "AdminBox",
    props: ['admin',],
    emits: ['details', 'deleted'],
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        deleteAdmin() {
            if(!confirm("Are you sure? This will permanently remove the administrator " + this.admin.name + " from your system.")) return;
            this.loading = true;
            repository.delete("/admins/" + this.admin.id)
                .then(() => {
                    this.loading = false;
                    this.$emit("deleted", this.admin.id);
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.response.data.message);
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.admin-box {
    display: block;
    background: $white;
    padding: 1.5rem;
    border-radius: $border-radius-large;
    box-shadow: rgba($dark, 0.1) 0 0.25rem 0.5rem;

    &.loading {
        filter: grayscale(1);
        opacity: 0.9;
        pointer-events: none;
        user-select: none;
    }

    .admin-avatar {
        img {
            width: 5rem;
            height: 5rem;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .admin-details {
        margin: 1rem 0 0 0;

        h3 {
            font-weight: bold;
            color: $dark;
            font-size: 1.25rem;
            margin: 0;
        }

        span {
            display: block;
            color: rgba($dark, 0.5);
        }
    }

    .admin-actions {
        margin-top: 1.5rem;

        .btn {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
</style>