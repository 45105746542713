<template>
    <div class="admins-screen">

        <!-- Welcome Message -->
        <div class="row align-items-center">
            <div class="col-md-8">
                <h1 class="dashboard-title mb-3">Administrators Management</h1>
                <p>Provide admin access only on team members that are related to Reiturn.</p>
            </div>
            <div class="col-md-4 text-md-end">
                <a href="#" class="btn btn-primary" @click.prevent="openNewAdminPopup">New Admin</a>
            </div>
        </div>

        <!-- Admins -->
        <div class="row">
            <div class="col-md-12" v-if="!loaded">
                <div class="spinner-border my-4 opacity-25"></div>
            </div>
            <div class="col-md-12" v-if="loaded && error">
                <div class="text-danger fw-bolder mt-4">{{ error }}</div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-4 col-xxl-4 mt-4" v-for="(admin, key) in admins" :key="key">
                <AdminBox
                    :admin="admin"
                    @deleted="adminDeleted"
                    @details="viewAdminDetails"
                >
                </AdminBox>
            </div>
        </div>

        <!-- Admin Details Popup -->
        <ModalPopup size="large" :class="{'active': currentAdmin !== null}">
            <AdminDetails :admin="currentAdmin" v-if="currentAdmin" @reload-admins="loadAdmins"/>
        </ModalPopup>

        <!-- New Admin Popup -->
        <ModalPopup size="large" :class="{'active': newAdminPopup}">
            <NewAdminForm v-if="newAdminPopup" @reload-admins="loadAdmins"/>
        </ModalPopup>

    </div>
</template>

<script>
import repository from "@/repository/repository";
import ModalPopup from "@/components/widgets/ModalPopup.vue";
import NewAdminForm from "@/components/admins/NewAdminForm.vue";
import AdminBox from "@/components/admins/AdminBox.vue";
import AdminDetails from "@/components/admins/AdminDetails.vue";

export default {
    name: "AdminsScreen",
    components: {AdminDetails, AdminBox, NewAdminForm, ModalPopup},
    data() {
        return {
            admins: [],
            loaded: false,
            error: false,
            currentAdmin: null,
            newAdminPopup: false,
        }
    },
    created() {
        this.loadAdmins();

        // Listen for modal closings
        let app = this;
        window.addEventListener('popstate', function () {
            app.currentAdmin = null;
            app.newAdminPopup = null;
            document.documentElement.style.overflow = "auto";
        });
    },
    methods: {
        loadAdmins() {
            this.loaded = false;
            this.error = false;
            repository.get("/admins")
                .then(response => {
                    this.admins = response.data.admins;
                    this.loaded = true;
                })
                .catch(error => {
                    this.loaded = true;
                    this.error = error.response.data.message;
                })
        },
        openNewAdminPopup() {
            this.newAdminPopup = true;
            history.pushState({page: 1}, "", "/admins/new");
        },
        adminDeleted(id) {
            let indexToDelete = this.admins.findIndex(admin => admin.id === id);
            if(indexToDelete !== -1) {
                this.admins.splice(indexToDelete, 1);
            }
        },
        viewAdminDetails(admin) {
            this.currentAdmin = admin;
            history.pushState({page: 1}, "", "/admins/" + admin.id);
            document.documentElement.style.overflow = "hidden";
        },
    },
}
</script>

<style lang="scss" scoped>

</style>