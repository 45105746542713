<template>
    <div class="row">
        <div class="col-md-8">
            <!-- Leads List -->
            <div class="dashboard-box">

                <!-- Filter Leads -->
                <div class="filter-leads-field mb-2">
                    <img src="../../../assets/icons/search.svg" alt="Search">
                    <input type="text" class="form-control" v-model="searchTerm" placeholder="Search by name, email or investor id..">
                </div>

                <!-- Leads List -->
                <div class="leads-list-wrapper" ref="leadsList">
                    <!-- Leads Entries -->
                    <LeadsListItem :lead="lead" v-for="(lead, key) in filteredLeads" :key="key"></LeadsListItem>

                    <!-- No Entries -->
                    <p class="small py-2" v-if="loaded && leads.length === 0">No leads found in the system.</p>

                    <!-- No Entries -->
                    <p class="small py-2" v-if="loaded && leads.length > 0 && filteredLeads.length === 0">No leads found for your search term. Please try something else.</p>

                    <!-- Loading effect -->
                    <div class="spinner-border" v-if="!loaded"></div>

                    <!-- Lead Details Popup -->
                    <ModalPopup size="large" :class="{'active': leadDetailsId !== null}">
                        <LeadDetails :lead-id="leadDetailsId"/>
                    </ModalPopup>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4 mt-md-0">
            <!-- Search, Filters & Export -->
            <div class="dashboard-box">

                <!-- Onboarding Step -->
                <div class="form-field">
                    <label for="step" class="form-label">Onboarding Step</label>
                    <select class="form-control" name="step" id="step" v-model="filters.step">
                        <option value="all">All Steps</option>
                        <option value="personal-details">Personal Details</option>
                        <option value="investment">Investment</option>
                        <option value="bank-account">Bank Account</option>
                        <option value="agreements">Agreements</option>
                        <option value="verification">Verification</option>
                    </select>
                </div>

                <!-- Period -->
                <div class="form-field mt-4">
                    <label for="period" class="form-label">Period</label>
                    <select class="form-control" name="period" id="period" v-model="filters.period">
                        <option value="all">All</option>
                        <option value="7">Last 7 days</option>
                        <option value="14">Last 14 days</option>
                        <option value="30">Last 30 days</option>
                        <option value="180">Last 6 months</option>
                        <option value="365">Last 12 months</option>
                        <option value="custom">Custom</option>
                    </select>
                </div>

                <!-- Custom Period -->
                <div class="row mt-4" v-if="filters.period === 'custom'">
                    <div class="col-6">
                        <div class="form-field">
                            <label for="start" class="form-label">Start</label>
                            <input type="date" class="form-control" name="start" id="start" v-model="filters.start">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field">
                            <label for="end" class="form-label">End</label>
                            <input type="date" class="form-control" name="end" id="end" v-model="filters.end">
                        </div>
                    </div>
                </div>

                <!-- Export -->
                <button @click.prevent="exportLeads" class="btn btn-primary d-block w-100 mt-4" :disabled="exporting">
                    <span class="spinner-border" v-if="exporting"></span>
                    <span :class="{'opacity-0': exporting}">Export Leads</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LeadsListItem from "@/components/accounts/leads/LeadsListItem.vue";
import repository from "@/repository/repository";
import ModalPopup from "@/components/widgets/ModalPopup.vue";
import LeadDetails from "@/components/accounts/leads/LeadDetails.vue";
import {isDateInPeriod, isDateWithinLastDays} from "@/helpers";

export default {
    name: "LeadsList",
    components: {LeadDetails, ModalPopup, LeadsListItem},
    data() {
        return {
            loaded: false,
            leads: [],
            end: 20,
            perPage: 20,
            leadDetailsId: null,
            searchTerm: null,
            filters: {
                step: 'all',
                period: 'all',
                start: null,
                end: null,
            },
            exporting: false,
        }
    },
    created() {
        // Disable previous listeners
        this.$root.$off('closePopups');
        this.$root.$off('openLeadPopup');
        this.$root.$off('leadDeleted');

        // Listen for any changes
        this.$root.$on('closePopups', () => {
            this.leadDetailsId = null;
            document.documentElement.style.overflow = "auto";
        });
        this.$root.$on('openLeadPopup', (leadId) => {
            history.pushState({page: 1}, "", "/leads/" + leadId);
            this.leadDetailsId = leadId;
            document.documentElement.style.overflow = "hidden";
        });
        this.$root.$on('leadDeleted', (leadId) => {
            this.leadDetailsId = null;
            let leadIndexToDelete = this.leads.findIndex(item => item.id === leadId);
            if(leadIndexToDelete !== -1) {
                this.leads.splice(leadIndexToDelete, 1);
            }
        });
        let app = this;
        window.addEventListener('popstate', function () {
            app.leadDetailsId = null;
            document.documentElement.style.overflow = "auto";
        });
    },
    mounted() {
        // Load Leads
        this.getLeads();

        // Add event listener
        this.$refs.leadsList.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        // Remove event listener
        this.$refs.leadsList.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        getLeads() {
            this.loaded = false;
            repository.get("/leads")
                .then(response => {
                    this.leads = response.data.leads;
                    this.loaded = true;
                })
                .catch(error => {
                    console.log("Error while fetching the Leads", error);
                })
        },
        handleScroll() {
            const scrollOffset = this.$refs.leadsList.scrollTop + this.$refs.leadsList.clientHeight
            if (scrollOffset >= this.$refs.leadsList.scrollHeight - 20) {
                this.end += this.perPage
                if(this.end >= this.leads.length) this.end = this.leads.length;
            }
        },
        exportLeads() {
            if (this.exporting) return;
            if (this.loaded && this.filteredLeads.length === 0) return;
            this.exporting = true;
            repository.post("/leads/export", {
                step: this.filters.step,
                period: this.filters.period,
                start: this.filters.start,
                end: this.filters.end,
            })
                .then(response => {
                    const blob = new Blob([response.data], {type: 'text/csv'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = "Reiturn Leads"+ (this.filters.period !== 'all' ? (this.filters.period === 'custom' ? ' ('+ this.filters.start +' - '+ this.filters.end +')' : ' (Last '+ this.filters.period +' days)') : '') +".csv";
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.exporting = false;
                })
                .catch(error => {
                    console.log(error);
                    this.exporting = false;
                })
        },
    },
    computed: {
        filteredLeads() {
            return this.leads.filter(lead => {
                if(!this.searchTerm || this.searchTerm.trim().length === 0) return lead;
                return lead.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || lead.email.toLowerCase().includes(this.searchTerm.toLowerCase()) || lead.id == this.searchTerm;
            }).filter(lead => {
                if(this.filters.step === 'all') return lead;
                return lead.onboarding_step === this.filters.step;
            }).filter(lead => {
                if(this.filters.period === 'all') {
                    return lead;
                } else if(this.filters.period === 'custom') {
                    if(this.filters.start === null || this.filters.end === null) return lead;
                    return isDateInPeriod(lead.created_at, this.filters.start, this.filters.end);
                } else {
                    return isDateWithinLastDays(lead.created_at, parseInt(this.filters.period));
                }
            }).slice(0, this.end);
        },
    },
    watch: {
        searchTerm() {
            this.end = 20;
        },
    },
}
</script>

<style lang="scss" scoped>
.filter-leads-field {
    position: relative;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0.5rem;
        margin: auto;
        width: 1rem;
        height: 1rem;
        opacity: 0.25;
        z-index: 1;
    }

    .form-control {
        box-shadow: none;
        padding: 0.5rem 0.5rem 0.5rem 2rem;
        border: none;
        outline: none;
        color: $dark;
        position: relative;
        z-index: 0;
        @extend .animated;

        &:focus {
            background: $grey-200;
        }
    }
}

.leads-list-wrapper {
    position: relative;
    min-height: 15rem;
    max-height: 40rem;
    overflow-x: hidden;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    width: calc(100% + 3rem);
    margin: -0.5rem -1.5rem -1.5rem -1.5rem;

    .spinner-border {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.15;
    }
}
</style>