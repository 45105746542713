<template>
    <div class="dashboard-box investments-box">
        <h2 class="dashboard-subtitle">States Detailed Summary</h2>
        <div class="states-summary-table-wrapper">
            <div class="spinner-border" v-if="loaded < 3"></div>
            <table class="table table-hover" v-if="loaded === 3">
                <thead>
                <tr>
                    <th>State</th>
                    <th>Leads</th>
                    <th>Investors</th>
                    <th>Conversion Rate</th>
                    <th>Total Invested</th>
                    <th>Investor Average</th>
                    <th>User Average</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(state, key) in preparedStates" :key="key">
                    <td>{{ state.name }}</td>
                    <td class="leads">{{ state.leads }}</td>
                    <td class="investors">{{ state.investors }}</td>
                    <td :class="{
                        'conversion-rate': true,
                        'text-danger fw-bold': calculateInvestorsPercentage(state.leads, state.investors) == 0,
                        'text-warning fw-bold': calculateInvestorsPercentage(state.leads, state.investors) > 0 && calculateInvestorsPercentage(state.leads, state.investors) < 10,
                        'text-success fw-bold': calculateInvestorsPercentage(state.leads, state.investors) >= 10
                    }">{{ calculateInvestorsPercentage(state.leads, state.investors) }}%</td>
                    <td :class="{'invested': true, 'fw-bolder': state.invested > 0}">${{ state.invested.toLocaleString() }}</td>
                    <td class="average-invested">${{ calculateAverageInvestedPerInvestor(state) }}</td>
                    <td class="average-invested">${{ calculateAverageInvestedPerUser(state) }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import repository from "@/repository/repository";
import {states} from "@/helpers";

export default {
    name: "StatesSummaryTable",
    data() {
        return {
            investments: [],
            investors: [],
            leads: [],
            loaded: 0,
            error: false,
        }
    },
    created() {
        this.loadInvestments();
        this.loadInvestors();
        this.loadLeads();
    },
    methods: {
        loadInvestments() {
            this.error = false;
            repository.get("/advanced-stats/investments")
                .then(response => {
                    this.investments = response.data.states;
                    this.loaded++;
                })
        },
        loadInvestors() {
            this.error = false;
            repository.get("/advanced-stats/maps/investors")
                .then(response => {
                    this.investors = response.data.states;
                    this.loaded++;
                })
        },
        loadLeads() {
            this.error = false;
            repository.get("/advanced-stats/maps/leads")
                .then(response => {
                    this.leads = response.data.states;
                    this.loaded++;
                })
        },
        calculateInvestorsPercentage(leads, investors) {
            if((leads + investors) === 0) return 0;
            return ((100 / (leads + investors)) * investors).toFixed(0);
        },
        calculateAverageInvestedPerInvestor(state) {
            if(state.invested === 0) return 0;
            return (state.invested / (state.investors)).toLocaleString(undefined, {maximumFractionDigits: 0});
        },
        calculateAverageInvestedPerUser(state) {
            if(state.invested === 0) return 0;
            return (state.invested / (state.leads + state.investors)).toLocaleString(undefined, {maximumFractionDigits: 0});
        },
    },
    computed: {
        preparedStates() {
            if(!this.loaded) return [];
            let results = [];
            console.log(states, Object.keys(states), this.investments);
            Object.keys(states).forEach(shortcode => {
                results.push({
                    name: states[shortcode],
                    investors: this.investors[shortcode] ?? 0,
                    leads: this.leads[shortcode] ?? 0,
                    invested: this.investments[shortcode] ?? 0,
                })
            })
            return results;
        }
    },
}
</script>

<style lang="scss" scoped>
.states-summary-table-wrapper {
    min-height: 300px;
    display: block;
    width: 100%;

    .spinner-border {
        margin: 2.5rem auto;
        opacity: 0.25;
        display: block;
    }

    .table {
        width: calc(100% + 1.5rem);
        margin: 0 -0.75rem;

        thead {
            tr {
                th {
                    border: none;
                    font-size: 0.8rem;
                    padding: 0.5rem 0;
                    color: rgba($dark, 0.5);

                    &:first-child {
                        padding-left: 0.75rem;
                        border-radius: $border-radius-medium 0 0 $border-radius-medium;
                    }

                    &:last-child {
                        padding-left: 0.75rem;
                        border-radius: 0 $border-radius-medium $border-radius-medium 0;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    border: none;
                    padding: 0.5rem 0;
                    color: $dark;
                    @extend .animated;

                    &:first-child {
                        padding-left: 0.75rem;
                        border-radius: $border-radius-medium 0 0 $border-radius-medium;
                    }

                    &:last-child {
                        padding-left: 0.75rem;
                        border-radius: 0 $border-radius-medium $border-radius-medium 0;
                    }
                }

                &:hover {
                    td {
                        background: rgba($dark, 0.05);
                    }
                }

                &:nth-child(2n) {
                    td {
                        background: rgba($dark, 0.025);
                    }
                }
            }
        }
    }

}
</style>