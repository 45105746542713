import { render, staticRenderFns } from "./InvestmentsList.vue?vue&type=template&id=b7164c0c&scoped=true&"
import script from "./InvestmentsList.vue?vue&type=script&lang=js&"
export * from "./InvestmentsList.vue?vue&type=script&lang=js&"
import style0 from "./InvestmentsList.vue?vue&type=style&index=0&id=b7164c0c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7164c0c",
  null
  
)

export default component.exports