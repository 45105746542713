<template>
    <LeadDetails></LeadDetails>
</template>

<script>
import LeadDetails from "@/components/accounts/leads/LeadDetails.vue";

export default {
    name: "LeadDetailsPage",
    components: {LeadDetails},
}
</script>