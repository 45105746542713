<template>
    <div :class="{'dashboard-box fund-summary-box h-100': true, 'loading': !loaded}">
        <h2 class="dashboard-subtitle">Fund Summary</h2>

        <!-- Funded Investments -->
        <div class="summary-main-entry">
            <VueCountTo class="summary-number" :autoplay="false" :startVal="0" :endVal="total_funded" :duration="1000" ref="totalFunded" prefix="$"></VueCountTo>
            <div class="summary-title">Total Funded Investments</div>
        </div>

        <!-- Pending Investments -->
        <div class="summary-entry">
            <div class="summary-title">Pending Investments</div>
            <VueCountTo class="summary-number" :autoplay="false" :startVal="0" :endVal="total_pending" :duration="1000" ref="totalPending" prefix="$"></VueCountTo>
            <ExplanationIcon title="The payments for these investments have been initiated, but the funds still haven't arrived."></ExplanationIcon>
        </div>

        <!-- Incomplete Investments -->
        <div class="summary-entry">
            <div class="summary-title">Incomplete Investments</div>
            <VueCountTo class="summary-number opacity-25" :autoplay="false" :startVal="0" :endVal="total_incomplete" :duration="1000" ref="totalIncomplete" prefix="$"></VueCountTo>
            <ExplanationIcon title="These investments have never been initiated. Most likely the investors didn't complete their onboarding process."></ExplanationIcon>
        </div>

        <!-- Cancelled Investments -->
        <div class="summary-entry">
            <div class="summary-title">Cancelled Investments</div>
            <VueCountTo class="summary-number text-danger" :autoplay="false" :startVal="0" :endVal="total_cancelled" :duration="1000" ref="totalCancelled" prefix="$"></VueCountTo>
            <ExplanationIcon title="These investments have been cancelled."></ExplanationIcon>
        </div>

        <!-- View All Investments -->
        <router-link to="/investments" class="btn btn-light mt-5 d-block w-100">View All Investments</router-link>

    </div>
</template>

<script>
import VueCountTo from 'vue-count-to';
import repository from "@/repository/repository";
import ExplanationIcon from "@/components/widgets/ExplanationIcon.vue";

export default {
    name: "DashboardFundSummary",
    components: {ExplanationIcon, VueCountTo },
    data: function () {
        return {
            loaded: false,
            total_funded: 0,
            total_pending: 0,
            total_cancelled: 0,
            total_incomplete: 0,
        }
    },
    created() {
        this.getSummary();
    },
    methods: {
        getSummary() {
            this.loaded = false;
            repository.get("/dashboard/stats/fund-summary")
                .then(response => {
                    this.total_funded = response.data.total_funded;
                    this.total_pending = response.data.total_pending;
                    this.total_cancelled = response.data.total_cancelled;
                    this.total_incomplete = response.data.total_incomplete;
                    this.loaded = true;
                    this.$refs.totalFunded.start();
                    this.$refs.totalPending.start();
                    this.$refs.totalIncomplete.start();
                    this.$refs.totalCancelled.start();
                })
                .catch(error => {
                    console.log("Error while fetching Fund Summary Data", error);
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.fund-summary-box {

    // Main Entry
    .summary-main-entry {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $grey-200;
        @extend .animated;

        .summary-number {
            font-size: 2rem;
            font-weight: bold;
            color: $primary;
        }

        .summary-title {
            display: block;
            color: rgba($dark, 0.5);
            font-weight: 600;
            font-size: 0.8rem;

            &.fw-bolder {
                color: $dark;
            }
        }
    }

    // Basic Entry
    .summary-entry {
        display: flex;
        align-items: center;
        @extend .animated;

        & + .summary-entry {
            margin-top: 0.5rem;
        }

        .summary-title {
            display: block;
            color: rgba($dark, 0.5);
            font-weight: 600;
            font-size: 0.8rem;
        }

        .summary-number {
            color: $dark;
            font-weight: 600;
            margin-left: auto;
            margin-right: 0.5rem;
        }
    }

    // Loading
    &.loading {
        @extend .disable-clicks;
        @extend .disable-selection;

        .summary-main-entry {
            border-radius: $border-radius-large;
            border-bottom-color: transparent;
            background: rgba($dark, 0.1);

            .summary-title, .summary-number {
                color: transparent;
            }
        }

        .summary-entry {
            border-radius: $border-radius-medium;
            border-bottom-color: transparent;
            background: rgba($dark, 0.05);

            * {
                opacity: 0;
            }

            .summary-title, .summary-number {
                color: transparent;
            }
        }
    }
}
</style>