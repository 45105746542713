<template>
    <div class="stats-screen">

        <!-- Onboarding Statistics -->
        <h1 class="dashboard-title">Advanced Statistics</h1>

        <!-- Average Investments / Conversion Rate -->
        <AdvancedStatistics></AdvancedStatistics>

        <!-- Onboarding Progress -->
        <OnboardingProgressStats></OnboardingProgressStats>

        <div class="row">
            <div class="col-md-4 mb-4">
                <!-- Total Invested Per State -->
                <InvestmentsPerState></InvestmentsPerState>
            </div>
            <div class="col-md-8 mb-4">
                <!-- Investors Per State -->
                <InvestorsMap></InvestorsMap>
            </div>
        </div>

        <!-- Leads Summary -->
        <StatesSummaryTable></StatesSummaryTable>

    </div>
</template>

<script>
import InvestorsMap from "@/components/statistics/InvestorsMap.vue";
import InvestmentsPerState from "@/components/statistics/InvestmentsPerState.vue";
import OnboardingProgressStats from "@/components/statistics/OnboardingProgressStats.vue";
import StatesSummaryTable from "@/components/statistics/StatesSummaryTable.vue";
import AdvancedStatistics from "@/components/statistics/AdvancedStatistics.vue";

export default {
    name: "StatsScreen",
    components: {AdvancedStatistics, StatesSummaryTable, OnboardingProgressStats, InvestmentsPerState, InvestorsMap }
}
</script>

<style lang="scss" scoped>

</style>