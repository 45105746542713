<template>
    <div class="dashboard-box users-box mb-4">
        <div class="row">
            <div class="col-md-6">
                <div class="dashboard-subtitle" v-if="type === 'pending'">
                    Pending Review <ExplanationIcon class="ms-1" title="These users need manual verification of their details. Please review their documents, and approve or disapprove accordingly."></ExplanationIcon>
                </div>
                <div class="dashboard-subtitle" v-if="type === 'disapproved'">
                    Disapproved Accounts <ExplanationIcon class="ms-1" title="These users have been disapproved automatically or manually by your team, and they haven't uploaded any new documents for review."></ExplanationIcon>
                </div>
            </div>
            <div class="col-md-6">
                <!-- Filter Users -->
                <div class="filter-users-field">
                    <img src="../../assets/icons/search.svg" alt="Search">
                    <input type="text" class="form-control" v-model="searchTerm" placeholder="Search by name, email or investor id..">
                </div>
            </div>
        </div>
        <div class="dashboard-users-wrapper">
            <div class="spinner-border my-4 mx-auto d-block opacity-25" v-if="!loaded"></div>
            <p v-if="loaded && items.length === 0 && type === 'pending'" class="px-2 mb-0">All pending users have been verified & updated.</p>
            <p v-if="loaded && items.length > 0 && users.length === 0" class="px-2 mb-0">No results were found for your search term.</p>
            <div class="user-entry" v-for="(user, key) in users" :key="key">
                <div :class="getAvatar(user)">{{ user.credentials }}</div>
                <div class="user-info">
                    <strong>{{ user.name }}</strong>
                    <span>{{ user.email }}</span>
                </div>
                <div :class="{'user-type': true, 'user-investor': user.verified }">{{ user.verified ? 'Investor' : 'Lead' }}</div>
                <div class="user-actions">
                    <button :class="{'btn': true, 'btn-primary': type === 'pending', 'btn-outline-dark': type === 'disapproved'}" @click.prevent="selectUser(user)">
                        <span v-if="type === 'pending'">Verify Details</span>
                        <span v-if="type === 'disapproved'">View Details</span>
                    </button>
                </div>
            </div>
        </div>

        <!-- User Details Popup -->
        <ModalPopup size="large" :class="{'active': selectedUser !== null}">
            <VerificationUserDetails :user="selectedUser" v-if="selectedUser"></VerificationUserDetails>
        </ModalPopup>
    </div>
</template>

<script>
import ExplanationIcon from "@/components/widgets/ExplanationIcon.vue";
import ModalPopup from "@/components/widgets/ModalPopup.vue";
import {generateAvatarClass} from "@/helpers";
import VerificationUserDetails from "@/components/verifications/VerificationUserDetails.vue";

export default {
    name: "VerificationList",
    components: {VerificationUserDetails, ModalPopup, ExplanationIcon },
    props: ['items', 'loaded', 'type'],
    data: function () {
        return {
            searchTerm: "",
            selectedUser: null,
        }
    },
    created() {
        let app = this;
        window.addEventListener('popstate', function () {
            app.selectedUser = null;
            document.documentElement.style.overflow = "auto";
        });
    },
    methods: {
        getAvatar(user) {
            return "user-credentials " + generateAvatarClass(user);
        },
        selectUser(user) {
            console.log(user);
            this.selectedUser = user;
            history.pushState({page: 1}, "", "/verifications/" + user.id);
            document.documentElement.style.overflow = "hidden";
        },
    },
    computed: {
        users() {
            return this.items.filter(user => {
                return user.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || user.email.toLowerCase().includes(this.searchTerm.toLowerCase()) || user.id == this.searchTerm;
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.dashboard-box.users-box {

    .filter-users-field {
        position: relative;

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0.5rem;
            margin: auto;
            width: 1rem;
            height: 1rem;
            opacity: 0.25;
            z-index: 1;
        }

        .form-control {
            box-shadow: none;
            padding: 0.5rem 0.5rem 0.5rem 2rem;
            border: none;
            outline: none;
            color: $dark;
            position: relative;
            z-index: 0;
            @extend .animated;

            &:focus {
                background: $grey-200;
            }
        }
    }

    .dashboard-users-wrapper {
        display: block;
        width: calc(100% + 3rem);
        margin: 0 -1.5rem -1.5rem -1.5rem;
        padding: 0 1rem 1rem 1rem;
        max-height: 20rem;
        overflow-x: hidden;

        .user-entry {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            border-radius: $border-radius-large;
            @extend .animated;

            &:hover {
                background: $grey-100;

                .user-actions {
                    opacity: 1;
                }
            }

            .user-credentials {
                width: 2.5rem;
                height: 2.5rem;
                text-align: center;
                border-radius: 50%;
                line-height: 2.5rem;
                font-size: 0.8rem;
                font-weight: bold;
                flex-shrink: 0;
                @extend .disable-selection;
            }

            .user-info {
                padding: 0 1rem;
                width: 50%;
                flex-shrink: 0;

                strong {
                    display: block;
                    font-size: 0.9rem;
                }

                span {
                    display: block;
                    color: rgba($dark, 0.75);
                    font-size: 0.8rem;
                }
            }

            .user-type {
                font-size: 0.8rem;
                padding: 0.15rem 0.75rem;
                border-radius: $border-radius-large;
                background: rgba($dark, 0.1);
                color: rgba($dark, 0.75);
                font-weight: 600;

                &.user-investor {
                    background: $primary;
                    color: $white;
                }
            }

            .user-actions {
                margin-left: auto;
                opacity: 0;
                @extend .animated;

                .btn {
                    text-transform: none;
                    padding: 0.5rem 1rem;
                    margin-left: 0.5rem;
                }
            }
        }
    }
}
</style>