<template>
    <!-- Refunds -->
    <div class="investor-contact-details investments-box">
        <h4>Returned Investments</h4>
        <p>These investments have been returned to the investor</p>
        <div class="investments-wrapper">
            <div class="investment-summary-item investment-cancelled">
                <strong class="text-danger">- ${{ totalRefunded.toLocaleString() }}</strong>
                <span>Total Refunded</span>
            </div>
        </div>

        <p class="mt-4 pt-3 border-top">The investor has received the following refunds</p>
        <div class="investor-transaction" v-for="(refund, key) in refunds" :key="key">
            <span class="transaction-date">{{ refund.date.month }} {{ refund.date.day }}, {{ refund.date.year }}</span>
            <span class="transaction-amount">- ${{ refund.amount.toLocaleString() }}</span>
            <span class="transaction-status">
                <span class="badge bg-danger">Refunded</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "InvestorRefundsSummary",
    components: {},
    props: ['refunds', 'inPopup', ],
    computed: {
        totalRefunded() {
            return this.refunds.reduce((total, refund) => total + refund.amount, 0);
        },
    },
}
</script>

<style lang="scss" scoped>
.investments-box {
    padding: 1.5rem;
    border-radius: $border-radius-large;
    background: $white;
    box-shadow: rgba($dark, 0.1) 0 0.25rem 0.5rem;

    h4 {
        margin: 0 0 0.25rem 0;
        font-size: 1rem;
        width: 100%;
        font-weight: bold;
        color: $dark;
    }

    p {
        font-size: 0.9rem;
        color: rgba($dark, 0.5);
        margin: 0 0 0.5rem 0;
    }

    .investor-transaction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;

        & + .investor-transaction {
            border-top: 1px solid rgba($dark, 0.1);
        }

        .transaction-amount {
            font-weight: bold;
            color: $danger;
            flex-grow: 0;
        }

        .transaction-date {
            color: $dark;
            font-weight: bold;
            font-size: 1rem;
        }

        .transaction-status {
            text-align: right;
            flex-shrink: 0;
            flex-grow: 0;

            .badge {
                border-radius: $border-radius-large;

                &.bg-success {
                    background: $success !important;
                }

                &.bg-danger {
                    background: $danger !important;
                }

                &.bg-info {
                    background: rgba($dark, 0.075) !important;
                    color: rgba($dark, 0.35);
                }
            }
        }
    }

    .investments-wrapper {
        display: flex;

        .investment-summary-item {
            display: block;
            width: calc(100% / 3);
            flex-shrink: 0;
            flex-grow: 0;

            strong {
                display: block;
                font-weight: bold;
                font-size: 1.5rem;
            }

            span {
                display: block;
                color: rgba($dark, 0.5);
                font-size: 0.8rem;
                line-height: 1.2;
            }

            &.investment-completed {
                strong {
                    color: $success;
                }
            }

            &.investment-pending {
                strong {
                    color: rgba($dark, 1);
                }
            }

            &.investment-cancelled {
                strong {
                    color: rgba($dark, 1);
                }
            }
        }
    }
}
</style>