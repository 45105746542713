import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import DashboardWrapper from "@/components/layouts/DashboardWrapper.vue";
import DashboardScreen from "@/views/DashboardScreen.vue";
import LeadsScreen from "@/views/LeadsScreen.vue";
import InvestorsScreen from "@/views/InvestorsScreen.vue";
import InvestmentsScreen from "@/views/InvestmentsScreen.vue";
import StatsScreen from "@/views/StatsScreen.vue";
import PropertiesScreen from "@/views/PropertiesScreen.vue";
import TrainingScreen from "@/views/TrainingScreen.vue";
import DistributionsScreen from "@/views/DistributionsScreen.vue";
import LoginScreen from "@/views/LoginScreen.vue";
import LeadDetailsPage from "@/views/LeadDetailsPage.vue";
import InvestorDetailsPage from "@/views/InvestorDetailsPage.vue";
import ImpactScreen from "@/views/ImpactScreen.vue";
import VerificationsScreen from "@/views/VerificationsScreen.vue";
import AdminsScreen from "@/views/AdminsScreen.vue";
import RefundsScreen from "@/views/RefundsScreen.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: DashboardWrapper,
        children: [
            {
                path: '/',
                component: DashboardScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/leads',
                component: LeadsScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/leads/:id',
                component: LeadDetailsPage,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/investors',
                component: InvestorsScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/investors/:id',
                component: InvestorDetailsPage,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/verifications',
                component: VerificationsScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/verifications/*',
                redirect: {
                    path: '/verifications',
                },
            },
            {
                path: '/investments',
                component: InvestmentsScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/investments/*',
                redirect: {
                    path: '/investments',
                },
            },
            {
                path: '/refunds',
                component: RefundsScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/distributions',
                component: DistributionsScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/distributions/*',
                redirect: {
                    path: '/distributions',
                },
            },
            {
                path: '/stats',
                component: StatsScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/properties',
                component: PropertiesScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/properties/*',
                redirect: {
                    path: '/properties',
                },
            },
            {
                path: '/impact',
                component: ImpactScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/impact/*',
                redirect: {
                    path: '/impact',
                },
            },
            {
                path: '/training',
                component: TrainingScreen,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/admins',
                component: AdminsScreen,
                meta: {
                    requireAuth: true,
                    requireFullAccess: true,
                    requireGuest: false,
                },
            },
            {
                path: '/admins/*',
                redirect: {
                    path: '/admins',
                },
            },
        ],
    },
    {
        path: '/login',
        component: LoginScreen,
        meta: {
            requireAuth: false,
            requireGuest: true,
        },
        props: (route) => ({ redirect: route.query.redirect }),
    },
    {
        path: '/logout',
        component: function () {
            store.commit("logoutUser");
            router.replace("/login");
        },
        meta: {
            requireAuth: true,
            requireGuest: false,
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router;

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (store.getters.token == null) {
            // If no user is signed in, simply redirect to /login
            next({
                path: "/login",
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            // Continue if ok
            next();
        }
    } else if (to.matched.some(record => record.meta.requireGuest)) {
        if (store.getters.token != null) {
            // If there is a user, simply redirect to home
            next({
                path: "/"
            });
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.requireFullAccess)) {
        if (store.getters.user.type === 0) {
            // If user does not have full access, redirect to home
            next({
                path: "/"
            });
        } else {
            next();
        }
    } else {
        // Simply continue
        next();
    }
});