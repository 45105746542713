import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem("admin_token"),
        user: {
            name: null,
            email: null,
            credentials: null,
            avatar: null,
            type: 0,
            loaded: false,
        },
    },
    getters: {
        token: state => state.token,
        user: state => state.user,
    },
    mutations: {
        updateAuth(state, data) {
            state.token = data.token;
            localStorage.setItem("admin_token", data.token);
        },
        updateUser(state, data) {
            state.user.name = data.name;
            state.user.credentials = data.credentials;
            state.user.email = data.email;
            state.user.avatar = data.avatar;
            state.user.type = data.type;
            state.user.loaded = true;
        },
        logoutUser(state) {
            state.token = null;
            localStorage.removeItem("admin_token");
        },
    },
    actions: {
    },
    modules: {
    }
})
