<template>
    <div class="impact-screen">

        <!-- Impact Management -->
        <h1 class="dashboard-title">Impact Management</h1>

        <!-- Impact Box -->
        <div class="dashboard-box mt-3">

            <!-- Title & New Button -->
            <div class="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-4">
                <div>
                    <p>The impact of each investor's investment will be automatically calculated based on the listed items below.</p>
                </div>
                <a href="#" class="btn btn-primary" @click.prevent="openNewImpactPopup">New Impact Item</a>
            </div>

            <!-- Items List -->
            <div class="impact-list-wrapper">
                <div class="spinner-border" v-if="!loaded"></div>
                <div class="impact-list" v-if="loaded">
                    <ImpactItem
                        v-for="(impact, key) in items"
                        :key="key"
                        :impact="impact"
                        @deleted="impactDeleted"
                        @details="viewImpactDetails">
                    </ImpactItem>
                </div>
            </div>
        </div>

        <!-- Lead Details Popup -->
        <ModalPopup size="large" :class="{'active': impact}">
            <ImpactDetails :id="impact" v-if="impact" @reload-impacts="loadImpactItems"/>
        </ModalPopup>

        <!-- New Impact Popup -->
        <ModalPopup size="large" :class="{'active': newImpactPopup}">
            <NewImpactForm v-if="newImpactPopup" @reload-impacts="loadImpactItems"/>
        </ModalPopup>

    </div>
</template>

<script>
import repository from "@/repository/repository";
import ImpactItem from "@/components/impact/ImpactItem.vue";
import ModalPopup from "@/components/widgets/ModalPopup.vue";
import ImpactDetails from "@/components/impact/ImpactDetails.vue";
import NewImpactForm from "@/components/impact/NewImpactForm.vue";

export default {
    name: "ImpactScreen",
    components: {NewImpactForm, ImpactDetails, ModalPopup, ImpactItem},
    data() {
        return {
            loaded: false,
            error: false,
            items: [],
            impact: null,
            newImpactPopup: false,
        }
    },
    created() {
        // Load properties
        this.loadImpactItems();

        // Listen for modal closings
        let app = this;
        window.addEventListener('popstate', function () {
            app.impact = null;
            app.newImpactPopup = null;
            document.documentElement.style.overflow = "auto";
        });
    },
    methods: {
        loadImpactItems() {
            this.loaded = false;
            repository.get("/impact")
                .then(response => {
                    this.items = response.data.items;
                    this.loaded = true;
                })
                .catch(error => {
                    this.error = true;
                    this.loaded = true;
                    console.log("An error happened while fetching the impact items", error);
                })
        },
        impactDeleted(id) {
            let indexToDelete = this.items.findIndex(item => item.id === id);
            if(indexToDelete !== -1) {
                this.items.splice(indexToDelete, 1);
            }
        },
        viewImpactDetails(id) {
            this.impact = id;
            history.pushState({page: 1}, "", "/impact/" + id);
            document.documentElement.style.overflow = "hidden";
        },
        openNewImpactPopup() {
            this.newImpactPopup = true;
            history.pushState({page: 1}, "", "/impact/new");
        },
    },
}
</script>

<style lang="scss" scoped>
.impact-screen {
    .impact-list-wrapper {
        position: relative;
        min-height: 300px;

        .spinner-border {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;
            opacity: 0.25;
        }
    }

    .impact-list {
    }
}
</style>