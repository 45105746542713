<template>
    <!-- Verify Account -->
    <form action="#" method="post" @submit.prevent="deleteAccount" :class="{'lead-actions delete-account': true, 'in-popup': inPopup, }">
        <h4>Delete Account</h4>
        <p>Use this action when the investor wants to restart their onboarding process from beginning. <strong>This action is permanent - the user will have to enter all details from the beginning when registering.</strong></p>
        <button type="submit" class="btn btn-danger mt-3">
            <img src="../../../assets/icons/trash.svg" alt="Trash">
            <span>Delete Account</span>
        </button>
        <div class="loading-effect" v-if="loading">
            <div class="spinner-border"></div>
        </div>
        <div class="text-danger fw-bolder mt-3 small" v-if="status === 'error'">
            {{ response }}
        </div>
    </form>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "DeleteLeadForm",
    components: {},
    props: ['userId', 'inPopup'],
    data() {
        return {
            loading: false,
            status: null,
            response: null,
        }
    },
    methods: {
        deleteAccount() {
            if(!confirm("Are you sure? This will permanently delete the selected lead.")) return;
            this.loading = true;
            repository.delete("/leads/" + this.userId)
                .then(() => {
                    this.$root.$emit("leadDeleted", this.userId);
                    history.back();
                })
                .catch(error => {
                    this.loading = false;
                    this.status = "error";
                    this.response = error.response.data.message;
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.lead-actions {
    margin-top: 1.5rem;
    overflow: hidden;
    display: block;
    position: relative;
    padding: 1.5rem;
    border-radius: $border-radius-large;
    box-shadow: rgba($dark, 0.05) 0 0.5rem 2rem;
    background: $white;
    text-decoration: none;
    color: inherit;

    h4 {
        margin: 0 0 0.25rem 0;
        font-size: 1rem;
        width: 100%;
        font-weight: bold;
        color: $dark;
    }

    p {
        font-size: 0.9rem;
        color: rgba($dark, 0.5);
        margin: 0;
    }

    .form-group {
        margin-top: 0.5rem;
        max-width: 50%;

        .form-control {
            box-shadow: none;
            border: none;
            background: rgba($dark, 0.05);
            color: $dark;
            font-size: 1rem;
            padding: 0.75rem;
            border-radius: $border-radius-medium;
            @extend .animated;

            &:focus {
                background: rgba($dark, 0.1);
            }
        }

        .btn {
            margin-top: 0.5rem;
        }
    }

    .btn {
        display: inline-flex;
        align-items: center;
        text-transform: none;
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: $border-radius-extra-large;

        img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.25rem;
        }

        &.btn-secondary {
            background: rgba($dark, 0.1);
            color: $dark;

            &:hover,
            &:focus {
                background: rgba($dark, 0.2);
                color: $dark;
            }
        }

        &.btn-danger {
            background: $danger;
            color: $white;

            img {
                filter: brightness(0) invert(1);
            }

            &:hover,
            &:focus {
                background: lighten($danger, 5%);
                color: $white;
            }
        }

        &.btn-success {
            background: $success;
            color: $white;

            img {
                filter: brightness(0) invert(1);
            }

            &:hover,
            &:focus {
                background: lighten($success, 5%);
                color: $white;
            }
        }
    }

    .loading-effect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 9;
        background: rgba($white, 0.9);

        .spinner-border {
            opacity: 0.25;
            border-width: 2px;
        }
    }

    &.in-popup {
        padding: 1.5rem;
        border: 1px solid $grey-200;
        border-radius: $border-radius-large;
        margin: 1.5rem 0 0 0;

        .form-group {
            max-width: 100%;
        }

        &.delete-account {
            background: rgba($danger, 0.025);
            border-color: rgba($danger, 0.125);
        }

        &.verify-account {
            background: rgba($success, 0.025);
            border-color: rgba($success, 0.125);
        }
    }
}
</style>