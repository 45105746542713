<template>
    <div :class="{'investor-account-details': true, 'expandable': true, 'expanded': expanded, 'in-popup': inPopup, }" @click="loadPartyDetails">
        <h4>Investor Party Details</h4>
        <p class="mb-0">Investor party details saved in NorthCapital:</p>
        <div class="expandable-content pt-3" v-if="expanded">
            <div class="spinner-border" v-if="!loaded"></div>
            <div class="details-wrapper" v-if="loaded && !error">
                <div class="investor-contact" v-if="data.partyId">
                    <strong>Party ID</strong>
                    <a href="https://api.norcapsecurities.com/admin_v3/client/parties" target="_blank" @click="copyValue(data.partyId)">
                        {{ data.partyId }}
                        <img src="../../../assets/icons/external-link.svg" alt="External Link">
                    </a>
                </div>
                <div class="investor-contact" v-if="data.firstName">
                    <strong>First Name</strong>
                    <span>{{ data.firstName }}</span>
                </div>
                <div class="investor-contact" v-if="data.lastName">
                    <strong>Last Name</strong>
                    <span>{{ data.lastName }}</span>
                </div>
                <div class="investor-contact" v-if="data.socialSecurityNumber">
                    <strong>SSN</strong>
                    <span>{{ protectedSSN }}</span>
                </div>
                <div class="investor-contact" v-if="data.birthday">
                    <strong>Birthday</strong>
                    <span>{{ data.birthday }}</span>
                </div>
                <div class="investor-contact" v-if="data.country">
                    <strong>Country</strong>
                    <span>{{ data.country }}</span>
                </div>
                <div class="investor-contact" v-if="data.address1">
                    <strong>Address 1</strong>
                    <span>{{ data.address1 }}</span>
                </div>
                <div class="investor-contact" v-if="data.address2">
                    <strong>Address 2</strong>
                    <span>{{ data.address2 }}</span>
                </div>
                <div class="investor-contact" v-if="data.city">
                    <strong>City</strong>
                    <span>{{ data.city }}</span>
                </div>
                <div class="investor-contact" v-if="data.state">
                    <strong>State</strong>
                    <span>{{ data.state }}</span>
                </div>
                <div class="investor-contact" v-if="data.zip">
                    <strong>ZIP</strong>
                    <span>{{ data.zip }}</span>
                </div>
                <div class="investor-contact" v-if="data.kycStatus">
                    <strong>KYC Status</strong>
                    <span>{{ data.kycStatus }}</span>
                </div>
                <div class="investor-contact" v-if="data.amlStatus">
                    <strong>AML Status</strong>
                    <span>{{ data.amlStatus }}</span>
                </div>
            </div>
            <div class="text-danger fw-bolder small" v-if="loaded && error">
                An error happened while fetching the party details.
            </div>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "InvestorPartyDetails",
    props: ['investorId', 'inPopup', ],
    data() {
        return {
            expanded: false,
            loaded: false,
            error: false,
            data: {
                partyId: null,
                firstName: null,
                lastName: null,
                socialSecurityNumber: null,
                birthday: null,
                country: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                zip: null,
                kycStatus: null,
                amlStatus: null,
            },
        }
    },
    methods: {
        loadPartyDetails() {
            if(this.expanded) return;
            this.expanded = true;
            this.loaded = false;
            this.error = false;

            // Load data
            repository.get("/investors/" + this.investorId + "/party")
                .then(response => {
                    console.log(response.data);

                    // Finish loading
                    this.loaded = true;

                    // Check if the party was found
                    if(response.data.party === null) {
                        this.error = true;
                    }

                    // Save data
                    this.data.partyId = response.data.party.partyId;
                    this.data.firstName = response.data.party.firstName;
                    this.data.lastName = response.data.party.lastName;
                    this.data.socialSecurityNumber = response.data.party.socialSecurityNumber;
                    this.data.birthday = response.data.party.birthday;
                    this.data.country = response.data.party.country;
                    this.data.address1 = response.data.party.address1;
                    this.data.address2 = response.data.party.address2;
                    this.data.city = response.data.party.city;
                    this.data.state = response.data.party.state;
                    this.data.zip = response.data.party.zip;
                    this.data.kycStatus = response.data.party.kycStatus;
                    this.data.amlStatus = response.data.party.amlStatus;
                })
                .catch(error => {
                    this.loaded = true;
                    this.error = true;
                    console.log("Error while fetching party details", error);
                });
        },
        copyValue(value) {
            this.$copyText(value);
        },
    },
    computed: {
        protectedSSN() {
            if(!this.loaded) return null;
            return "******" + this.data.socialSecurityNumber.substr(6, 3);
        },
    },
}
</script>

<style lang="scss" scoped>
.investor-account-details {
    position: relative;
    cursor: pointer;
    background: $white;
    box-shadow: rgba($dark, 0.1) 0 0.25rem 0.5rem;
    padding: 1.5rem;
    border-radius: $border-radius-large;
    @extend .animated;

    &:hover {
        box-shadow: rgba($dark, 0.1) 0 0.5rem 1rem;
    }

    &.expanded {
        cursor: inherit;
        background: rgba($dark, 0.025);
        box-shadow: none;
    }

    &:not(.in-popup) {
        border-radius: $border-radius-large;
        box-shadow: rgba($dark, 0.05) 0 0.5rem 2rem;
        background: $white;

        &.expanded {
            background: $white;
        }
    }

    &:not(.expanded):after {
        position: absolute;
        top: 0;
        right: 1.5rem;
        bottom: 0;
        width: 0.5rem;
        height: 0.5rem;
        border-right: 2px solid $dark;
        border-bottom: 2px solid $dark;
        transform-origin: center;
        transform: rotate(-45deg);
        content: ' ';
        margin: auto;
    }

    h4 {
        margin: 0 0 0.25rem 0;
        font-size: 1rem;
        width: 100%;
        font-weight: bold;
        color: $dark;
    }

    p {
        font-size: 0.9rem;
        color: rgba($dark, 0.5);
        margin: 0 0 0.5rem 0;
    }

    .investor-contact {
        display: flex;
        align-items: flex-start;
        padding: 0.5rem 0;

        & + .investor-contact {
            border-top: 1px solid $grey-200;
        }

        strong {
            width: 40%;
            flex-shrink: 0;
            flex-grow: 0;
            font-weight: 400;
            padding-right: 0.5rem;

            &:after {
                content: ':';
            }
        }

        span {
            font-weight: 600;
            color: $dark;
            margin-left: 0.25rem;
        }

        a {
            font-weight: 600;
            color: $dark;
            margin-left: 0.25rem;
            position: relative;
            text-decoration: none;
            padding-right: 1.25rem;

            &:hover {
                color: $primary;
            }

            img {
                width: 0.75rem;
                height: 0.75rem;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                opacity: 0.25;
            }
        }
    }

    .spinner-border {
        opacity: 0.25;
        margin: 2.5rem auto;
        display: block;
    }

}
</style>