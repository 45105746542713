<template>
    <div class="row">
        <div class="col-md-8">
            <!-- Refunds List -->
            <div class="dashboard-box">
                <!-- Refunds List -->
                <div class="refunds-list-wrapper" ref="refundsList">
                    <!-- Refunds Entries -->
                    <div v-if="loaded">
                        <RefundListItem :refund="refund" v-for="(refund, key) in filteredRefunds" :key="key"></RefundListItem>
                    </div>

                    <!-- No Investments -->
                    <p class="small py-2" v-if="loaded && refunds.length === 0">No refunds found in the system.</p>

                    <!-- Loading effect -->
                    <div class="spinner-border" v-if="!loaded"></div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <!-- Summary -->
            <div class="dashboard-box refunds-summary">
                <h2 class="dashboard-subtitle">Refunds Summary</h2>

                <!-- Total Refunded Amount -->
                <div class="summary-main-entry">
                    <div class="summary-title">Total Refunded Amount <ExplanationIcon title="These are the funds that you have manually refunded to all investors."></ExplanationIcon></div>
                    <VueCountTo class="summary-number fw-bolder text-danger" :autoplay="false" :startVal="0" :endVal="totalRefundedAmount" :duration="1000" ref="totalRefunded" prefix="$"></VueCountTo>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCountTo from 'vue-count-to';
import repository from "@/repository/repository";
import RefundListItem from "@/components/refunds/RefundListItem.vue";
import ExplanationIcon from "@/components/widgets/ExplanationIcon.vue";

export default {
    name: "RefundsList",
    components: {ExplanationIcon, RefundListItem, VueCountTo},
    data() {
        return {
            loaded: false,
            refunds: [],
            end: 20,
            perPage: 20,
            searchTerm: null,
            exporting: false,
        }
    },
    mounted() {
        // Load Refunds
        this.getRefunds();

        // Add event listener
        this.$refs.refundsList.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        // Remove event listener
        this.$refs.refundsList.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        getRefunds() {
            this.loaded = false;
            repository.get("/refunds")
                .then(response => {
                    this.refunds = response.data.refunds;
                    this.loaded = true;
                    this.$refs.totalRefunded.start();
                })
                .catch(error => {
                    console.log("Error while fetching the refunds", error);
                })
        },
        handleScroll() {
            const scrollOffset = this.$refs.refundsList.scrollTop + this.$refs.refundsList.clientHeight
            if (scrollOffset >= this.$refs.refundsList.scrollHeight - 20) {
                this.end += this.perPage
                if(this.end >= this.refunds.length) this.end = this.refunds.length;
            }
        },
    },
    computed: {
        filteredRefunds() {
            return this.refunds.filter(refund => {
                if(!this.searchTerm || this.searchTerm.trim().length === 0) return refund;
                return refund.user.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || refund.user.email.toLowerCase().includes(this.searchTerm.toLowerCase()) || refund.id == this.searchTerm;
            }).slice(0, this.end);
        },
        totalRefundedAmount() {
            return this.refunds.reduce((total, refund) => total + refund.amount, 0);
        },
    },
    watch: {
        searchTerm() {
            this.end = 20;
        },
    },
}
</script>

<style lang="scss" scoped>
.filter-refunds-field {
    position: relative;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0.5rem;
        margin: auto;
        width: 1rem;
        height: 1rem;
        opacity: 0.25;
        z-index: 1;
    }

    .form-control {
        box-shadow: none;
        padding: 0.5rem 0.5rem 0.5rem 2rem;
        border: none;
        outline: none;
        color: $dark;
        position: relative;
        z-index: 0;
        @extend .animated;

        &:focus {
            background: $grey-200;
        }
    }
}

.refunds-list-wrapper {
    position: relative;
    min-height: 15rem;
    max-height: 40rem;
    overflow-x: hidden;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    width: calc(100% + 3rem);
    margin: -0.5rem -1.5rem -1.5rem -1.5rem;

    .spinner-border {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.15;
    }
}

.refunds-summary {
    .summary-main-entry {
        @extend .animated;

        .summary-number {
            font-size: 2rem;
            font-weight: bold;
            color: $danger;
        }

        .summary-title {
            display: block;
            color: rgba($dark, 0.5);
            font-weight: 600;
            font-size: 0.8rem;

            &.fw-bolder {
                color: $dark;
            }
        }
    }
}
</style>