<template>
    <InvestorDetails></InvestorDetails>
</template>

<script>

import InvestorDetails from "@/components/accounts/investors/InvestorDetails.vue";

export default {
    name: "InvestorDetailsPage",
    components: {InvestorDetails},
}
</script>