<template>
    <div class="property-details-wrapper">

        <!-- Details -->
        <form @submit.prevent="save" class="property-details">
            <div class="property-images">
                <!-- All images -->
                <div v-for="(image, key) in images" :key="key" :class="{'property-image': true, 'active': image.primary}" @click.prevent="setPrimary(image)">

                    <!-- Image thumbnail -->
                    <img :src="image.image" :alt="name" class="property-image-thumb">

                    <!-- Primary image label -->
                    <span v-if="image.primary">Primary</span>

                    <!-- Delete image -->
                    <button type="button" class="btn btn-icon-only" data-bs-toggle="tooltip" title="Delete" @click.prevent.stop="deleteImage(image)">
                        <img src="../../assets/icons/trash.svg" alt="Delete">
                    </button>
                </div>

                <!-- Upload Image Box -->
                <div class="upload-image" v-if="images.length < 3">
                    <input type="file" id="images" ref="images" multiple @change="addImages">
                    <label for="images" class="upload-image-label">
                        <img src="../../assets/icons/image.svg" alt="Image">
                        <strong>Upload images</strong>
                        <span>Maximum 3 images allowed<br>JPG, PNG only | 1MB</span>
                    </label>
                </div>
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="name">Property Name</label>
                <input type="text" id="name" class="form-control property-name" placeholder="Property name" v-model="name" required>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="address">Full address</label>
                <input type="text" id="address" class="form-control property-address" placeholder="Full address" v-model="address" required>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="price">Purchase Price</label>
                <CurrencyInput placeholder="Enter amount in USD" v-model="purchasePrice"/>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="row">
                <div class="col-md-6 mt-4">
                    <div class="form-field edit-field">
                        <label class="form-label" for="profile">Asset Profile</label>
                        <select id="profile" class="form-control asset-profile" v-model="assetProfile" required>
                            <option>Class A multifamily</option>
                            <option>Class B multifamily</option>
                            <option>Class C multifamily</option>
                        </select>
                        <img src="../../assets/icons/edit.svg" alt="Edit">
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                    <div class="form-field edit-field">
                        <label class="form-label" for="units">Units</label>
                        <input id="units" type="text" class="form-control units" placeholder="Units" v-model="units" required>
                        <img src="../../assets/icons/edit.svg" alt="Edit">
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                    <div class="form-field edit-field">
                        <label class="form-label" for="market">Market</label>
                        <input id="market" type="text" class="form-control market" placeholder="Market" v-model="market" required>
                        <img src="../../assets/icons/edit.svg" alt="Edit">
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                    <div class="form-field edit-field">
                        <label class="form-label" for="sub-market">Sub Market</label>
                        <input id="sub-market" type="text" class="form-control sub-market" placeholder="Sub Market" v-model="subMarket" required>
                        <img src="../../assets/icons/edit.svg" alt="Edit">
                    </div>
                </div>
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="acquisition-date">Acquisition Date</label>
                <input type="date" id="acquisition-date" class="form-control acquisition-date" placeholder="Acquisition Date" v-model="acquisition" required>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="text-danger fw-bolder mt-4" v-if="savingError">{{ savingError }}</div>
            <div class="d-flex justify-content-between mt-4 pt-3">
                <button type="button" class="btn btn-light" @click.prevent="cancelChanges">Cancel</button>
                <button type="submit" class="btn btn-primary" :disabled="saving">
                    <span :class="{'opacity-0': saving}">Save Changes</span>
                    <span class="spinner-border" v-if="saving"></span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import CurrencyInput from "@/components/widgets/CurrencyInput.vue";

export default {
    name: "NewPropertyForm",
    components: {CurrencyInput},
    emits: ['reload-properties'],
    data() {
        return {
            saving: false,
            savingError: false,
            name: null,
            address: null,
            purchasePrice: null,
            assetProfile: null,
            units: null,
            market: null,
            subMarket: null,
            acquisition: null,
            images: [],
        }
    },
    methods: {
        save() {
            this.savingError = false;

            // Verify images
            if(!this.verifyFields()) {
                return false;
            }

            // Start saving process
            console.log("Saving changes..");
            this.saving = true;

            // Prepare form data
            let formData = new FormData();
            formData.append('name', this.name);
            formData.append('address', this.address);
            formData.append('purchase_price', this.purchasePrice);
            formData.append('asset_profile', this.assetProfile);
            formData.append('units', this.units);
            formData.append('market', this.market);
            formData.append('sub_market', this.subMarket);
            formData.append('acquisition', this.acquisition);
            this.images.forEach(image => {
                formData.append('images[]', image.file);
            })

            // Load data
            repository.post("/properties", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    console.log(response.data);
                    this.saving = false;
                    this.savingError = false;
                    this.$emit('reload-properties');
                    history.back();
                })
                .catch(error => {
                    this.saving = false;
                    this.savingError = error.response.data.message;
                    console.log("An error happened while saving the property", error);
                });
        },
        cancelChanges() {
            history.back();
        },
        setPrimary(image) {
            this.images.forEach(img => img.primary = 0);
            image.primary = 1;
        },
        deleteImage(image) {
            if(this.images.indexOf(image) < 0) return null;
            this.images.splice(this.images.indexOf(image), 1);
        },
        addImages() {
            if(this.$refs.images.files.length === 0) return null;
            Array.from(this.$refs.images.files).forEach(file => {

                // Verify if no more than 3 images
                if(this.images.length >= 3) return null;

                // Add temporary image
                let image = {
                    image: URL.createObjectURL(file),
                    file: file,
                    uploaded: true,
                    primary: this.images.length === 0,
                }
                this.images.push(image);
            });
        },
        verifyFields() {
            if(this.images.length <= 0) {
                this.savingError = "Please upload at least 1 image from the property";
                return false;
            }
            if(!this.name) {
                this.savingError = "Please enter property name";
                return false;
            }
            if(!this.address) {
                this.savingError = "Please enter property address";
                return false;
            }
            if(!this.assetProfile) {
                this.savingError = "Please enter property asset profile";
                return false;
            }
            if(!this.purchasePrice) {
                this.savingError = "Please enter property price";
                return false;
            }
            if(!this.units) {
                this.savingError = "Please enter property units";
                return false;
            }
            if(!this.market) {
                this.savingError = "Please enter property market";
                return false;
            }
            if(!this.subMarket) {
                this.savingError = "Please enter property sub market";
                return false;
            }
            if(!this.acquisition) {
                this.savingError = "Please enter property acquisition date";
                return false;
            }
            return true;
        },
    },
}
</script>

<style lang="scss" scoped>
.property-images {
    display: flex;
    flex-wrap: wrap;

    .property-image {
        width: calc(25% - 0.5rem);
        height: 6rem;
        border-radius: $border-radius-medium;
        overflow: hidden;
        margin-right: 0.5rem;
        position: relative;
        cursor: pointer;
        background: rgba($dark, 0.1);
        @extend .animated;

        &:hover {
            box-shadow: rgba($primary, 1) 0 0 0 0.15rem;

            .btn.btn-icon-only {
                opacity: 1;
            }
        }

        &.active {
            box-shadow: rgba($primary, 1) 0 0 0 0.15rem;
        }

        .property-image-thumb {
            width: 100%;
            height: 6rem;
            object-fit: cover;
            position: relative;
            z-index: 0;
        }

        span {
            position: absolute;
            bottom: 0;
            left: 0.5rem;
            z-index: 1;
            height: auto;
            margin: auto;
            color: $white;
            background: $primary;
            padding: 0.1rem 0.45rem 0.1rem 0.45rem;
            font-weight: 600;
            border-radius: $border-radius-medium $border-radius-medium 0 0;
            font-size: 0.7rem;
        }

        .btn.btn-icon-only {
            position: absolute;
            bottom: 0.5rem;
            right: 0.5rem;
            width: 1.25rem;
            height: 1.25rem;
            padding: 0;
            line-height: 1.25rem;
            border: none;
            z-index: 1;
            opacity: 0;
            background: $primary;
            @extend .animated;

            &:hover {
                background: $primary;
                border: none;
                outline: none;
            }

            img {
                width: 1rem;
                height: 1rem;
                filter: brightness(0) invert(1);
            }
        }

        &.uploading {
            .property-image-thumb {
                opacity: 0;
            }

            .spinner-border {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-width: 2px;
                border-color: #333;
                border-right-color: transparent;
                margin: auto;
                z-index: 3;
            }

            .btn.btn-icon-only {
                opacity: 0;
            }
        }
    }

    .upload-image {
        width: calc(50% - 0.5rem);
        height: 6rem;
        border-radius: $border-radius-medium;
        overflow: hidden;
        margin-right: 0.5rem;
        position: relative;
        cursor: pointer;
        background: rgba($dark, 0.05);
        @extend .animated;

        &:hover {
            background: rgba($dark, 0.1);
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        label.upload-image-label {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 1rem 1rem 1rem 4.5rem;
            cursor: pointer;

            img {
                position: absolute;
                top: 1rem;
                left: 1rem;
                width: 3rem;
                height: 3rem;
            }

            strong {
                display: block;
                font-size: 0.9rem;
                color: $dark;
            }

            span {
                display: block;
                font-size: 0.8rem;
                color: rgba($dark, 0.5);
                line-height: 1.2;
                background: none;
            }
        }
    }
}
</style>