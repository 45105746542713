<template>
    <div class="properties-screen">

        <!-- Properties Management -->
        <h1 class="dashboard-title">Properties Management</h1>

        <!-- Properties List -->
        <div class="dashboard-box mt-3">

            <!-- Title & New Button -->
            <div class="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-4">
                <div>
                    <p>Add all properties that have been purchased & financed by the Reiturn fund.</p>
                </div>
                <a href="#" class="btn btn-primary" @click.prevent="openNewPropertyPopup">New Property</a>
            </div>

            <!-- Items List -->
            <div class="properties-list-wrapper">
                <div class="spinner-border" v-if="!loaded"></div>
                <div class="properties-list" v-if="loaded">
                    <PropertyItem
                        v-for="(property, key) in properties"
                        :property="property"
                        :key="key"
                        @deleted="propertyDeleted"
                        @details="viewPropertyDetails"
                    />
                </div>
            </div>
        </div>

        <!-- Property Details Popup -->
        <ModalPopup size="large" :class="{'active': property !== null}">
            <PropertyDetails :property-id="property" v-if="property" @reload-properties="loadProperties"/>
        </ModalPopup>

        <!-- New Property Popup -->
        <ModalPopup size="large" :class="{'active': newPropertyPopup}">
            <NewPropertyForm v-if="newPropertyPopup" @reload-properties="loadProperties"/>
        </ModalPopup>

    </div>
</template>

<script>
import repository from "@/repository/repository";
import PropertyItem from "@/components/properties/PropertyItem.vue";
import ModalPopup from "@/components/widgets/ModalPopup.vue";
import PropertyDetails from "@/components/properties/PropertyDetails.vue";
import NewPropertyForm from "@/components/properties/NewPropertyForm.vue";

export default {
    name: "PropertiesScreen",
    components: {NewPropertyForm, PropertyDetails, ModalPopup, PropertyItem},
    data() {
        return {
            loaded: false,
            error: false,
            properties: [],
            property: null,
            newPropertyPopup: null,
        }
    },
    created() {
        // Load properties
        this.loadProperties();

        // Listen for modal closings
        let app = this;
        window.addEventListener('popstate', function () {
            app.property = null;
            app.newPropertyPopup = null;
            document.documentElement.style.overflow = "auto";
        });
    },
    methods: {
        loadProperties() {
            this.loaded = false;
            repository.get("/properties")
                .then(response => {
                    this.properties = response.data.properties;
                    this.loaded = true;
                })
                .catch(error => {
                    this.error = true;
                    this.loaded = true;
                    console.log("An error happened while fetching the properties", error);
                })
        },
        propertyDeleted(propertyId) {
            let propertyIndexToDelete = this.properties.findIndex(item => item.id === propertyId);
            if(propertyIndexToDelete !== -1) {
                this.properties.splice(propertyIndexToDelete, 1);
            }
        },
        viewPropertyDetails(propertyId) {
            this.property = propertyId;
            history.pushState({page: 1}, "", "/properties/" + propertyId);
            document.documentElement.style.overflow = "hidden";
        },
        openNewPropertyPopup() {
            this.newPropertyPopup = true;
            history.pushState({page: 1}, "", "/properties/new");
        },
    },
}
</script>

<style lang="scss" scoped>
.properties-list-wrapper {
    position: relative;
    min-height: 300px;

    .spinner-border {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        opacity: 0.25;
    }
}

.new-property {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px dashed rgba($dark, 0.1);
    color: $dark;
    text-decoration: none;

    img {
        width: 3rem;
        height: 3rem;
        flex-shrink: 0;
        opacity: 0.1;
    }

    .button-content {
        display: block;
        padding-left: 1rem;

        strong {
            display: block;
        }

        span {
            display: block;
        }
    }

}
</style>