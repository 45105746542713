<template>
    <!-- Change Email -->
    <form action="#" method="post" @submit.prevent="changePassword" :class="{'investor-actions password-change': true, 'in-popup': inPopup, }">
        <h4>Change Password</h4>
        <p>Reset the password for this account.</p>
        <div class="form-group">
            <input type="text" class="form-control" v-model="input" required placeholder="Enter password">
            <button type="submit" class="btn btn-secondary">
                <img src="../../assets/icons/lock.svg" alt="Lock">
                <span>Change Password</span>
            </button>
        </div>
        <div class="loading-effect" v-if="loading">
            <div class="spinner-border"></div>
        </div>
        <div class="password-errors mt-3" v-if="status === 'error'">
            <p class="text-danger fw-bolder small m-0" v-if="input && input.length < 8">Password should be at least 8 characters</p>
            <p class="text-danger fw-bolder small m-0" v-if="!matchRegex(input, /[A-Z]/)">Add at least one uppercase character (A-Z)</p>
            <p class="text-danger fw-bolder small m-0" v-if="!matchRegex(input, /[a-z]/)">Add at least one lowercase character (a-z)</p>
            <p class="text-danger fw-bolder small m-0" v-if="!matchRegex(input, /[0-9]/)">Add at least one numeric character (0-9)</p>
        </div>
        <div class="text-danger fw-bolder mt-3 small" v-if="status === 'error'">
            {{ response }}
        </div>
        <div class="text-success fw-bolder mt-3 small" v-if="status === 'success'">
            The password was successfully set to <strong>{{ input }}</strong>
        </div>
    </form>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "UpdateUserPasswordForm",
    components: {},
    props: ['userId', 'inPopup'],
    data() {
        return {
            input: null,
            loading: false,
            status: null,
            response: null,
        }
    },
    methods: {
        changePassword() {
            // Verify password
            this.status = null;
            if(!this.checkPassword()) {
                this.status = "error";
                return false;
            }

            // Confirm to proceed
            if(!confirm("Are you sure? This action will replace their current password with the one you provided.")) return false;

            // Save updates
            this.loading = true;
            repository.put("/users/" + this.userId + "/password", {
                password: this.input,
            })
                .then(response => {
                    console.log(response.data);
                    this.loading = false;
                    this.status = "success";
                })
                .catch(error => {
                    console.log(error);
                    this.status = "error";
                    this.response = error.data.message;
                })
        },
        checkPassword() {
            if(!this.input) return false;
            if(this.input.length < 8) return false;
            if(!this.matchRegex(this.input, /[A-Z]/)) return false;
            if(!this.matchRegex(this.input, /[a-z]/)) return false;
            return this.matchRegex(this.input, /[0-9]/);
        },
        matchRegex(input, regex) {
            return regex.test(input);
        },
    },
}
</script>

<style lang="scss" scoped>
.investor-actions {
    margin-top: 1.5rem;
    overflow: hidden;
    display: block;
    position: relative;
    padding: 1.5rem;
    border-radius: $border-radius-large;
    box-shadow: rgba($dark, 0.05) 0 0.5rem 2rem;
    background: $white;
    text-decoration: none;
    color: inherit;

    h4 {
        margin: 0 0 0.25rem 0;
        font-size: 1rem;
        width: 100%;
        font-weight: bold;
        color: $dark;
    }

    p {
        font-size: 0.9rem;
        color: rgba($dark, 0.5);
        margin: 0;
    }

    .form-group {
        margin-top: 0.5rem;
        max-width: 50%;

        .form-control {
            box-shadow: none;
            border: none;
            background: rgba($dark, 0.05);
            color: $dark;
            font-size: 1rem;
            padding: 0.75rem;
            border-radius: $border-radius-medium;
            @extend .animated;

            &:focus {
                background: rgba($dark, 0.1);
            }
        }

        .btn {
            margin-top: 0.5rem;
        }
    }

    .btn {
        display: inline-flex;
        align-items: center;
        text-transform: none;
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: $border-radius-extra-large;

        img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.25rem;
        }

        &.btn-secondary {
            background: rgba($dark, 0.1);
            color: $dark;

            &:hover,
            &:focus {
                background: rgba($dark, 0.2);
                color: $dark;
            }
        }

        &.btn-danger {
            background: $danger;
            color: $white;

            img {
                filter: brightness(0) invert(1);
            }

            &:hover,
            &:focus {
                background: lighten($danger, 5%);
                color: $white;
            }
        }

        &.btn-success {
            background: $success;
            color: $white;

            img {
                filter: brightness(0) invert(1);
            }

            &:hover,
            &:focus {
                background: lighten($success, 5%);
                color: $white;
            }
        }
    }

    .loading-effect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 9;
        background: rgba($white, 0.9);

        .spinner-border {
            opacity: 0.25;
            border-width: 2px;
        }
    }

    &.in-popup {
        padding: 1.5rem;
        border: 1px solid $grey-200;
        border-radius: $border-radius-large;
        margin: 1.5rem 0 0 0;

        .form-group {
            max-width: 100%;
        }
    }
}
</style>