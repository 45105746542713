<template>
    <div class="property-details-wrapper">

        <!-- Details -->
        <form @submit.prevent="saveChanges" class="property-details" v-if="loaded && !error">
            <PropertyImagesManagement :property="$data"></PropertyImagesManagement>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="name">Property Name</label>
                <input type="text" id="name" class="form-control property-name" placeholder="Property name" v-model="name">
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="address">Full address</label>
                <input type="text" id="address" class="form-control property-address" placeholder="Full address" v-model="address">
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="price">Purchase Price</label>
                <CurrencyInput placeholder="Enter amount in USD" v-model="purchasePrice"/>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="row">
                <div class="col-md-6 mt-4">
                    <div class="form-field edit-field">
                        <label class="form-label" for="profile">Asset Profile</label>
                        <select id="profile" class="form-control asset-profile" v-model="assetProfile">
                            <option>Class A multifamily</option>
                            <option>Class B multifamily</option>
                            <option>Class C multifamily</option>
                        </select>
                        <img src="../../assets/icons/edit.svg" alt="Edit">
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                    <div class="form-field edit-field">
                        <label class="form-label" for="units">Units</label>
                        <input id="units" type="text" class="form-control units" placeholder="Units" v-model="units">
                        <img src="../../assets/icons/edit.svg" alt="Edit">
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                    <div class="form-field edit-field">
                        <label class="form-label" for="market">Market</label>
                        <input id="market" type="text" class="form-control market" placeholder="Market" v-model="market">
                        <img src="../../assets/icons/edit.svg" alt="Edit">
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                    <div class="form-field edit-field">
                        <label class="form-label" for="sub-market">Sub Market</label>
                        <input id="sub-market" type="text" class="form-control sub-market" placeholder="Sub Market" v-model="subMarket">
                        <img src="../../assets/icons/edit.svg" alt="Edit">
                    </div>
                </div>
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="acquisition-date">Acquisition Date</label>
                <input type="text" id="acquisition-date" class="form-control acquisition-date" placeholder="Acquisition Date" v-model="acquisition">
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="d-flex justify-content-between mt-4 pt-3">
                <button type="button" class="btn btn-light" @click.prevent="cancelChanges">Cancel</button>
                <button type="submit" class="btn btn-primary" :disabled="saving">
                    <span v-if="!saving">Save Changes</span>
                    <span class="spinner-border" v-if="saving"></span>
                </button>
            </div>
        </form>

        <!-- Error -->
        <div class="error-message" v-if="loaded && error">
            <img src="../../assets/icons/error.svg" alt="Error">
            <h4>Property not found</h4>
            <p>We couldn't find the property with the provided ID. Please try again.</p>
        </div>

        <!-- Loading Effect -->
        <div class="spinner-border" v-if="!loaded"></div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import CurrencyInput from "@/components/widgets/CurrencyInput.vue";
import PropertyImagesManagement from "@/components/properties/PropertyImagesManagement.vue";

export default {
    name: "PropertyDetails",
    components: {PropertyImagesManagement, CurrencyInput},
    props: ['propertyId',],
    emits: ['reload-properties'],
    data() {
        return {
            loaded: false,
            error: false,
            saving: false,
            savingError: false,
            id: null,
            name: null,
            address: null,
            purchasePrice: null,
            assetProfile: null,
            units: null,
            market: null,
            subMarket: null,
            acquisition: null,
            images: [],
        }
    },
    created() {
        this.loadDetails(this.$route.params.id ?? this.propertyId);
    },
    methods: {
        loadDetails(id) {
            if (!id) return null;

            // Reset data
            this.id = id;
            this.loaded = false;
            this.error = false;

            // Load data
            repository.get("/properties/" + id)
                .then(response => {
                    console.log(response.data);
                    this.name = response.data.property.name;
                    this.address = response.data.property.address;
                    this.purchasePrice = response.data.property.purchase_price;
                    this.assetProfile = response.data.property.asset_profile;
                    this.units = response.data.property.units;
                    this.market = response.data.property.market;
                    this.subMarket = response.data.property.sub_market;
                    this.acquisition = response.data.property.acquisition;
                    this.images = response.data.property.images;
                    this.images.forEach(image => {
                        image.uploaded = true;
                    });
                    this.loaded = true;
                    this.error = false;
                })
                .catch(error => {
                    this.loaded = true;
                    this.error = true;
                    console.log("Property not found", error);
                });
        },
        saveChanges() {
            console.log("Saving changes..");
            this.saving = true;

            // Save data
            repository.put("/properties/" + this.id, {
                name: this.name,
                address: this.address,
                purchase_price: this.purchasePrice,
                asset_profile: this.assetProfile,
                units: this.units,
                market: this.market,
                sub_market: this.subMarket,
                acquisition: this.acquisition,
            })
                .then(response => {
                    console.log(response.data);
                    this.saving = false;
                    this.savingError = false;
                    this.$emit('reload-properties');
                    history.back();
                })
                .catch(error => {
                    this.saving = false;
                    this.savingError = true;
                    console.log("An error happened while saving the property updates", error);
                });
        },
        cancelChanges() {
            history.back();
        },
    },
    watch: {
        propertyId(newId) {
            this.loadDetails(newId);
        },
        '$route.params.id'(newId) {
            this.loadDetails(newId);
        }
    },
}
</script>

<style lang="scss">
.property-details-wrapper {
    position: relative;

    // Loading Effect
    > .spinner-border {
        opacity: 0.25;
        margin: 2.5rem auto;
        display: block;
    }

    // Error Effect
    .error-message {
        padding: 2.5rem 5rem;
        text-align: center;

        img {
            width: 8rem;
            height: 8rem;
            display: block;
            margin: auto;
        }

        h4 {
            font-size: 1.25rem;
            font-weight: bold;
            color: $danger;
            margin: 1.5rem 0 0.5rem 0;
        }
    }

    // Property Details
    .property-details {

        .form-field.edit-field {
            position: relative;

            &:focus-within {
                img {
                    opacity: 0.5;
                }
            }

            .form-control {
                padding-right: 1.75rem;
                z-index: 1;
                background: transparent;
                position: relative;
            }

            img {
                position: absolute;
                bottom: 0.75rem;
                right: 0;
                z-index: 0;
                width: 1.5rem;
                height: 1.5rem;
                opacity: 0.1;
                @extend .animated;
            }
        }
    }
}
</style>