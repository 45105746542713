<template>
    <div class="row mt-3">
        <div class="col-md-6 col-lg-3 mb-4">
            <router-link to="/leads" :class="{'dashboard-box box-clickable stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="Total users that have started the onboarding process, but haven't completed it">
                <VueCountTo class="stats-box-number" :autoplay="false" :startVal="0" :endVal="newLeads7Days" :duration="1000" ref="newLeads7"></VueCountTo>
                <span class="fw-bolder">New Leads</span>
                <span>Last 7 days</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </router-link>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <router-link to="/investors" :class="{'dashboard-box box-clickable stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="New investors that have completed the onboarding form in the last 7 days">
                <VueCountTo class="stats-box-number" :autoplay="false" :startVal="0" :endVal="newInvestors7Days" :duration="1000" ref="newInvestors7"></VueCountTo>
                <span class="fw-bolder">New Investors</span>
                <span>Last 7 days</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </router-link>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <router-link to="/investments" :class="{'dashboard-box box-clickable stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="Total investments that have been initiated or funded in the last 7 days">
                <VueCountTo class="stats-box-number" :autoplay="false" :startVal="0" :endVal="totalInvested7Days" :duration="1000" ref="totalInvested7" prefix="$"></VueCountTo>
                <span class="fw-bolder">Total Invested</span>
                <span>Last 7 Days</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </router-link>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <router-link to="/investments" :class="{'dashboard-box box-clickable stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="Total investments that have been initiated or funded in the last 30 days">
                <VueCountTo class="stats-box-number" :autoplay="false" :startVal="0" :endVal="totalInvested30Days" :duration="1000" ref="totalInvested30" prefix="$"></VueCountTo>
                <span class="fw-bolder">Total Invested</span>
                <span>Last 30 days</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </router-link>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import VueCountTo from 'vue-count-to';

export default {
    name: "DashboardBasicStats",
    components: { VueCountTo },
    data: function () {
        return {
            loaded: false,
            totalInvested7Days: 20000,
            totalInvested30Days: 140000,
            newLeads7Days: 51,
            newInvestors7Days: 11,
        }
    },
    created() {
        this.getStatistics();
    },
    methods: {
        getStatistics() {
            this.loaded = false;
            repository.get("/dashboard/stats/basic")
                .then(response => {
                    this.totalInvested7Days = response.data.total_investments_7;
                    this.totalInvested30Days = response.data.total_investments_30;
                    this.newLeads7Days = response.data.new_leads;
                    this.newInvestors7Days = response.data.new_investors;
                    this.$refs.totalInvested7.start();
                    this.$refs.totalInvested30.start();
                    this.$refs.newLeads7.start();
                    this.$refs.newInvestors7.start();
                    this.loaded = true;
                })
                .catch(error => {
                    console.log("Error while fetching Basic Statistics Data", error);
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.stats-box {
    .stats-box-number {
        font-weight: bold;
        display: block;
        font-size: 2rem;
        color: $dark;
    }

    span {
        display: block;
        color: rgba($dark, 0.5);
        font-weight: 600;
        font-size: 0.8rem;

        &.fw-bolder {
            color: $dark;
        }
    }

    &.loading {
        position: relative;
        @extend .disable-clicks;
        @extend .disable-selection;

        .stats-box-number {
            color: transparent;
            @extend .disable-clicks;
            @extend .disable-selection;
        }

        span {
            color: transparent;
            @extend .disable-clicks;
            @extend .disable-selection;
        }

        .spinner-border {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.25;
            margin: auto;
        }
    }
}
</style>