<template>
    <div :class="{'distribution-period-box': true, 'loading': loading}">
        <strong class="period-year">{{ period.year }}</strong>
        <strong class="period-quarter">{{ period.quarter }}</strong>
        <div class="period-details">
            <div class="period-details-entry">
                <strong>Total returns</strong>
                <span>{{ period.percentage }}%</span>
            </div>
            <div class="period-details-entry">
                <strong>Payable Date</strong>
                <span>{{ convertToReadableDate(period.payable_date) }}</span>
            </div>
            <div class="period-details-entry">
                <strong>Created on</strong>
                <span>{{ convertToReadableDate(period.created_at) }}</span>
            </div>
            <div class="period-details-entry">
                <strong>Created by</strong>
                <img :src="period.admin.avatar" data-bs-toggle="tooltip" :title="period.admin.name" :alt="period.admin.name" class="period-admin-avatar">
            </div>
        </div>
        <div class="period-amounts">
            <div class="period-amount-item">
                <strong>{{ convertToReadableNumber(period.total_distributed) }}</strong>
                <span>Distributed</span>
            </div>
            <div class="period-amount-item">
                <strong>{{ convertToReadableNumber(period.total_reinvested) }}</strong>
                <span>Reinvested</span>
            </div>
        </div>
        <div class="period-actions">
            <button class="btn btn-primary" @click.prevent="$emit('details', period.id)">View</button>
            <button class="btn btn-light ms-md-2" @click.prevent="deleteDistributionPeriod" :disabled="loading">
                <span :class="{'opacity-0': loading}">Delete</span>
                <span class="spinner-border" v-if="loading"></span>
            </button>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import {readableDate, readableNumber} from "@/helpers";

export default {
    name: "DistributionPeriodBox",
    props: ['period',],
    emits: ['details', 'deleted'],
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        deleteDistributionPeriod() {
            if(!confirm("Are you sure? This will permanently remove the distribution period " + this.period.year + " " + this.period.quarter + ", including all distributions & re-investments from the platform.")) return;
            this.loading = true;
            repository.delete("/distribution-periods/" + this.period.id)
                .then(() => {
                    this.loading = false;
                    this.$emit("deleted", this.period.id);
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.response.data.message);
                })
        },
        convertToReadableDate(date) {
            return readableDate(date, false);
        },
        convertToReadableNumber(value) {
            return readableNumber(value);
        },
    },
}
</script>

<style lang="scss" scoped>
.distribution-period-box {
    display: block;
    background: $white;
    padding: 1.5rem;
    border-radius: $border-radius-large;
    box-shadow: rgba($dark, 0.1) 0 0.25rem 0.5rem;

    &.loading {
        filter: grayscale(1);
        opacity: 0.9;
        pointer-events: none;
        user-select: none;
    }

    .period-year {
        font-size: 1.5rem;
        font-weight: bold;
        color: $dark;
    }

    .period-quarter {
        font-size: 1.5rem;
        font-weight: bold;
        color: $dark;
        margin-left: 0.5rem;
    }

    .period-details {
        display: block;

        .period-details-entry {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 0.5rem;
            margin-top: 0.5rem;
            border-top: 1px solid $grey-100;
            font-size: 0.9rem;

            img.period-admin-avatar {
                width: 1.5rem;
                height: 1.5rem;
                margin-bottom: -0.5rem;
                margin-top: -0.5rem;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }

    .period-amounts {
        display: flex;
        margin-top: 0.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid $grey-100;

        .period-amount-item {
            display: block;
            width: 50%;

            strong {
                display: block;
                color: $primary;
                font-size: 1.25rem;
                line-height: 1.2;
            }

            span {
                display: block;
                color: $dark;
                font-size: 0.9rem;
                line-height: 1.2;
            }
        }
    }

    .period-actions {
        margin-top: 1.5rem;
    }
}
</style>