<template>
    <div class="explanation-icon" data-bs-toggle="tooltip" :title="title">?</div>
</template>

<script>
export default {
    name: "ExplanationIcon",
    props: ['title'],
}
</script>

<style lang="scss" scoped>
.explanation-icon {
    display: inline-block;
    vertical-align: middle;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: rgba($dark, 0.15);
    color: $dark;
    font-weight: bold;
    line-height: 1rem;
    text-align: center;
    font-size: 0.8rem;
    cursor: pointer;
    opacity: 0.45;
    @extend .animated;

    &:hover {
        opacity: 0.75;
    }
}
</style>