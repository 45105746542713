<template>
    <div class="investors-screen">

        <!-- Welcome Message -->
        <h1 class="dashboard-title">Investors Management</h1>

        <!-- Leads Daily Chart Last 6 months -->
        <div class="row mt-3">
            <div class="col-md-8 mb-4">
                <InvestorsDailyChart></InvestorsDailyChart>
            </div>
            <div class="col-md-4 mb-4">
                <InvestorsSummary></InvestorsSummary>
            </div>
        </div>

        <!-- List -->
        <InvestorsList></InvestorsList>

    </div>
</template>

<script>
import InvestorsList from "@/components/accounts/investors/InvestorsList.vue";
import InvestorsSummary from "@/components/statistics/InvestorsSummary.vue";
import InvestorsDailyChart from "@/components/statistics/InvestorsMonthlyChart.vue";

export default {
    name: "InvestorsScreen",
    components: {InvestorsDailyChart, InvestorsSummary, InvestorsList}
}
</script>

<style lang="scss" scoped>

</style>