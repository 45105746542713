<template>
    <div class="impact-details-wrapper">
        <!-- Details -->
        <form @submit.prevent="saveChanges" class="impact-details">
            <div class="form-field edit-field">
                <label class="form-label" for="title">Title</label>
                <input type="text" id="title" class="form-control" placeholder="Impact title" v-model="title" required>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="description">Description</label>
                <input type="text" id="description" class="form-control" placeholder="Impact short description" v-model="description" required>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="value">Value</label>
                <input type="number" id="value" class="form-control" placeholder="Impact total value" v-model="value" required>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="row">
                <div class="col-md-6 mt-4">
                    <div class="form-field edit-field">
                        <label class="form-label" for="prefix">Prefix</label>
                        <input type="text" id="prefix" class="form-control" placeholder="Prefix (optional)" v-model="prefix">
                        <img src="../../assets/icons/edit.svg" alt="Edit">
                    </div>
                </div>
                <div class="col-md-6 mt-4">
                    <div class="form-field edit-field">
                        <label class="form-label" for="suffix">Suffix</label>
                        <input type="text" id="suffix" class="form-control market" placeholder="Suffix (optional)" v-model="suffix">
                        <img src="../../assets/icons/edit.svg" alt="Edit">
                    </div>
                </div>
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="icon">Icon</label>
                <input type="file" id="icon" ref="icon" required @change="previewIcon">
                <img :src="icon" class="impact-icon" alt="">
                <p class="small mt-3">Please upload PNG icon with #F37021 color to match the style of the project</p>
            </div>
            <div class="text-danger fw-bolder mt-4" v-if="savingError">{{ savingError }}</div>
            <div class="d-flex justify-content-between mt-4 pt-3">
                <button type="button" class="btn btn-light" @click.prevent="cancel">Cancel</button>
                <button type="submit" class="btn btn-primary" :disabled="saving">
                    <span v-if="!saving">Save Impact Item</span>
                    <span class="spinner-border" v-if="saving"></span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "NewImpactForm",
    components: {},
    emits: ['reload-impacts'],
    data() {
        return {
            saving: false,
            savingError: false,
            value: null,
            title: null,
            description: null,
            icon: null,
            prefix: null,
            suffix: null,
        }
    },
    created() {
    },
    methods: {
        saveChanges() {
            this.saving = true;

            // Prepare data for saving
            let formData = new FormData();
            formData.append('value', this.value);
            formData.append('title', this.title);
            formData.append('description', this.description);
            formData.append('prefix', this.prefix ?? '');
            formData.append('suffix', this.suffix ?? '');
            formData.append('icon', this.$refs.icon.files[0] ?? '');

            // Load data
            repository.post("/impact", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    console.log(response.data);
                    this.saving = false;
                    this.savingError = false;
                    this.$emit('reload-impacts');
                    history.back();
                })
                .catch(error => {
                    this.saving = false;
                    this.savingError = error.response.data.message;
                    console.log("An error happened while saving the impact item updates", error);
                });
        },
        cancel() {
            history.back();
        },
        previewIcon() {
            this.icon = URL.createObjectURL(this.$refs.icon.files[0]);
        },
    },
}
</script>

<style lang="scss">
.impact-details-wrapper {
    position: relative;

    // Impact Details
    .impact-details {

        .form-field.edit-field {
            position: relative;

            &:focus-within {
                img:not(.impact-icon) {
                    opacity: 0.5;
                }
            }

            .form-control {
                padding-right: 1.75rem;
                z-index: 1;
                background: transparent;
                position: relative;
            }

            img:not(.impact-icon) {
                position: absolute;
                bottom: 0.75rem;
                right: 0;
                z-index: 0;
                width: 1.5rem;
                height: 1.5rem;
                opacity: 0.1;
                @extend .animated;
            }
        }
    }

    .impact-icon {
        width: 6rem;
        height: 6rem;
        padding: 1rem;
        background: $grey-100;
        border-radius: $border-radius-large;
        margin-top: 1rem;
        display: block;
        position: relative;
        opacity: 1;
    }
}
</style>