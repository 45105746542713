<template>
    <div :class="{'dashboard-box fund-summary-box h-100': true, 'loading': !loaded}">
        <h2 class="dashboard-subtitle">Investors Summary</h2>

        <!-- Total Investors -->
        <div class="summary-main-entry">
            <VueCountTo class="summary-number" :autoplay="false" :startVal="0" :endVal="active_investors" :duration="1000" ref="activeInvestors"></VueCountTo>
            <div class="summary-title">Total Active Investors</div>
        </div>

        <!-- Month Investors -->
        <div class="summary-entry">
            <div class="summary-title">Investors last 30 days</div>
            <VueCountTo class="summary-number" :autoplay="false" :startVal="0" :endVal="investors_month" :duration="1000" ref="monthInvestors"></VueCountTo>
        </div>

        <!-- Week Investors -->
        <div class="summary-entry">
            <div class="summary-title">Investors last 7 days</div>
            <VueCountTo class="summary-number" :autoplay="false" :startVal="0" :endVal="investors_week" :duration="1000" ref="weekInvestors"></VueCountTo>
        </div>

        <!-- Today Investors -->
        <div class="summary-entry">
            <div class="summary-title">Investors today</div>
            <VueCountTo class="summary-number" :autoplay="false" :startVal="0" :endVal="investors_day" :duration="1000" ref="dayInvestors"></VueCountTo>
        </div>

        <!-- Inactive Investors -->
        <div class="summary-entry">
            <div class="summary-title">Inactive investors</div>
            <ExplanationIcon class="ms-1" title="These users have completed their onboarding process, but don't have any active investments."></ExplanationIcon>
            <VueCountTo class="summary-number text-danger" :autoplay="false" :startVal="0" :endVal="inactive_investors" :duration="1000" ref="inactiveInvestors"></VueCountTo>
        </div>

    </div>
</template>

<script>
import VueCountTo from 'vue-count-to';
import repository from "@/repository/repository";
import ExplanationIcon from "@/components/widgets/ExplanationIcon.vue";

export default {
    name: "InvestorsSummary",
    components: {ExplanationIcon, VueCountTo },
    data: function () {
        return {
            loaded: false,
            active_investors: 0,
            inactive_investors: 0,
            investors_month: 0,
            investors_week: 0,
            investors_day: 0,
        }
    },
    created() {
        this.getSummary();
    },
    methods: {
        getSummary() {
            this.loaded = false;
            repository.get("/investors/summary")
                .then(response => {
                    this.active_investors = response.data.active_investors;
                    this.inactive_investors = response.data.inactive_investors;
                    this.investors_month = response.data.investors_month;
                    this.investors_week = response.data.investors_week;
                    this.investors_day = response.data.investors_day;
                    this.loaded = true;
                    this.$refs.activeInvestors.start();
                    this.$refs.inactiveInvestors.start();
                    this.$refs.monthInvestors.start();
                    this.$refs.weekInvestors.start();
                    this.$refs.dayInvestors.start();
                })
                .catch(error => {
                    console.log("Error while fetching investors summary data", error);
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.fund-summary-box {

    // Main Entry
    .summary-main-entry {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $grey-200;
        @extend .animated;

        .summary-number {
            font-size: 2rem;
            font-weight: bold;
            color: $primary;
        }

        .summary-title {
            display: block;
            color: rgba($dark, 0.5);
            font-weight: 600;
            font-size: 0.8rem;

            &.fw-bolder {
                color: $dark;
            }
        }
    }

    // Basic Entry
    .summary-entry {
        display: flex;
        align-items: center;
        @extend .animated;

        & + .summary-entry {
            margin-top: 0.5rem;
        }

        .summary-title {
            display: block;
            color: rgba($dark, 0.5);
            font-weight: 600;
            font-size: 0.8rem;
        }

        .summary-number {
            color: $dark;
            font-weight: 600;
            margin-left: auto;
            margin-right: 0.5rem;
        }
    }

    // Loading
    &.loading {
        @extend .disable-clicks;
        @extend .disable-selection;

        .summary-main-entry {
            border-radius: $border-radius-large;
            border-bottom-color: transparent;
            background: rgba($dark, 0.1);

            .summary-title, .summary-number {
                color: transparent;
            }
        }

        .summary-entry {
            border-radius: $border-radius-medium;
            border-bottom-color: transparent;
            background: rgba($dark, 0.05);

            * {
                opacity: 0;
            }

            .summary-title, .summary-number {
                color: transparent;
            }
        }
    }
}
</style>