<template>
    <div class="admin-details-wrapper">

        <!-- Details -->
        <form @submit.prevent="save" class="admin-details">
            <div class="form-field edit-field">
                <label class="form-label" for="name">Full Name</label>
                <input type="text" id="name" class="form-control" placeholder="Enter first and last name" v-model="name" required>
                <img src="@/assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="email">Email Address</label>
                <input type="email" id="email" class="form-control" placeholder="Enter email address" v-model="email" required>
                <img src="@/assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="password">Password</label>
                <input type="text" id="password" class="form-control" placeholder="Enter password" v-model="password" required>
                <img src="@/assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="avatar">Avatar</label>
                <input type="file" id="avatar" ref="avatar" @change="updateAvatar" required>
                <img src="@/assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="avatar-preview" v-if="avatar">
                <img :src="avatarPreview" alt="">
            </div>
            <div class="text-danger fw-bolder mt-4" v-if="savingError">{{ savingError }}</div>
            <div class="d-flex justify-content-between mt-4 pt-3">
                <button type="button" class="btn btn-light" @click.prevent="cancelChanges">Cancel</button>
                <button type="submit" class="btn btn-primary" :disabled="saving">
                    <span :class="{'opacity-0': saving}">Save Admin</span>
                    <span class="spinner-border" v-if="saving"></span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import {generateRandomString} from "@/helpers";

export default {
    name: "NewAdminForm",
    emits: ['reload-admins'],
    data() {
        return {
            saving: false,
            savingError: false,
            name: null,
            email: null,
            password: null,
            avatar: null,
            avatarPreview: null,
        }
    },
    created() {
        this.password = generateRandomString(10);
    },
    methods: {
        save() {
            this.savingError = false;

            // Verify images
            if(!this.verifyFields()) {
                return false;
            }

            // Start saving process
            console.log("Saving changes..");
            this.saving = true;

            // Prepare form data
            let formData = new FormData();
            formData.append('name', this.name);
            formData.append('email', this.email);
            formData.append('password', this.password);
            formData.append('avatar', this.$refs.avatar.files[0]);

            // Load data
            repository.post("/admins", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    console.log(response.data);
                    this.saving = false;
                    this.savingError = false;
                    this.$emit('reload-admins');
                    history.back();
                })
                .catch(error => {
                    this.saving = false;
                    this.savingError = error.response.data.message;
                    console.log("An error happened while saving the admin", error);
                });
        },
        cancelChanges() {
            history.back();
        },
        updateAvatar() {
            if(this.$refs.avatar.files.length === 0) return null;
            this.avatarPreview = URL.createObjectURL(this.$refs.avatar.files[0]);
            this.avatar = this.$refs.avatar.files[0];
        },
        verifyFields() {
            if(!this.name) {
                this.savingError = "Please enter full name";
                return false;
            }
            if(!this.email) {
                this.savingError = "Please enter email address";
                return false;
            }
            if(!this.password) {
                this.savingError = "Please enter password";
                return false;
            }
            if(!this.$refs.avatar.files[0]) {
                this.savingError = "Please upload an avatar";
                return false;
            }
            return true;
        },
    },
}
</script>

<style lang="scss" scoped>
.admin-details-wrapper {
    position: relative;

    // Loading Effect
    > .spinner-border {
        opacity: 0.25;
        margin: 2.5rem auto;
        display: block;
    }

    // Error Effect
    .error-message {
        padding: 2.5rem 5rem;
        text-align: center;

        img {
            width: 8rem;
            height: 8rem;
            display: block;
            margin: auto;
        }

        h4 {
            font-size: 1.25rem;
            font-weight: bold;
            color: $danger;
            margin: 1.5rem 0 0.5rem 0;
        }
    }

    // Admin Details
    .admin-details {

        .form-field.edit-field {
            position: relative;

            &:focus-within {
                img {
                    opacity: 0.5;
                }
            }

            .form-control {
                padding-right: 1.75rem;
                z-index: 1;
                background: transparent;
                position: relative;
            }

            img {
                position: absolute;
                bottom: 0.75rem;
                right: 0;
                z-index: 0;
                width: 1.5rem;
                height: 1.5rem;
                opacity: 0.1;
                @extend .animated;
            }
        }

        .avatar-preview {
            margin-top: 1rem;

            img {
                width: 4rem;
                height: 4rem;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }
}
</style>