<template>
    <div :class="{'dashboard-box fund-summary-box h-100': true, 'loading': !fund.loaded}">
        <h2 class="dashboard-subtitle">Investments Summary</h2>

        <!-- Transactions Summary Chart -->
        <TransactionsSummaryChart></TransactionsSummaryChart>

        <!-- Funded Investments -->
        <div class="summary-entry">
            <div class="summary-title">Funded Investments</div>
            <VueCountTo class="summary-number fw-bolder text-brand" :autoplay="false" :startVal="0" :endVal="fund.total_funded" :duration="1000" ref="totalFunded" prefix="$"></VueCountTo>
            <ExplanationIcon title="These funds are available at your escrow account."></ExplanationIcon>
        </div>

        <!-- Pending Investments -->
        <div class="summary-entry">
            <div class="summary-title">Pending Investments</div>
            <VueCountTo class="summary-number" :autoplay="false" :startVal="0" :endVal="fund.total_pending" :duration="1000" ref="totalPending" prefix="$"></VueCountTo>
            <ExplanationIcon title="The payments for these investments have been initiated, but the funds still haven't arrived."></ExplanationIcon>
        </div>

        <!-- Incomplete Investments -->
        <div class="summary-entry">
            <div class="summary-title">Incomplete Investments</div>
            <VueCountTo class="summary-number opacity-25" :autoplay="false" :startVal="0" :endVal="fund.total_incomplete" :duration="1000" ref="totalIncomplete" prefix="$"></VueCountTo>
            <ExplanationIcon title="These investments have never been initiated. Most likely the investors didn't complete their onboarding process."></ExplanationIcon>
        </div>

        <!-- Cancelled Investments -->
        <div class="summary-entry">
            <div class="summary-title">Cancelled Investments</div>
            <VueCountTo class="summary-number text-danger" :autoplay="false" :startVal="0" :endVal="fund.total_cancelled" :duration="1000" ref="totalCancelled" prefix="$"></VueCountTo>
            <ExplanationIcon title="These investments have been cancelled."></ExplanationIcon>
        </div>
    </div>
</template>

<script>
import VueCountTo from 'vue-count-to';
import repository from "@/repository/repository";
import ExplanationIcon from "@/components/widgets/ExplanationIcon.vue";
import TransactionsSummaryChart from "@/components/statistics/TransactionsSummaryChart.vue";

export default {
    name: "InvestmentsSummary",
    components: {TransactionsSummaryChart, ExplanationIcon, VueCountTo },
    data: function () {
        return {
            fund: {
                loaded: false,
                total_funded: 0,
                total_pending: 0,
                total_cancelled: 0,
                total_incomplete: 0,
            },
        }
    },
    created() {
        this.getFundSummary();
    },
    methods: {
        getFundSummary() {
            this.fund.loaded = false;
            repository.get("/dashboard/stats/fund-summary")
                .then(response => {
                    this.fund.total_funded = response.data.total_funded;
                    this.fund.total_pending = response.data.total_pending;
                    this.fund.total_cancelled = response.data.total_cancelled;
                    this.fund.total_incomplete = response.data.total_incomplete;
                    this.fund.loaded = true;
                    this.$refs.totalFunded.start();
                    this.$refs.totalPending.start();
                    this.$refs.totalIncomplete.start();
                    this.$refs.totalCancelled.start();
                })
                .catch(error => {
                    console.log("Error while fetching Fund Summary Data", error);
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.fund-summary-box {

    // Main Entry
    .summary-main-entry {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $grey-200;
        @extend .animated;

        .summary-number {
            font-size: 2rem;
            font-weight: bold;
            color: $primary;
        }

        .summary-title {
            display: block;
            color: rgba($dark, 0.5);
            font-weight: 600;
            font-size: 0.8rem;

            &.fw-bolder {
                color: $dark;
            }
        }
    }

    // Basic Entry
    .summary-entry {
        display: flex;
        align-items: center;
        @extend .animated;

        & + .summary-entry {
            margin-top: 0.5rem;
        }

        .summary-title {
            display: block;
            color: rgba($dark, 0.5);
            font-weight: 600;
            font-size: 0.8rem;
        }

        .summary-number {
            color: $dark;
            font-weight: 600;
            margin-left: auto;
            margin-right: 0.5rem;
        }
    }

    // Loading
    &.loading {
        @extend .disable-clicks;
        @extend .disable-selection;

        .summary-main-entry {
            border-radius: $border-radius-large;
            border-bottom-color: transparent;
            background: rgba($dark, 0.1);

            .summary-title, .summary-number {
                color: transparent;
            }
        }

        .summary-entry {
            border-radius: $border-radius-medium;
            border-bottom-color: transparent;
            background: rgba($dark, 0.05);

            * {
                opacity: 0;
            }

            .summary-title, .summary-number {
                color: transparent;
            }
        }
    }
}
</style>