<template>
    <div class="transactions-summary-chart mb-4">
        <VueApexCharts type="donut" height="300" :options="areaChartOptions" :series="areaSeries" v-if="loaded"></VueApexCharts>
        <div class="spinner-border" v-if="!loaded"></div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import repository from "@/repository/repository";

export default {
    name: "TransactionsSummaryChart",
    components: { VueApexCharts },
    data() {
        return {
            loaded: false,
            total_funded: 0,
            total_pending: 0,
            total_cancelled: 0,
            total_incomplete: 0,
        }
    },
    created() {
        this.getTransactionsSummary();
    },
    methods: {
        getTransactionsSummary() {
            this.loaded = false;
            repository.get("/investments/charts/summary")
                .then(response => {
                    this.total_funded = response.data.total_funded;
                    this.total_pending = response.data.total_pending;
                    this.total_cancelled = response.data.total_cancelled;
                    this.total_incomplete = response.data.total_incomplete;
                    this.loaded = true;
                })
                .catch(error => {
                    console.log("Error while fetching Transactions Summary Data", error);
                })
        },
    },
    computed: {
        areaSeries() {
            if(!this.loaded) return [];
            return [
                this.total_funded,
                this.total_pending,
                this.total_incomplete,
                this.total_cancelled,
            ];
        },
        areaChartOptions() {
            return {
                chart: {
                    height: 300,
                    type: 'donut',
                },
                legend: {
                    position: 'right'
                },
                labels: ['Funded', 'Pending', 'Incomplete', 'Cancelled'],
                colors: ['#1FAE5D', '#16193A', '#AAAAAA', '#D64933'],
                plotOptions: {
                    pie: {
                        donut: {
                            size: '45%',
                        },
                        dataLabels: {
                            dropShadow: {
                                enabled: false,
                            },
                        },
                    },
                },
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.transactions-summary-chart {
    height: 200px;
    position: relative;

    .spinner-border {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
        border-width: 2px;
        border-color: $border-grey;
        border-right-color: transparent;
    }
}
</style>