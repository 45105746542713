<template>
    <div class="row mt-3">
        <div class="col-md-6 col-lg-3 mb-4">
            <div :class="{'dashboard-box stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="Average investment amount by the investors. This is the total invested amount divided by the total investors in the system.">
                <VueCountTo class="stats-box-number" :autoplay="false" :startVal="0" :endVal="averageInvestedPerInvestor" :duration="1000" ref="averageInvestedPerInvestor" prefix="$"></VueCountTo>
                <span class="fw-bolder">Average investment</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <div :class="{'dashboard-box stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="The total amount that has been invested & funded in your escrow account.">
                <VueCountTo class="stats-box-number" :autoplay="false" :startVal="0" :endVal="totalInvested" :duration="1000" ref="totalInvested" prefix="$"></VueCountTo>
                <span class="fw-bolder">Total invested</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <div :class="{'dashboard-box stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="Total users that have completed their onboarding process.">
                <VueCountTo class="stats-box-number" :autoplay="false" :startVal="0" :endVal="total_investors" :duration="1000" ref="totalUsers"></VueCountTo>
                <span class="fw-bolder">Total investors</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-4">
            <div :class="{'dashboard-box stats-box': true, 'loading': !loaded }" data-bs-toggle="tooltip" title="The percentage rate of how many users converted to investors.">
                <VueCountTo :class="{'stats-box-number': true, 'text-brand': loaded}" :autoplay="false" :startVal="0" :endVal="conversionRate" :duration="1000" ref="conversionRate" suffix="%"></VueCountTo>
                <span class="fw-bolder">Conversion rate</span>
                <div class="spinner-border" v-if="!loaded"></div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCountTo from 'vue-count-to';
import repository from "@/repository/repository";

export default {
    name: "AdvancedStatistics",
    components: { VueCountTo },
    data: function () {
        return {
            total_funded: null,
            total_investors: null,
            total_leads: null,
            loaded: false,
        }
    },
    created() {
        this.getStatistics();
    },
    methods: {
        getStatistics() {
            this.loaded = false;
            repository.get("/dashboard/stats/fund-summary")
                .then(response => {
                    this.total_funded = response.data.total_funded;

                    repository.get("/dashboard/stats/users-summary")
                        .then(response => {
                            this.total_investors = response.data.active_investors + response.data.inactive_investors;
                            this.total_leads = response.data.total_leads;
                            this.$refs.averageInvestedPerInvestor.start();
                            this.$refs.totalUsers.start();
                            this.$refs.conversionRate.start();
                            this.$refs.totalInvested.start();
                            this.loaded = true;
                        })
                        .catch(error => {
                            console.log("Error while fetching users summary data", error);
                        })
                })
                .catch(error => {
                    console.log("Error while fetching fund summary data", error);
                })
        },
    },
    computed: {
        averageInvestedPerInvestor() {
            return this.total_funded / this.total_investors;
        },
        averageUserValue() {
            return this.total_funded / (this.total_investors + this.total_leads);
        },
        conversionRate() {
            return (100 / (this.total_investors + this.total_leads)) * this.total_investors;
        },
        totalInvested() {
            return this.total_funded;
        }
    },
}
</script>

<style lang="scss" scoped>
.stats-box {
    .stats-box-number {
        font-weight: bold;
        display: block;
        font-size: 2rem;
        color: $dark;
    }

    span {
        display: block;
        color: rgba($dark, 0.5);
        font-weight: 600;
        font-size: 0.8rem;

        &.fw-bolder {
            color: $dark;
        }
    }

    &.loading {
        position: relative;
        @extend .disable-clicks;
        @extend .disable-selection;

        .stats-box-number {
            color: transparent;
            @extend .disable-clicks;
            @extend .disable-selection;
        }

        span {
            color: transparent;
            @extend .disable-clicks;
            @extend .disable-selection;
        }

        .spinner-border {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.25;
            margin: auto;
        }
    }
}
</style>