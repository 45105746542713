<template>
    <div class="lead-item" @click.prevent="openLeadPopup(lead)">
        <div :class="getAvatar(lead)">{{ lead.credentials }}</div>
        <div class="lead-info">
            <strong>{{ fullName }}</strong>
            <span>{{ lead.email }}</span>
        </div>
        <div class="lead-joined">{{ createdAt }}</div>
    </div>
</template>

<script>
import {generateAvatarClass, ucwords} from "@/helpers";
import moment from "moment-timezone";

export default {
    name: "LeadsListItem",
    components: {},
    props: [ 'lead' ],
    methods: {
        getAvatar(user) {
            return "lead-credentials " + generateAvatarClass(user);
        },
        openLeadPopup(user) {
            console.log("Open lead popup", user);
            this.$root.$emit("openLeadPopup", user.id);
        },
    },
    computed: {
        fullName() {
            return ucwords(this.lead.name);
        },
        createdAt() {
            if (this.lead.created_at === null) return "Error..";
            let today = moment().tz('UTC');
            let accessedDate = moment(this.lead.created_at).tz('UTC');
            let difference = moment.duration(today.diff(accessedDate));
            let returnString = "";
            if (difference.months() === 1) returnString += difference.months() + " month";
            else if (difference.months() > 1) returnString += difference.months() + " months";
            else if (difference.days() === 1) returnString += difference.days() + " day";
            else if (difference.days() > 1) returnString += difference.days() + " days";
            else if (difference.hours() === 1) returnString += difference.hours() + " hour";
            else if (difference.hours() > 1) returnString += difference.hours() + " hours";
            else if (difference.minutes() === 1) returnString += difference.minutes() + " minute";
            else if (difference.minutes() > 1) returnString += difference.minutes() + " minutes";
            else if (difference.seconds() === 1) returnString += difference.seconds() + " second";
            else if (difference.seconds() > 1) returnString += difference.seconds() + " seconds";
            return returnString === "" ? "Just now" : returnString + " ago";
        },
    },
}
</script>

<style lang="scss" scoped>
.lead-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin: 0 -0.5rem;
    border-radius: $border-radius-large;
    cursor: pointer;
    @extend .animated;

    & + .lead-item {
        position: relative;

        &:before {
            position: absolute;
            top: 0;
            left: 0.5rem;
            width: calc(100% - 1rem);
            height: 1px;
            background: $grey-200;
            content: ' ';
        }
    }

    &:hover {
        background: $grey-200;
    }

    &:active {
        background: darken($grey-200, 5%);
    }

    .lead-credentials {
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        border-radius: 50%;
        line-height: 2.5rem;
        font-size: 0.8rem;
        font-weight: bold;
        flex-shrink: 0;
        @extend .disable-selection;
    }

    .lead-info {
        padding: 0 1rem;

        strong {
            display: block;
            font-size: 0.9rem;
        }

        span {
            display: block;
            color: rgba($dark, 0.75);
            font-size: 0.8rem;
        }
    }

    .lead-joined {
        margin-left: auto;
        font-size: 0.8rem;
        color: rgba($dark, 0.5);
        @extend .disable-selection;
    }
}
</style>