<template>
    <div class="impact-item">
        <img :src="impact.icon" alt="" class="impact-icon">
        <div class="impact-info">
            <div class="impact-value">{{ impact ? (impact.prefix ? impact.prefix : '') + impact.value.toLocaleString() + (impact.suffix ? impact.suffix : '') : '0,00' }}</div>
            <div class="impact-title">{{ impact.title }}</div>
            <div class="impact-description">{{ impact.description }}</div>
        </div>
        <div class="impact-actions">
            <div @click.prevent="$emit('details', impact.id)" class="btn btn-circle" data-bs-toggle="tooltip" title="View & Edit">
                <img src="../../assets/icons/settings.svg" alt="View & Edit" class="btn-icon">
            </div>
            <div @click.prevent="deleteImpact" class="btn btn-circle" data-bs-toggle="tooltip" title="Delete">
                <img src="../../assets/icons/trash.svg" alt="Trash" class="btn-icon">
            </div>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "ImpactItem",
    props: ['impact',],
    data() {
        return {
            loading: false,
        }
    },
    emits: ['details', 'deleted'],
    methods: {
        deleteImpact() {
            if(!confirm("Are you sure? This will permanently remove the impact item from the platform.")) return;
            this.loading = true;
            repository.delete("/impact/" + this.impact.id)
                .then(() => {
                    this.$emit("deleted", this.impact.id);
                })
                .catch(error => {
                    console.log(error.response.data.message);
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.impact-item {
    display: flex;
    align-items: flex-start;

    & + .impact-item {
        margin-top: 0.75rem;
        padding-top: 0.75rem;
        border-top: 1px solid $grey-100;
    }

    > img {
        width: 3rem;
        height: 3rem;
        flex-shrink: 0;
        object-fit: contain;
    }

    .impact-info {
        padding-left: 1rem;

        .impact-value {
            font-weight: bold;
            color: $primary;
        }

        .impact-title {
            font-weight: bold;
            color: $dark;
        }

        .impact-description {
            font-size: 0.9rem;
            color: rgba($dark, 0.5);
        }
    }

    .impact-actions {
        margin-left: auto;
        opacity: 0;
        @extend .animated;

        .btn-circle {
            width: 3rem;
            height: 3rem;
            background: rgba($dark, 0.05);
            border: none;
            padding: 0;
            text-align: center;
            line-height: 3rem;
            border-radius: 50%;
            margin-left: 0.5rem;
            @extend .animated;

            &:hover,
            &:focus {
                background: rgba($dark, 0.1);
                border-color: transparent;
                outline: none;
                box-shadow: none;
            }

            &:active {
                background: rgba($dark, 0.15);
                border-color: transparent;
                box-shadow: none;
                outline: none;
                transform-origin: center;
                transform: scale(0.925);
            }

            img.btn-icon {
                margin: 0;
            }
        }
    }

    &:hover {
        .impact-actions {
            opacity: 1;
        }
    }
}
</style>