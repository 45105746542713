<template>
    <div class="investor-details-wrapper">

        <!-- Lead Basic Details -->
        <div :class="{'investor-details': true, 'in-popup': investorId, 'not-popup': !investorId}" v-if="loaded && !error">

            <!-- Name -->
            <div class="investor-name">
                <div :class="getAvatar(email)">{{ credentials }}</div>
                <strong>{{ name }}</strong>
            </div>

            <div class="row">
                <div class="col-md-12">

                    <!-- Contact Details -->
                    <div class="investor-contact-details">
                        <h4>Contact Details</h4>
                        <p>Basic contact details for this investor:</p>
                        <div class="investor-contact">
                            <strong>Email</strong>
                            <span>{{ email }}</span>
                        </div>
                        <div class="investor-contact">
                            <strong>Phone</strong>
                            <span>{{ phone }}</span>
                        </div>
                        <div class="investor-contact">
                            <strong>State</strong>
                            <span>{{ fullStateName }}</span>
                        </div>
                        <div class="investor-contact" v-if="joinedDate">
                            <strong>Joined</strong>
                            <span>{{ joinedDate }}</span>
                        </div>
                        <div class="investor-contact" v-if="id">
                            <strong>Investor ID</strong>
                            <span>#{{ id }}</span>
                        </div>
                    </div>

                </div>
                <div class="col-md-12 mt-4">

                    <!-- Investments -->
                    <InvestorInvestmentsTransactionsSummary :investments="investments" :in-popup="isInPopup"></InvestorInvestmentsTransactionsSummary>

                </div>
                <div class="col-md-12 mt-4">

                    <!-- Refunds -->
                    <InvestorRefundsSummary :refunds="refunds" :in-popup="isInPopup"></InvestorRefundsSummary>

                </div>
                <div class="col-md-12 mt-4">

                    <!-- Distributions -->
                    <InvestorDistributionsSummary :distributions="distributions" :in-popup="isInPopup"></InvestorDistributionsSummary>

                </div>
                <div class="col-md-12 mt-4" v-if="accountId">

                    <!-- Account Details -->
                    <InvestorAccountDetails :investor-id="id" :in-popup="isInPopup"></InvestorAccountDetails>

                </div>
                <div class="col-md-12 mt-4" v-if="partyId">

                    <!-- Party Details -->
                    <InvestorPartyDetails :investor-id="id" :in-popup="isInPopup"></InvestorPartyDetails>

                </div>
                <div class="col-md-12 mt-4" v-if="entityId">

                    <!-- Entity Details -->
                    <InvestorEntityDetails :investor-id="id" :in-popup="isInPopup"></InvestorEntityDetails>

                </div>
            </div>

            <!-- Change Email -->
            <UpdateUserEmailForm :user-id="id" :in-popup="isInPopup"></UpdateUserEmailForm>

            <!-- Change Password -->
            <UpdateUserPasswordForm :user-id="id" :in-popup="isInPopup"></UpdateUserPasswordForm>

            <!-- Access Account -->
            <AccessAccountForm :user-id="id" :in-popup="isInPopup"></AccessAccountForm>

        </div>

        <!-- Error -->
        <div class="error-message" v-if="loaded && error">
            <img src="../../../assets/icons/error.svg" alt="Error">
            <h4>Investor not found</h4>
            <p>We couldn't find an investor with the provided ID. The ID might be incorrect, or the investor didn't complete their onboarding process.</p>
        </div>

        <!-- Loading Effect -->
        <div class="spinner-border" v-if="!loaded"></div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import {generateAvatarClass, getState} from "@/helpers";
import moment from "moment-timezone";
import InvestorAccountDetails from "@/components/accounts/investors/InvestorAccountDetails.vue";
import InvestorPartyDetails from "@/components/accounts/investors/InvestorPartyDetails.vue";
import InvestorInvestmentsTransactionsSummary from "@/components/accounts/investors/InvestorInvestmentsTransactionsSummary.vue";
import UpdateUserEmailForm from "@/components/accounts/UpdateUserEmailForm.vue";
import InvestorEntityDetails from "@/components/accounts/investors/InvestorEntityDetails.vue";
import UpdateUserPasswordForm from "@/components/accounts/UpdateUserPasswordForm.vue";
import AccessAccountForm from "@/components/accounts/investors/AccessAccountForm.vue";
import InvestorDistributionsSummary from "@/components/accounts/investors/InvestorDistributionsSummary.vue";
import InvestorRefundsSummary from "@/components/accounts/investors/InvestorRefundsSummary.vue";

export default {
    name: "InvestorDetails",
    components: {
        InvestorRefundsSummary,
        InvestorDistributionsSummary,
        AccessAccountForm,
        UpdateUserPasswordForm,
        InvestorEntityDetails,
        UpdateUserEmailForm,
        InvestorInvestmentsTransactionsSummary,
        InvestorPartyDetails,
        InvestorAccountDetails,
    },
    props: ['investorId'],
    data() {
        return {
            id: null,
            name: null,
            email: null,
            phone: null,
            state: null,
            accountId: null,
            partyId: null,
            entityId: null,
            linkId: null,
            autoInvestmentPeriod: null,
            autoInvestmentStartDay: null,
            autoInvestmentAmount: null,
            bankAccountConnected: null,
            dividendReinvestment: null,
            verificationCode: null,
            verified: null,
            createdAt: null,
            kycDocumentUploaded: null,
            investments: [],
            distributions: [],
            refunds: [],
            credentials: null,
            joined: null,
            loaded: false,
            error: false,
        }
    },
    created() {
        this.loadDetails(this.$route.params.id ?? this.investorId);
    },
    methods: {
        loadDetails(id) {
            if(!id) return null;

            // Reset data
            this.id = id;
            this.loaded = false;
            this.error = false;

            // Load data
            repository.get("/investors/" + id)
                .then(response => {
                    console.log(response.data);
                    this.name = response.data.investor.name;
                    this.email = response.data.investor.email;
                    this.phone = response.data.investor.phone;
                    this.state = response.data.investor.state;
                    this.accountId = response.data.investor.account_id;
                    this.partyId = response.data.investor.party_id;
                    this.entityId = response.data.investor.entity_id;
                    this.linkId = response.data.investor.link_id;
                    this.autoInvestmentPeriod = response.data.investor.auto_investment_period;
                    this.autoInvestmentStartDay = response.data.investor.auto_investment_start_day;
                    this.autoInvestmentAmount = response.data.investor.auto_investment_amount;
                    this.bankAccountConnected = response.data.investor.bank_account_connected;
                    this.dividendReinvestment = response.data.investor.dividend_reinvestment;
                    this.verificationCode = response.data.investor.verification_code;
                    this.verified = response.data.investor.verified;
                    this.createdAt = response.data.investor.created_at;
                    this.kycDocumentUploaded = response.data.investor.kyc_document_uploaded;
                    this.credentials = response.data.investor.credentials;
                    this.joined = response.data.investor.joined;
                    this.investments = response.data.investor.investments;
                    this.distributions = response.data.investor.distributions;
                    this.refunds = response.data.investor.refunds;
                    this.loaded = true;
                    this.error = false;
                })
                .catch(error => {
                    this.loaded = true;
                    this.error = true;
                    console.log("Lead not found", error);
                });
        },
        getAvatar(email) {
            return "investor-credentials " + generateAvatarClass({
                email: email,
            });
        },
        copyValue(value) {
            this.$copyText(value);
        },
    },
    computed: {
        fullStateName() {
            if(this.state === null) return null;
            return getState(this.state);
        },
        investmentAmount() {
            if(!this.loaded) return 0;
            let total = 0;
            this.investments.forEach(investment => {
                total += investment.amount;
            });
            return total.toLocaleString();
        },
        autoInvestment() {
            if(!this.loaded) return null;
            if(this.autoInvestmentPeriod === null) return null;
            if(this.autoInvestmentPeriod === "Disabled") return this.autoInvestmentPeriod;
            return this.autoInvestmentPeriod + " - $" + this.autoInvestmentAmount.toLocaleString();
        },
        joinedDate() {
            if(!this.loaded) return null;
            return moment(this.createdAt).format('MMMM Do YYYY, h:mm A');
        },
        isInPopup() {
            return this.investorId;
        },
    },
    watch: {
        investorId(newId) {
            this.loadDetails(newId);
        },
        '$route.params.id'(newId) {
            this.loadDetails(newId);
        }
    },
}
</script>

<style lang="scss" scoped>
.investor-details-wrapper {
    position: relative;

    // Loading Effect
    .spinner-border {
        opacity: 0.25;
        margin: 2.5rem auto;
        display: block;
    }

    // Error Effect
    .error-message {
        padding: 2.5rem 5rem;
        text-align: center;

        img {
            width: 8rem;
            height: 8rem;
            display: block;
            margin: auto;
        }

        h4 {
            font-size: 1.25rem;
            font-weight: bold;
            color: $danger;
            margin: 1.5rem 0 0.5rem 0;
        }
    }

    // Details Wrapper Box
    .investor-details {

        .investor-name {
            display: flex;
            align-items: center;
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid $grey-200;

            > div {
                width: 3rem;
                height: 3rem;
                text-align: center;
                line-height: 3rem;
                font-weight: bold;
                text-transform: uppercase;
                border-radius: 50%;
            }

            strong {
                margin-left: 0.5rem;
                display: block;
                font-size: 1.25rem;
                font-weight: bold;
                color: $dark;
            }
        }

        .investor-contact-details {

            h4 {
                margin: 0 0 0.25rem 0;
                font-size: 1rem;
                width: 100%;
                font-weight: bold;
                color: $dark;
            }

            p {
                font-size: 0.9rem;
                color: rgba($dark, 0.5);
                margin: 0 0 0.5rem 0;
            }

            .investor-contact {
                display: flex;
                align-items: flex-start;
                padding: 0.5rem 0;

                & + .investor-contact {
                    border-top: 1px solid $grey-200;
                }

                strong {
                    width: 40%;
                    flex-shrink: 0;
                    flex-grow: 0;
                    font-weight: 400;
                    padding-right: 0.5rem;

                    &:after {
                        content: ':';
                    }
                }

                span {
                    font-weight: 600;
                    color: $dark;
                    margin-left: 0.25rem;
                }

                a {
                    font-weight: 600;
                    color: $dark;
                    margin-left: 0.25rem;
                    position: relative;
                    text-decoration: none;
                    padding-right: 1.25rem;

                    &:hover {
                        color: $primary;
                    }

                    img {
                        width: 0.75rem;
                        height: 0.75rem;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        opacity: 0.25;
                    }
                }
            }

        }

        &.in-popup {
            .investor-contact-details {
                background: rgba($dark, 0.025);
                padding: 1.5rem;
                border-radius: $border-radius-large;

                &.investments-box {
                    background: $white;
                    box-shadow: rgba($dark, 0.1) 0 0.25rem 0.5rem;
                }
            }
        }

        &.not-popup {
            .investor-name {
                margin-bottom: 0;
            }

            .investor-contact-details {
                display: block;
                position: relative;
                padding: 1.5rem;
                border-radius: $border-radius-large;
                box-shadow: rgba($dark, 0.05) 0 0.5rem 2rem;
                background: $white;
                text-decoration: none;
                color: inherit;
            }
        }
    }
}
</style>