<template>
    <div class="investment-details-wrapper">
        <!-- Investor -->
        <a :href="userLink" target="_blank" class="investor-name">
            <div :class="getAvatar(investment.user.email)">{{ investment.user.credentials }}</div>
            <strong>{{ investment.user.name }}</strong>
            <img src="@/assets/icons/external-link.svg" alt="External Link">
        </a>

        <!-- Transaction Details -->
        <div class="investment-details mt-4">
            <h4>Transaction Details</h4>
            <p>Details about this transaction</p>
            <div class="details-item">
                <strong>Amount</strong>
                <span class="fw-bolder text-brand">${{ investment.amount.toLocaleString() }}</span>
            </div>
            <div class="details-item">
                <strong>Status</strong>
                <span :class="{'text-danger': investment.payment_status === 'cancelled', 'text-success fw-bolder': investment.payment_status === 'funded'}">
                    {{ capitalize(investment.payment_status) }}
                    <ExplanationIcon title="The payment for this investment was initiated, but still hasn't arrived in your escrow account." v-if="investment.payment_status === 'pending'"/>
                    <ExplanationIcon title="The payment for this investment was never initiated." v-if="investment.payment_status === 'incomplete'"/>
                </span>
            </div>
            <div class="details-item">
                <strong>Investment ID</strong>
                <span>#{{ investment.id }}</span>
            </div>
            <div class="details-item" v-if="investment.trade_id">
                <strong>Trade ID</strong>
                <a href="https://api.norcapsecurities.com/admin_v3/client/trades" target="_blank" @click="copyValue(investment.trade_id)">
                    {{ investment.trade_id }}
                    <img src="@/assets/icons/external-link.svg" alt="External Link">
                </a>
            </div>
            <div class="details-item">
                <strong>Payment Method</strong>
                <span>{{ investment.payment_method }}</span>
            </div>
            <div class="details-item">
                <strong>Auto Investment</strong>
                <span>{{ investment.auto_investment === 0 ? 'No' : 'Yes' }}</span>
            </div>
            <div class="details-item">
                <strong>Signed Agreement</strong>
                <span>{{ investment.docu_sign === 0 ? 'No' : 'Yes' }}</span>
            </div>
            <div class="details-item">
                <strong>Date</strong>
                <span>{{ date }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {generateAvatarClass, readableDate, ucwords} from "@/helpers";
import ExplanationIcon from "@/components/widgets/ExplanationIcon.vue";

export default {
    name: "InvestmentDetails",
    components: {ExplanationIcon},
    props: ['investment'],
    data() {
        return {
        }
    },
    methods: {
        getAvatar(user) {
            return "investor-credentials " + generateAvatarClass(user);
        },
        copyValue(value) {
            this.$copyText(value);
        },
        capitalize(value) {
            return ucwords(value);
        },
    },
    computed: {
        userLink() {
            if(this.investment.user.verified === 0) return '/leads/' + this.investment.user.id;
            return '/investors/' + this.investment.user.id;
        },
        date() {
            return readableDate(this.investment.created_at);
        }
    },
}
</script>

<style lang="scss" scoped>
.investment-details-wrapper {

    .investor-name {
        display: inline-flex;
        padding-right: 1.5rem;
        align-items: center;
        text-decoration: none;
        color: inherit;
        position: relative;

        .investor-credentials {
            width: 2.5rem;
            height: 2.5rem;
            text-align: center;
            border-radius: 50%;
            line-height: 2.5rem;
            font-size: 0.8rem;
            font-weight: bold;
            flex-shrink: 0;
            @extend .disable-selection;
        }

        strong {
            font-weight: 700;
            color: $dark;
            margin-left: 0.5rem;
        }

        img {
            width: 0.75rem;
            height: 0.75rem;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            opacity: 0.25;
        }
    }

    .investment-details {
        background: rgba($dark, 0.025);
        padding: 1.5rem;
        border-radius: $border-radius-large;

        h4 {
            margin: 0 0 0.25rem 0;
            font-size: 1rem;
            width: 100%;
            font-weight: bold;
            color: $dark;
        }

        p {
            font-size: 0.9rem;
            color: rgba($dark, 0.5);
            margin: 0 0 0.5rem 0;
        }

        .details-item {
            display: flex;
            align-items: flex-start;
            padding: 0.5rem 0;

            & + .investor-contact {
                border-top: 1px solid $grey-200;
            }

            strong {
                width: 40%;
                flex-shrink: 0;
                flex-grow: 0;
                font-weight: 400;
                padding-right: 0.5rem;

                &:after {
                    content: ':';
                }
            }

            span {
                font-weight: 600;
                color: $dark;
                margin-left: 0.25rem;
            }

            a {
                font-weight: 600;
                color: $dark;
                margin-left: 0.25rem;
                position: relative;
                text-decoration: none;
                padding-right: 1.25rem;

                &:hover {
                    color: $primary;
                }

                img {
                    width: 0.75rem;
                    height: 0.75rem;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    opacity: 0.25;
                }
            }
        }
    }
}
</style>