<template>
    <div class="impact-details-wrapper">
        <!-- Details -->
        <form @submit.prevent="saveChanges" class="impact-details">
            <div class="form-field edit-field">
                <label class="form-label" for="year">Year</label>
                <input type="number" id="year" class="form-control" placeholder="Distribution Period Year" v-model="year" required>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="quarter">Quarter</label>
                <select id="quarter" class="form-control" v-model="quarter" required>
                    <option>Q1</option>
                    <option>Q2</option>
                    <option>Q3</option>
                    <option>Q4</option>
                </select>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="percentage">IRR (Percentage)</label>
                <input type="number" step="0.01" id="percentage" class="form-control" placeholder="Percentage Total Returns" v-model="percentage" required>
                <img src="../../assets/icons/edit.svg" alt="Edit">
            </div>
            <div class="form-field edit-field mt-4">
                <label class="form-label" for="payable_date">Payable Date</label>
                <input type="date" id="payable_date" class="form-control" v-model="payableDate" required>
            </div>
            <div class="text-danger fw-bolder mt-4" v-if="savingError">{{ savingError }}</div>
            <div class="d-flex justify-content-between mt-4 pt-3">
                <button type="button" class="btn btn-light" @click.prevent="cancel">Cancel</button>
                <button type="submit" class="btn btn-primary" :disabled="saving">
                    <span v-if="!saving">Save Distribution Period</span>
                    <span class="spinner-border" v-if="saving"></span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "NewDistributionPeriodForm",
    components: {},
    emits: ['reload-distribution-periods'],
    data() {
        return {
            saving: false,
            savingError: false,
            year: null,
            quarter: 'Q1',
            percentage: null,
            payableDate: null,
        }
    },
    created() {
    },
    methods: {
        saveChanges() {
            this.saving = true;

            // Load data
            repository.post("/distribution-periods", {
                year: this.year,
                quarter: this.quarter,
                percentage: this.percentage,
                payable_date: this.payableDate,
            })
                .then(response => {
                    console.log(response.data);
                    this.saving = false;
                    this.savingError = false;
                    this.$emit('reload-distribution-periods');
                    history.back();
                })
                .catch(error => {
                    this.saving = false;
                    this.savingError = error.response.data.message;
                    console.log("An error happened while saving the distribution period", error);
                });
        },
        cancel() {
            history.back();
        },
    },
}
</script>

<style lang="scss">
</style>