<template>
    <div class="dashboard-box">
        <h2 class="dashboard-subtitle">Leads vs. Investors in the last 30 days</h2>
        <div class="dashboard-chart-wrapper">
            <div class="spinner-border" v-if="!loaded"></div>
            <VueApexCharts type="area" height="100%" :options="areaChartOptions" :series="areaSeries" v-if="loaded"></VueApexCharts>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import repository from "@/repository/repository";
export default {
    name: "DashboardLeadsVsInvestorsChart",
    components: { VueApexCharts },
    data: function () {
        return {
            loaded: false,
            chart: {
                leads: [],
                investors: [],
            },
        }
    },
    created() {
        this.getInvestments();
    },
    methods: {
        getInvestments() {
            this.loaded = false;
            repository.get("/dashboard/charts/leads-investors")
                .then(response => {
                    this.chart.leads = response.data.leads;
                    this.chart.investors = response.data.investors;
                    this.loaded = true;
                })
                .catch(error => {
                    console.log("Error while fetching Leads vs Investors Data", error);
                })
        },
        prepareDaily(entries) {
            if (!entries || entries.length === 0) return [];
            return Object.keys(entries).map(key => entries[key]);
        },
    },
    computed: {
        areaSeries() {
            if(!this.loaded) return [];
            let series = [];
            series.push({
                name: "New Leads",
                type: 'line',
                data: this.prepareDaily(this.chart.leads),
            });
            series.push({
                name: "New Investors",
                type: 'area',
                data: this.prepareDaily(this.chart.investors),
            });
            return series;
        },
        areaCategories() {
            if (this.chart.leads === 0) return [];
            return Object.keys(this.chart.leads);
        },
        areaChartOptions() {
            return {
                chart: {
                    type: 'line',
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                },
                fill: {
                    type: 'gradient',
                    colors: ["#16193A", "#F37021"],
                },
                colors: [
                    "#16193A",
                    "#F37021",
                ],
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
                grid: {
                    borderColor: "#eee",
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    labels: {
                        colors: "#aaa",
                    }
                },
                xaxis: {
                    type: 'date',
                    categories: this.areaCategories,
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: [{
                    title: {
                        text: 'New Leads',
                    },
                    max: Math.max(...this.prepareDaily(this.chart.leads)) + 2,
                    tickAmount: 6,
                }, {
                    opposite: true,
                    title: {
                        text: 'New Investors'
                    },
                    max: Math.max(...this.prepareDaily(this.chart.investors)) + 2,
                    tickAmount: 6,
                }],
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.dashboard-chart-wrapper {
    position: relative;
    min-height: 250px;

    .spinner-border {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        opacity: 0.25;
    }
}
</style>