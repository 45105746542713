<template>
    <div class="chart-wrapper">
        <div class="chart-type" :style="{color: this.color}">
            <div class="chart-color" :style="{backgroundColor: this.color}"></div>
            <span class="chart-name">{{ this.name }}</span>
        </div>
        <VueApexCharts type="area" height="150" :options="areaChartOptions" :series="areaSeries"></VueApexCharts>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    name: "DailyLineChart",
    components: { VueApexCharts },
    props: ['entries', 'color', 'name', 'type'],
    methods: {
        prepareDaily(entries) {
            if (!entries || entries.length === 0) return [];
            console.log(Object.keys(entries).map(key => entries[key]));
            return Object.keys(entries).map(key => entries[key]);
        },
    },
    computed: {
        areaSeries() {
            if(!this.entries) return [];
            let series = [];
            series.push({
                name: this.name,
                color: this.color,
                type: this.type,
                data: this.prepareDaily(this.entries),
            });
            return series;
        },
        areaCategories() {
            if (!this.entries || this.entries.length === 0) return [];
            return Object.keys(this.entries);
        },
        areaChartOptions() {
            return {
                chart: {
                    height: 300,
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                },
                fill: {
                    type: 'solid',
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
                grid: {
                    borderColor: "#eee",
                },
                tooltip: {
                    enabled: true,
                    marker: {
                        show: true,
                    },
                    x: {
                        format: 'dd/MM/yy'
                    },
                    fixed: {
                        enabled: true,
                        position: 'topRight',
                        offsetX: 0,
                        offsetY: 0,
                    },
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        console.log(w.globals, series, seriesIndex, dataPointIndex);
                        return `
        <div>
          <div>${w.globals.categoryLabels[dataPointIndex]}</div>
          <strong>$${series[seriesIndex][dataPointIndex].toLocaleString()}</strong>
        </div>
      `;
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    labels: {
                        colors: "#aaa",
                    }
                },
                xaxis: {
                    type: 'date',
                    categories: this.areaCategories,
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    forceNiceScale: true,
                    tickAmount: 4,
                    // logarithmic: true,
                    // logBase: 100,
                    labels: {
                        formatter: function (value) {
                            if (value >= 500000) {
                                return "$" + (value / 1000000).toFixed(1) + "M";
                            }
                            if (value >= 1000) {
                                return "$" + (value / 1000).toFixed(0) + "K";
                            }
                            return "$" + value;
                        },
                    },
                },
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.chart-type {
    display: flex;
    align-items: center;
    margin-left: 0.75rem;
    margin-bottom: -0.5rem;

    .chart-color {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: $border-radius-large;
        margin-right: 0.5rem;
    }

    span {
        font-size: 0.9rem;
        font-weight: 700;
    }
}
</style>