<template>
    <div class="user-details-popup">
        <div class="user-details-header">
            <div :class="getAvatar(user)">{{ user.credentials }}</div>
            <div class="user-info">
                <strong>{{ user.name }}</strong>
                <span>{{ user.email }}</span>
            </div>
        </div>

        <!-- No documents -->
        <p v-if="user.verification_documents.length === 0" class="mt-4">This user did not upload any documents.</p>

        <!-- Pending Review -->
        <div class="user-documents" v-if="pendingDocuments.length > 0">
            <h4>Pending Review</h4>
            <div class="document-item" v-for="(document, key) in pendingDocuments" :key="key">
                <strong class="document-date">{{ readableDate(document.created_at) }}</strong>
                <div class="document-status">
                    <span>{{ document.status }}</span>
                </div>
                <a :href="document.document" target="_blank" class="btn btn-link">View Document</a>
            </div>
            <div class="review-actions">
                <button @click.prevent="approve" :class="{'btn btn-success': true, 'disabled': approving}" :disabled="approving">
                    <span v-if="approving" class="spinner-border"></span>
                    <span :class="{'opacity-0': approving}">Approve</span>
                </button>
                <button @click.prevent="disapprove" :class="{'btn btn-danger': true, 'disabled': disapproving}" :disabled="disapproving">
                    <span v-if="disapproving" class="spinner-border"></span>
                    <span :class="{'opacity-0': disapproving}">Disapprove</span>
                </button>
                <a :href="accountDetailsLink" target="_blank" class="btn btn-light">More Details</a>
            </div>
        </div>

        <!-- Previous Documents -->
        <div class="user-documents" v-if="previousDocuments.length > 0">
            <h4>Previous Documents</h4>
            <div class="document-item" v-for="(document, key) in previousDocuments" :key="key">
                <strong class="document-date">{{ readableDate(document.created_at) }}</strong>
                <div class="document-status" data-bs-toggle="tooltip" data-bs-html="true" :title="document.admin.name + ':<br>' + document.reason">
                    <img v-if="document.admin" :src="document.admin.avatar" :alt="document.admin.name" class="admin-avatar">
                    <span :class="{'fw-bolder': true, 'text-danger': document.status === 'Disapproved', 'text-success': document.status === 'Manually Approved'}">{{ document.status }}</span>
                </div>
                <a :href="document.document" target="_blank" class="btn btn-link">View Document</a>
            </div>
        </div>
    </div>
</template>

<script>
import {generateAvatarClass, readableDate} from "@/helpers";
import repository from "@/repository/repository";

export default {
    name: "VerificationUserDetails",
    components: { },
    props: ['user'],
    data() {
        return {
            approving: false,
            disapproving: false,
            error: false,
        }
    },
    methods: {
        readableDate,
        getAvatar(user) {
            return "user-credentials " + generateAvatarClass(user);
        },
        disapprove() {
            let reason = prompt("What is the reason for the disapproval?");
            if(!reason || reason.trim().length === 0) {
                alert("Please enter the reason for the disapproval.");
                return null;
            }
            this.disapproving = true;
            this.error = false;
            repository.post("/verifications/" + this.user.id + "/disapprove", {
                reason: reason
            })
                .then(() => {
                    this.disapproving = false;
                    this.$root.$emit("verificationUserDisapproved", this.user);
                    history.back();
                })
                .catch(error => {
                    this.error = error.response.data.message;
                })
        },
        approve() {
            if(!confirm("Are you sure? By approving their documents, you confirm that their details are correct.")) {
                return null;
            }
            this.approving = true;
            this.error = false;
            repository.post("/verifications/" + this.user.id + "/approve")
                .then(() => {
                    this.approving = false;
                    this.$root.$emit("verificationUserApproved", this.user);
                    history.back();
                })
                .catch(error => {
                    this.error = error.response.data.message;
                })
        },
    },
    computed: {
        pendingDocuments() {
            if(!this.user || !this.user.verification_documents) return [];
            return this.user.verification_documents.filter(document => document.status === 'Pending');
        },
        previousDocuments() {
            if(!this.user || !this.user.verification_documents) return [];
            return this.user.verification_documents.filter(document => document.status !== 'Pending');
        },
        accountDetailsLink() {
            if(!this.user) return null;
            if(this.user.verified) return "/investors/" + this.user.id;
            return "/leads/" + this.user.id;
        },
    },
}
</script>

<style lang="scss" scoped>
.user-details-popup {
    .user-details-header {
        display: flex;
        align-items: center;

        .user-credentials {
            width: 3.5rem;
            height: 3.5rem;
            text-align: center;
            border-radius: 50%;
            line-height: 3.5rem;
            font-size: 1.25rem;
            font-weight: bold;
            flex-shrink: 0;
            @extend .disable-selection;
        }

        .user-info {
            padding: 0 1rem;

            strong {
                display: block;
                font-size: 1.25rem;
                color: $dark;
            }

            span {
                display: block;
                color: rgba($dark, 0.75);
                font-size: 0.8rem;
            }
        }
    }

    .review-actions {
        display: flex;
        justify-content: stretch;
        width: 100%;
        margin-top: 1rem;

        .btn {
            flex-grow: 1;
        }

        .btn-danger {
            margin: 0 1rem;
        }
    }

    .user-documents {
        padding-top: 1.5rem;
        margin-top: 1.5rem;
        border-top: 1px solid $grey-100;

        h4 {
            font-weight: bold;
            color: $dark;
            font-size: 1rem;
        }

        .document-item {
            display: flex;
            align-items: center;
            background: $grey-100;
            border-radius: $border-radius-large;
            padding: 0.5rem 1rem;

            & + .document-item {
                margin-top: 0.5rem;
            }

            .document-date {
                font-weight: 400;
                font-style: italic;
                color: rgba($dark, 0.5);
                width: 40%;
                flex-shrink: 0;
            }

            .document-status {
                display: flex;
                align-items: center;
                margin-left: 1.5rem;
                margin-right: auto;
                cursor: pointer;

                .admin-avatar {
                    margin-right: 0.5rem;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
        }
    }
}
</style>