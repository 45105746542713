<template>
    <div class="property-details-wrapper">
        <div class="property-images">
            <!-- All images -->
            <div v-for="(image, key) in images" :key="key" :class="{'property-image': true, 'active': image.primary, 'uploading': !image.uploaded}" @click.prevent="setPrimary(image)">

                <!-- Image thumbnail -->
                <img :src="image.image" alt="" class="property-image-thumb">

                <!-- Uploading -->
                <div class="spinner-border"></div>

                <!-- Primary image label -->
                <span v-if="image.primary">Primary</span>

                <!-- Delete image -->
                <button type="button" class="btn btn-icon-only" data-bs-toggle="tooltip" title="Delete" @click.prevent.stop="deleteImage(image)" :disabled="!image.uploaded">
                    <img src="../../assets/icons/trash.svg" alt="Delete">
                </button>
            </div>

            <!-- Upload Image Box -->
            <div class="upload-image" v-if="images.length < 3">
                <input type="file" id="images" ref="images" multiple @change="uploadImages">
                <label for="images" class="upload-image-label">
                    <img src="../../assets/icons/image.svg" alt="Image">
                    <strong>Upload images</strong>
                    <span>Maximum 3 images allowed<br>JPG, PNG only | 1MB</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "PropertyImagesManagement",
    components: {},
    props: ['property',],
    data() {
        return {
            id: this.property.id,
            images: this.property.images,
        }
    },
    methods: {
        setPrimary(image) {
            // Change effects
            this.images.forEach(img => img.primary = 0);
            image.primary = 1;

            // Set as primary
            repository.post("/properties/" + this.id + "/images/" + image.id)
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },
        deleteImage(image) {
            // Remove tooltips
            let tooltipTriggers = document.querySelectorAll(".tooltip");
            tooltipTriggers.forEach(el => el.remove());

            // Verify image index
            if(this.images.indexOf(image) < 0) return null;

            // Remove image
            image.uploaded = false;
            repository.delete("/properties/" + this.id + "/images/" + image.id)
                .then(response => {
                    console.log(response.data);
                    this.images.splice(this.images.indexOf(image), 1);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        uploadImages() {
            if(this.$refs.images.files.length === 0) return null;
            Array.from(this.$refs.images.files).forEach(file => {

                // Verify if no more than 3 images
                if(this.images.length >= 3) return null;

                // Add temporary image
                let image = {
                    image: URL.createObjectURL(file),
                    uploaded: false,
                    primary: 0,
                }
                this.images.push(image);

                // Prepare image data
                let formData = new FormData();
                formData.append('image', file);

                // Save image
                repository.post("/properties/" + this.id + "/images", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(response => {
                        console.log(response.data);
                        image.image = response.data.property_image.image;
                        image.primary = response.data.property_image.primary;
                        image.uploaded = true;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.property-images {
    display: flex;
    flex-wrap: wrap;

    .property-image {
        width: calc(25% - 0.5rem);
        height: 6rem;
        border-radius: $border-radius-medium;
        overflow: hidden;
        margin-right: 0.5rem;
        position: relative;
        cursor: pointer;
        background: rgba($dark, 0.1);
        @extend .animated;

        &:hover {
            box-shadow: rgba($primary, 1) 0 0 0 0.15rem;

            .btn.btn-icon-only {
                opacity: 1;
            }
        }

        &.active {
            box-shadow: rgba($primary, 1) 0 0 0 0.15rem;
        }

        .property-image-thumb {
            width: 100%;
            height: 6rem;
            object-fit: cover;
            position: relative;
            z-index: 0;
        }

        span {
            position: absolute;
            bottom: 0;
            left: 0.5rem;
            z-index: 1;
            height: auto;
            margin: auto;
            color: $white;
            background: $primary;
            padding: 0.1rem 0.45rem 0.1rem 0.45rem;
            font-weight: 600;
            border-radius: $border-radius-medium $border-radius-medium 0 0;
            font-size: 0.7rem;
        }

        .btn.btn-icon-only {
            position: absolute;
            bottom: 0.5rem;
            right: 0.5rem;
            width: 1.25rem;
            height: 1.25rem;
            padding: 0;
            line-height: 1.25rem;
            border: none;
            z-index: 1;
            opacity: 0;
            background: $primary;
            @extend .animated;

            &:hover {
                background: $primary;
                border: none;
                outline: none;
            }

            img {
                width: 1rem;
                height: 1rem;
                filter: brightness(0) invert(1);
            }
        }

        &.uploading {
            .property-image-thumb {
                opacity: 0;
            }

            .spinner-border {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-width: 2px;
                border-color: #333;
                border-right-color: transparent;
                margin: auto;
                z-index: 3;
            }

            .btn.btn-icon-only {
                opacity: 0;
            }
        }
    }

    .upload-image {
        width: calc(50% - 0.5rem);
        height: 6rem;
        border-radius: $border-radius-medium;
        overflow: hidden;
        margin-right: 0.5rem;
        position: relative;
        cursor: pointer;
        background: rgba($dark, 0.05);
        @extend .animated;

        &:hover {
            background: rgba($dark, 0.1);
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        label.upload-image-label {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 1rem 1rem 1rem 4.5rem;
            cursor: pointer;

            img {
                position: absolute;
                top: 1rem;
                left: 1rem;
                width: 3rem;
                height: 3rem;
            }

            strong {
                display: block;
                font-size: 0.9rem;
                color: $dark;
            }

            span {
                display: block;
                font-size: 0.8rem;
                color: rgba($dark, 0.5);
                line-height: 1.2;
                background: none;
            }
        }
    }
}
</style>