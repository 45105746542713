<template>
    <div class="investments-screen">

        <!-- Welcome Message -->
        <h1 class="dashboard-title mb-3">Investments Management</h1>

        <!-- Investments Chart & Summary -->
        <div class="row">
            <div class="col-md-8 mb-4">
                <InvestmentsDailyLineChart></InvestmentsDailyLineChart>
            </div>
            <div class="col-md-4 mb-4">
                <InvestmentsSummary></InvestmentsSummary>
            </div>
        </div>

        <!-- List -->
        <InvestmentsList></InvestmentsList>

    </div>
</template>

<script>
import InvestmentsList from "@/components/investments/InvestmentsList.vue";
import InvestmentsDailyLineChart from "@/components/statistics/InvestmentsMonthlyLineCharts.vue";
import InvestmentsSummary from "@/components/statistics/InvestmentsSummary.vue";

export default {
    name: "InvestmentsScreen",
    components: {InvestmentsSummary, InvestmentsDailyLineChart, InvestmentsList}
}
</script>

<style lang="scss" scoped>

</style>