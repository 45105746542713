<template>
    <div class="dashboard-box">
        <h2 class="dashboard-subtitle">Investments in the last 30 Days</h2>
        <div class="dashboard-chart-wrapper">
            <div class="spinner-border" v-if="!loaded"></div>
            <VueApexCharts type="area" height="300" :options="areaChartOptions" :series="areaSeries" v-if="loaded"></VueApexCharts>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import repository from "@/repository/repository";
export default {
    name: "DashboardInvestmentsChart",
    components: { VueApexCharts },
    data: function () {
        return {
            loaded: false,
            chart: {
                incomplete: [],
                pending: [],
                funded: [],
                cancelled: [],
            },
        }
    },
    created() {
        this.getInvestments();
    },
    methods: {
        getInvestments() {
            this.loaded = false;
            repository.get("/dashboard/charts/investments")
                .then(response => {
                    this.chart.incomplete = response.data.incomplete;
                    this.chart.pending = response.data.pending;
                    this.chart.funded = response.data.funded;
                    this.chart.cancelled = response.data.cancelled;
                    this.loaded = true;
                })
                .catch(error => {
                    console.log("Error while fetching Investments Data", error);
                })
        },
        prepareDaily(entries) {
            if (!entries || entries.length === 0) return [];
            return Object.keys(entries).map(key => entries[key]);
        },
    },
    computed: {
        areaSeries() {
            if(!this.loaded) return [];
            let series = [];
            series.push({
                name: "Completed",
                type: 'line',
                data: this.prepareDaily(this.chart.funded),
            });
            series.push({
                name: "Pending",
                type: 'line',
                data: this.prepareDaily(this.chart.pending),
            });
            series.push({
                name: "Incomplete",
                type: 'area',
                data: this.prepareDaily(this.chart.incomplete),
            });
            series.push({
                name: "Cancelled",
                type: 'line',
                data: this.prepareDaily(this.chart.cancelled),
            });
            return series;
        },
        areaCategories() {
            if (this.chart.incomplete === 0) return [];
            return Object.keys(this.chart.incomplete);
        },
        areaChartOptions() {
            return {
                chart: {
                    height: 300,
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                },
                fill: {
                    type: 'solid',
                },
                colors: [
                    "#1FAE5D",
                    "#16193A",
                    "#EEEEEE",
                    "#D64933"
                ],
                stroke: {
                    curve: 'smooth',
                    dashArray: [0, 0, 0, 3],
                    width: [3, 3, 0, 3],
                },
                grid: {
                    borderColor: "#eee",
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    labels: {
                        colors: "#aaa",
                    }
                },
                xaxis: {
                    type: 'date',
                    categories: this.areaCategories,
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return "$" + (value ? value.toLocaleString() : 0);
                        },
                    },
                },
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.dashboard-chart-wrapper {
    position: relative;
    min-height: 300px;

    .spinner-border {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        opacity: 0.25;
    }
}
</style>

<style lang="scss">
.apexcharts-tooltip.apexcharts-theme-light {
    background: $white !important;
    border: none !important;
    border-radius: $border-radius-large;
    box-shadow: rgba($dark, 0.1) 0 0.5rem 2.5rem;
    padding: 1rem;

    .apexcharts-tooltip-title {
        background: none !important;
        border: none !important;
        font-weight: bold;
        font-size: 1rem !important;
        color: $dark !important;
        font-family: $default-font !important;
    }

    .apexcharts-tooltip-series-group {

        .apexcharts-tooltip-marker {
            margin-right: 0.5rem !important;
        }

        .apexcharts-tooltip-text {
            flex-grow: 1;
            font-family: $default-font !important;
            font-weight: 400 !important;
            color: $dark !important;

            .apexcharts-tooltip-y-group {
                padding-top: 5px !important;
                padding-bottom: 0 !important;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .apexcharts-tooltip-text-y-value {
                    font-weight: bold !important;
                    padding-left: 0.5rem;
                }
            }
        }
    }
}
</style>