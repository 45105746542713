<template>
    <div class="investments-screen">

        <!-- Welcome Message -->
        <h1 class="dashboard-title mb-3">Refunds</h1>

        <!-- List -->
        <RefundsList></RefundsList>

    </div>
</template>

<script>

import RefundsList from "@/components/refunds/RefundsList.vue";

export default {
    name: "RefundsScreen",
    components: {RefundsList}
}
</script>

<style lang="scss" scoped>

</style>