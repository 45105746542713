<template>
    <div class="dashboard-box">
        <h2 class="dashboard-subtitle">Investors Map</h2>
        <div class="map-wrapper">
            <svg-map :map="map" @mouseover="showTooltip" @mouseout="hideTooltip"/>
            <div class="map-tooltip" v-if="currentLocation" :style="position">
                <strong>{{ currentLocationStateName }}</strong>
                {{ states[currentLocation] }} {{ states[currentLocation] === 1 ? 'investor' : 'investors' }}
            </div>
        </div>
    </div>
</template>

<script>
import {SvgMap} from "vue-svg-map";
import USA from "@svg-maps/usa";
import repository from "@/repository/repository";
import {getState} from "@/helpers";

export default {
    name: "InvestorsMap",
    components: {
        SvgMap
    },
    data () {
        return {
            states: [],
            loaded: false,
            error: false,
            currentLocation: null,
            position: null,
        }
    },
    created() {
        this.loadInvestors();
    },
    methods: {
        loadInvestors() {
            this.loaded = false;
            this.error = false;
            repository.get("/advanced-stats/maps/investors")
                .then(response => {
                    USA.locations.forEach(location => {
                        this.states[location.id.toUpperCase()] = response.data.states[location.id.toUpperCase()] ?? 0;
                    });
                    this.loaded = true;
                })
                .catch(() => {
                    this.loaded = true;
                    this.error = true;
                })
        },
        showTooltip(event) {
            if(event.target.getAttribute("disabled")) return;
            this.currentLocation = event.target.id.toUpperCase();
            this.position = {
                top: `${event.offsetY - 75}px`,
                left: `${event.offsetX - 50}px`,
            }
        },
        hideTooltip() {
            this.currentLocation = null;
            this.position = null;
        },
        calculatePercentage(locationId) {
            return (((100 / this.maximumInvestors) * this.states[locationId]) / 100) * 2.5;
        },
    },
    computed: {
        map() {
            // if(!this.loaded) return null;
            let locations = [...USA.locations];
            return {
                ...USA,
                label: "USA",
                locations: locations.map(location => {
                    if(!this.loaded) {
                        location.fill = "#f2f3f4";
                        location.disabled = true;
                    } else {
                        if(this.states[location.id.toUpperCase()] === 0) {
                            location.fill = "#f2f3f4";
                            location.disabled = true;
                        } else {
                            location.fill = "#16193A";
                            location.opacity = this.calculatePercentage(location.id.toUpperCase());
                            location.disabled = false;
                        }
                    }
                    return location;
                })
            }
        },
        maximumInvestors() {
            if(!this.loaded) return 0;
            return Math.max(...Object.values(this.states)) / 2.5;
        },
        currentLocationStateName() {
            if(!this.currentLocation) return null;
            return getState(this.currentLocation);
        },
    },
}
</script>

<style lang="scss">
.map-wrapper {
    padding-top: 1rem;
}
.svg-map {
    width: 100%;
    height: auto;
    stroke: #fff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.svg-map__location {
    cursor: pointer;
    @extend .animated;
}
.svg-map__location:disabled,
.svg-map__location[disabled] {
    cursor: default;
    pointer-events: none;
}
.svg-map__location:focus, .svg-map__location:hover {
    fill: #F37021;
    outline: 0;
    opacity: 1;
}
.map-wrapper {
    position: relative;
}
.map-tooltip {
    position: absolute;
    z-index: 1;
    background: $white;
    padding: 0.75rem 1rem;
    border-radius: $border-radius-large;
    box-shadow: rgba($dark, 0.1) 0 0.5rem 1.5rem;
    white-space: nowrap;
    @extend .animated;

    strong {
        display: block;
        color: $dark;
        font-size: 1rem;
        font-weight: bold;
        white-space: nowrap;
        line-height: 1.2;
    }

    span {
        color: $primary;
        display: block;
        white-space: nowrap;
        line-height: 1.2;
    }
}
</style>