<template>
    <div class="investment-item" @click.prevent="openInvestmentPopup(investment)">
        <div :class="getAvatar(investment.user)">{{ investment.user.credentials }}</div>
        <div class="investor-info">
            <strong>{{ fullName }}</strong>
            <span>{{ investment.user.email }}</span>
        </div>
        <div :class="getInvestmentStatusClass(investment)" data-bs-toggle="tooltip" :title="capitalize(investment.payment_status)">${{ investment.amount.toLocaleString() }}</div>
        <div class="investment-date">{{ createdAt }}</div>
    </div>
</template>

<script>
import {generateAvatarClass, ucwords} from "@/helpers";
import moment from "moment-timezone";

export default {
    name: "InvestmentsListItem",
    components: {},
    props: [ 'investment' ],
    methods: {
        capitalize(value) {
            return ucwords(value);
        },
        getAvatar(user) {
            return "investor-credentials " + generateAvatarClass(user);
        },
        getInvestmentStatusClass(investment) {
            return "investment-amount investment-" + investment.payment_status;
        },
        openInvestmentPopup(investment) {
            console.log("Open investment popup", investment);
            this.$root.$emit("openInvestmentPopup", investment);
        },
    },
    computed: {
        fullName() {
            return ucwords(this.investment.user.name);
        },
        createdAt() {
            if (this.investment.created_at === null) return "Error..";
            let today = moment().tz('UTC');
            let accessedDate = moment(this.investment.created_at).tz('UTC');
            let difference = moment.duration(today.diff(accessedDate));
            let returnString = "";
            if (difference.months() === 1) returnString += difference.months() + " month";
            else if (difference.months() > 1) returnString += difference.months() + " months";
            else if (difference.days() === 1) returnString += difference.days() + " day";
            else if (difference.days() > 1) returnString += difference.days() + " days";
            else if (difference.hours() === 1) returnString += difference.hours() + " hour";
            else if (difference.hours() > 1) returnString += difference.hours() + " hours";
            else if (difference.minutes() === 1) returnString += difference.minutes() + " minute";
            else if (difference.minutes() > 1) returnString += difference.minutes() + " minutes";
            else if (difference.seconds() === 1) returnString += difference.seconds() + " second";
            else if (difference.seconds() > 1) returnString += difference.seconds() + " seconds";
            return returnString === "" ? "Just now" : returnString + " ago";
        },
    },
}
</script>

<style lang="scss" scoped>
.investment-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin: 0 -0.5rem;
    border-radius: $border-radius-large;
    cursor: pointer;
    position: relative;
    @extend .animated;

    & + .investment-item {
        &:before {
            position: absolute;
            top: 0;
            left: 0.5rem;
            width: calc(100% - 1rem);
            height: 1px;
            background: $grey-200;
            content: ' ';
        }
    }

    &:hover {
        background: $grey-200;
    }

    &:active {
        background: darken($grey-200, 5%);
    }

    .investor-credentials {
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        border-radius: 50%;
        line-height: 2.5rem;
        font-size: 0.8rem;
        font-weight: bold;
        flex-shrink: 0;
        @extend .disable-selection;
    }

    .investor-info {
        padding: 0 1rem;
        width: 40%;
        flex-shrink: 0;

        strong {
            display: block;
            font-size: 0.9rem;
        }

        span {
            display: block;
            color: rgba($dark, 0.75);
            font-size: 0.8rem;
        }
    }

    .investment-amount {
        margin-left: 1rem;
        font-size: 0.8rem;
        padding: 0.15rem 0.75rem;
        border-radius: $border-radius-large;
        background: rgba($dark, 0.1);
        color: rgba($dark, 0.75);
        font-weight: 600;

        &.investment-incomplete {
            background: rgba($dark, 0.05);
            color: rgba($dark, 0.25);
        }

        &.investment-pending {
            background: rgba($dark, 0.1);
            color: rgba($dark, 1);
        }

        &.investment-cancelled {
            background: $danger;
            color: $white;
        }

        &.investment-funded {
            background: $success;
            color: $white;
        }
    }

    .investment-date {
        margin-left: auto;
        font-size: 0.8rem;
        color: rgba($dark, 0.5);
        @extend .disable-selection;
    }
}
</style>